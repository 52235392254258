import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistReducer } from 'redux-persist';
import reduxStorage from 'redux-persist/lib/storage';
import {
  loader, notify, storage, user, menu
} from './reducers';

const persistConfig = {
  key: 'root',
  storage: reduxStorage,
  whitelist: ['storage'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    loader,
    notify,
    user,
    storage,
    menu,
    form: reduxFormReducer,
  }),
);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
