import React, { PureComponent } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

class ConfirmBox extends PureComponent {
  render() {
    const {
      isOpen = false, title, text, onConfirm, onCancel, confirmButtonText = 'Logout'
    } = this.props;
    return (
      isOpen && (
      <SweetAlert
        warning
        showCancel
        confirmBtnText={confirmButtonText}
        cancelBtnText="Continue"
        title={title}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        focusCancelBtn
      >
        {text}
      </SweetAlert>
      )
    );
  }
}

export default ConfirmBox;
