import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import withNotify from '../../common/withNotify';
import { renderField, required } from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class EditSupplierTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('editSupplierTime'));
    }
    document.getElementById('modalEditSupplierTime').click();
  };

  _onEditSupplier = async (formData) => {
    const { editSupplierTime } = this.props;
    const data = {
      delivery_time: formData.delivery_time,
    };
    editSupplierTime(data);
    this._resetForm();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0) {
      dispatch(change('editSupplierTime', 'short_code', data.short_code));
      dispatch(change('editSupplierTime', 'full_name', data.full_name));
      dispatch(change('editSupplierTime', 'delivery_time', data.delivery_time));
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div
        className="modal fade" id="modalEditSupplierTime"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">Edit Supplier Delivery Time</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="editSupplierTime" onSubmit={handleSubmit(this._onEditSupplier)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="full_name" className="col-sm-3 col-form-label">Full Name</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="full_name"
                          component={renderField}
                          className="form-control"
                          placeholder="Full Name"
                          validate={[required]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="short_code" className="col-sm-3 col-form-label">Short code</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_code"
                          component={renderField}
                          className="form-control"
                          placeholder="Short code"
                          validate={[required]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="delivery_time" className="col-sm-3 col-form-label">Delivery time</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="delivery_time"
                          component={renderField}
                          className="form-control"
                          placeholder="Delivery time"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">Update</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'editSupplierTime'
});
export default withLoader(withForm(withNotify(EditSupplierTime)));
