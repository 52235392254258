import React from 'react';
import Loader from 'react-loader-spinner';
import withLoader from '../common/withLoader';

const Indicator = (props) => {
  const { loader } = props;
  return (
    <div>
      {loader && (
        <div className="loading">
          <Loader
            style={{ position: 'absolute' }}
            type="Rings"
            color="#6777ef"
          />
        </div>
      )}
    </div>
  );
};

export default withLoader(Indicator);
