import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Select2 from 'react-select2-wrapper';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import {
  renderField, required
} from '../../helpers/form-validations';
import API from '../../utils/utils';

class SeoAddEditFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      pageList: [{}],
      selectedPage: null
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('seoAddEditFrom'));
  };

  _getSeoDetailById = () => {
    const {
      setLoader, notify, params: { id }, dispatch
    } = this.props;
    setLoader(true);
    API.getSeoDetailById(id).then((responseJson) => {
      setLoader(false);
      const { status, message, cmspage } = responseJson;
      if (status) {
        Object.keys(cmspage).map((key) => {
          dispatch(change('seoAddEditFrom', key, cmspage[key]));
        });
        this.setState({ selectedPage: cmspage.page_permalink });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSave = (formData) => {
    const {
      setLoader, notify, params, params: { id = null }
    } = this.props;
    const { selectedPage } = this.state;
    if (selectedPage === '' || selectedPage === undefined) {
      notify({ type: 'error', message: 'Please select any page for SEO.' });
      return;
    }
    const data = {
      page_permalink: selectedPage,
      meta_title_en: formData.meta_title_en,
      meta_description_en: formData.meta_description_en,
      meta_keyword_en: formData.meta_keyword_en,
      meta_title_de: formData.meta_title_de,
      meta_description_de: formData.meta_description_de,
      meta_keyword_de: formData.meta_keyword_de,
      meta_title_du: formData.meta_title_du,
      meta_description_du: formData.meta_description_du,
      meta_keyword_du: formData.meta_keyword_du,
      status: 1,
    };
    if (id != null) {
      data.id = params.id;
      setLoader(true);
      API.updateSeoDetail(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/site-settings/manage-seo');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      setLoader(true);
      API.addSeoDetail(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/site-settings/manage-seo');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onPressCancel = () => {
    browserHistory.push('/site-settings/manage-seo');
    this._clearForm();
  }

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      if (params.hasOwnProperty('id')) {
        this.setState({ isEdit: true });
        this._getSeoDetailById();
      }
      this.setState({
        pageList: [
          { _id: '/home', name: 'Home' },
          { _id: '/signup', name: 'Sign Up' },
          { _id: '/login', name: 'Login' },
          { _id: '/forgot-password', name: 'Forgot Password' },
          { _id: '/contact-us', name: 'Contact Us' },
          { _id: '/track-order', name: 'Track Order' },
        ]
      });
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isEdit, pageList, selectedPage
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>{(isEdit) ? 'Update SEO Detail' : 'Add SEO Detail'}</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this._onSave)} name="seoAddEditFrom">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="col-10 p-0  form-group form-row">
                            <label htmlFor="page_parmalink" className="col-sm-4 col-form-label">Select Page For SEO</label>
                            <div className="col-sm-8">
                              <Select2
                                style={{ width: '100%' }}
                                className="form-control"
                                data={
                                  pageList.map(({ name, _id }) => ({ id: _id, text: name }))
                                }
                                options={{ placeholder: 'Select Page' }}
                                value={selectedPage}
                                onSelect={({ target: { value } }) => {
                                  this.setState({ selectedPage: value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <h3>English</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_title_en" className="col-sm-3 col-form-label">Meta Title (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_description_en" className="col-sm-3 col-form-label">Meta Description (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_keyword_en" className="col-sm-3 col-form-label">Meta Keyword (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                              />
                            </div>
                          </div>
                          <h3>German</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_title_de" className="col-sm-3 col-form-label">Meta Title (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_description_de" className="col-sm-3 col-form-label">Meta Description (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_keyword_de" className="col-sm-3 col-form-label">Meta Keyword (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                              />
                            </div>
                          </div>
                          <h3>Dutch</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_title_du" className="col-sm-3 col-form-label">Meta Title (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_description_du" className="col-sm-3 col-form-label">Meta Description (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_keyword_du" className="col-sm-3 col-form-label">Meta Keyword (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">{(isEdit) ? 'Update' : 'Save'}</button>
                            {' '}
                            &nbsp;&nbsp;
                            <button className="btn btn-primary" type="button" onClick={() => this._onPressCancel()}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'seoAddEditFrom',
  destroyOnUnmount: false
});
export default withForm(withLoader(withNotify(withUser(SeoAddEditFrom))));
