import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import {
  change,
  Field,
  reduxForm
} from 'redux-form';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import {
  email,
  maxLength,
  PhoneRegEx,
  renderField,
  required,
  urlRegEx
} from '../../helpers/form-validations';

class GeneralSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: 'headerEditFormTab',
      settingId: null
    };
  }

  componentDidMount() {
    const {
      setLoader
    } = this.props;

    setLoader(true);
    // setTimeout(() => {
    // }, 1000);
    this._getGeneralSetting();
  }

  _getGeneralSetting = () => {
    const {
      setLoader, dispatch
    } = this.props;

    API.getGeneralSetting().then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({
          settingId: data._id
        });

        Object.keys(data).map((key) => {
          dispatch(change('headerEditForm', key, data[key]));
          // dispatch(change('headerEditForm', key, data[key]));
          // dispatch(change('footerEditForm', key, data[key]));
          // dispatch(change('socialMediaEditForm', key, data[key]));
          // dispatch(change('contactUsEditForm', key, data[key]));
        });
      }
    });
  };

  _onEmailSave = (formData) => {
    const {
      setLoader, notify,
    } = this.props;
    const { settingId } = this.state;
    const data = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      to_email: formData.to_email,
    };
    data.id = settingId;
    const query = 'Email_Settings';
    setLoader(true);
    API.updateGeneralSetting(data, query).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getGeneralSetting();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onHeaderSave = (formData) => {
    const {
      setLoader, notify
    } = this.props;
    const { settingId } = this.state;
    const data = {
      header_contact_no: formData.header_contact_no,
    };
    data.id = settingId;
    const query = 'Header_Section';
    setLoader(true);
    API.updateGeneralSetting(data, query).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getGeneralSetting();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onFooterSave = (formData) => {
    const {
      setLoader, notify
    } = this.props;
    const { settingId } = this.state;
    const data = {
      footer_about_text: formData.footer_about_text,
      footer_contact_address: formData.footer_contact_address,
      footer_contact_no: formData.footer_contact_no,
      footer_email_address: formData.footer_email_address,
      footer_location_address: formData.footer_location_address,
      footer_copyright: formData.footer_copyright,
      footer_whatsapp_no: formData.footer_whatsapp_no,
      footer_working_time: formData.footer_working_time,
      footer_company_name: formData.footer_company_name,
    };
    data.id = settingId;
    const query = 'Footer_Section';
    setLoader(true);
    API.updateGeneralSetting(data, query).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getGeneralSetting();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSocialMediaSave = (formData) => {
    const {
      setLoader, notify
    } = this.props;
    const { settingId } = this.state;
    const data = {
      fb_link: formData.fb_link,
      tw_link: formData.tw_link,
      pt_link: formData.pt_link,
      yt_link: formData.yt_link,
      in_link: formData.in_link,
    };
    data.id = settingId;
    const query = 'Social_Media';
    setLoader(true);
    API.updateGeneralSetting(data, query).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getGeneralSetting();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onContactSave = (formData) => {
    const {
      setLoader, notify
    } = this.props;
    const { settingId } = this.state;
    const data = {
      workingSchedule: formData.workingSchedule,
      contact_address: formData.contact_address,
      contact_email: formData.contact_email,
      contact_phone: formData.contact_phone,
    };
    data.id = settingId;
    const query = 'Contact_us';
    setLoader(true);
    API.updateGeneralSetting(data, query).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getGeneralSetting();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  render() {
    const { eventKey } = this.state;
    const { handleSubmit } = this.props;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="container">
              {this.props.valid === false
                ? (
                  <div className="mt-2 mb-3">
                    <span className="text-danger">
                      <i className="fa fa-exclamation-circle text-danger" />
                      &nbsp;&nbsp;
                      Please check the tabs - There is some fields are required.
                    </span>
                  </div>
                )
                : ''}
              <Tabs
                id="controlled-tab-example"
                activeKey={eventKey}
                onSelect={(k) => {
                  this.setState({ eventKey: k });
                  localStorage.setItem('eventKey', k);
                }}
                variant="pills"
              >
                {/* <Tab eventKey="emailSettingEditFormTab" title="Email Settings">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Email Setting</h4>
                        </div>
                        <div className="card-body">
                          <form name="emailSettingEditForm"
                          onSubmit={handleSubmit(this._onEmailSave)}>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="from_name"
                                  className="col-sm-3 col-form-label">From Name</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="from_name"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="From Name"
                                      validate={[required]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="display_order"
                                  className="col-sm-3 col-form-label">From Email</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="from_email"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="From Email"
                                      validate={[required, email]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="to_email"
                                  className="col-sm-3 col-form-label">To Email</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="to_email"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="To Email"
                                      validate={[required, email]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-row form-group">
                                  <button className="btn btn-primary" type="submit">Submit</button>
                                  {' '}
                                  &nbsp;&nbsp;
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab> */}

                <Tab eventKey="headerEditFormTab" title="Header Section">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Header Section</h4>
                        </div>
                        <div className="card-body">
                          <form name="headerEditForm" onSubmit={handleSubmit(this._onHeaderSave)}>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="header_contact_no" className="col-sm-3 col-form-label">Header Contact No.</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="header_contact_no"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Header Contact Number"
                                      validate={[required, PhoneRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-row form-group">
                                  <button className="btn btn-primary" type="submit">Submit</button>
                                  {' '}
                                  &nbsp;&nbsp;
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="footerEditFormTab" title="Footer Section">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Footer Section</h4>
                        </div>
                        <div className="card-body">
                          <form name="footerEditForm" onSubmit={handleSubmit(this._onFooterSave)}>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_about_text" className="col-sm-3 col-form-label">About Text</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_about_text"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="About Text"
                                      validate={[required, maxLength(300)]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_contact_address" className="col-sm-3 col-form-label">Contact Address</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_contact_address"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Contact Address"
                                      validate={[required, maxLength(300)]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_contact_no" className="col-sm-3 col-form-label">Contact No.</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_contact_no"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Contact No."
                                      validate={[required, PhoneRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_email_address" className="col-sm-3 col-form-label">Email Address</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_email_address"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Email Address"
                                      validate={[required, email]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_location_address" className="col-sm-3 col-form-label">Location Address</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_location_address"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Footer location address"
                                      validate={[required, maxLength(300)]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_whatsapp_no" className="col-sm-3 col-form-label">WhatsApp No.</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_whatsapp_no"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Footer Whatsapp Number"
                                      validate={[required, PhoneRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_company_name" className="col-sm-3 col-form-label">Company Name</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_company_name"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Footer Company Name"
                                      validate={[required, maxLength(300)]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_working_time" className="col-sm-3 col-form-label">Working Time</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_working_time"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Footer Working Time"
                                      validate={[required, maxLength(300)]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="footer_copyright" className="col-sm-3 col-form-label">Copyright</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="footer_copyright"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Footer copyright"
                                      validate={[required, maxLength(300)]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-row form-group">
                                  <button className="btn btn-primary" type="submit">Submit</button>
                                  {' '}
                                  &nbsp;&nbsp;
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="socialMediaEditFormTab" title="Social Media">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Social Media </h4>
                        </div>
                        <div className="card-body">
                          <form name="socialMediaEditForm" onSubmit={handleSubmit(this._onSocialMediaSave)}>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="fb_link" className="col-sm-3 col-form-label">Facebook Link</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="fb_link"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Facebook Link"
                                      validate={[required, urlRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="tw_link" className="col-sm-3 col-form-label">Twitter Link</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="tw_link"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Twitter Link"
                                      validate={[required, urlRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="pt_link" className="col-sm-3 col-form-label">Pinterest Link</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="pt_link"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Pinterest Link"
                                      validate={[required, urlRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="yt_link" className="col-sm-3 col-form-label">Youtube Link</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="yt_link"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Youtube Link"
                                      validate={[required, urlRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="in_link" className="col-sm-3 col-form-label">Instagram Link</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="in_link"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Instagram Link"
                                      validate={[required, urlRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-row form-group">
                                  <button className="btn btn-primary" type="submit">Submit</button>
                                  {' '}
                                  &nbsp;&nbsp;
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="contactUsEditFormTab" title="Contact us">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h4>Contact us</h4>
                        </div>
                        <div className="card-body">
                          <form name="contactUsEditForm" onSubmit={handleSubmit(this._onContactSave)}>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="workingSchedule" className="col-sm-3 col-form-label">Working Schedule</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="workingSchedule"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Working Schedule"
                                      validate={[required]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="contact_address" className="col-sm-3 col-form-label">Address</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="contact_address"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Address"
                                      validate={[required]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="contact_email" className="col-sm-3 col-form-label">Email</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="contact_email"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Email"
                                      validate={[required, email]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-group form-row">
                                  <label htmlFor="contact_phone" className="col-sm-3 col-form-label">Phone</label>
                                  <div className="col-sm-9">
                                    <Field
                                      name="contact_phone"
                                      component={renderField}
                                      type="text"
                                      className="form-control"
                                      placeholder="Phone"
                                      validate={[required, PhoneRegEx]}
                                    />
                                  </div>
                                </div>
                                <div className="col-10 p-0 form-row form-group">
                                  <button className="btn btn-primary" type="submit">Submit</button>
                                  {' '}
                                  &nbsp;&nbsp;
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>

            </div>
          </div>
        </section>
      </div>
    );
  }
}
// const editForm = localStorage.getItem('eventKey').split('T')[0];
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'headerEditForm',
  destroyOnUnmount: true
});
export default withForm(withLoader(withNotify(withUser(GeneralSetting))));
