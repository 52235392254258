import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import Pagination from '../Pagination';

class OrderListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: 'created_date',
      sortOrder: 'desc'
    };
  }

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage, customerName, productName, orderStatus
    } = this.props;
    const { sortKey, sortOrder, } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              {/* text-center" */}
              <h4>
                {orderStatus === 1 && 'Today`s Pending Order Lists'}
                {orderStatus === 2 && 'Today`s Processing Order Lists'}
                {orderStatus === 6 && 'Today`s Cancelled Order Lists'}
                {!orderStatus && 'Order Lists'}
                {' '}
                {`${customerName ? `(Customer Name : ${customerName} )` : ''} `}
                {`${productName ? `(Product Name : ${productName} )` : ''} `}
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'order_date' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'order_date', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Order Date & Time
                      </th>
                      <th
                        className={`sort ${sortKey === 'order_number' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'order_number', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        #Order
                      </th>
                      <th
                        className={`sort ${sortKey === 'is_guest_customer' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'is_guest_customer', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Guest?
                      </th>
                      <th
                        className={`sort ${sortKey === 'user_name' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'user_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'user_email' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'user_email', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Email
                      </th>
                      <th
                        className={`sort ${sortKey === 'is_paid' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'is_paid', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Paid?
                      </th>
                      <th
                        className={`sort ${sortKey === 'status' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'status', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Status
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((user, key) => (
                        <tr key={key}>
                          <td>
                            {user.order_date.slice(0, 10)}
                            {' '}
                            {user.order_date.slice(11, 19)}
                          </td>
                          <td>
                            {user.order_number}
                          </td>
                          <td>{user.is_guest_customer ? 'Yes' : 'No'}</td>
                          <td>{user.user_name || '-'}</td>
                          <td>{user.user_email}</td>
                          <td>{user.is_paid ? 'Yes' : 'No'}</td>
                          <td>
                            {user.status === 1 && <i title="Pending" className="dot bg-pending cursor" />}
                            {user.status === 2 && <i title="Processing" className="dot bg-running cursor" />}
                            {user.status === 3 && <i title="Partial Updated" className="dot bg-secondry cursor" />}
                            {user.status === 4 && <i title="Shipment" className="dot bg-shipment cursor" />}
                            {user.status === 5 && <i title="Completed" className="dot bg-completed cursor" />}
                            {user.status === 6 && <i title="Cancelled" className="dot bg-cancel cursor" />}
                          </td>
                          <td>
                            <Link to={`/orders/view/${user._id}`} title="View" className="cursor" aria-label="View">
                              <i className="fas fa-eye" aria-hidden="true" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNotify(withLoader(OrderListSection));
