import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import { passwordsMustMatch, renderField, required } from '../helpers/form-validations';
import withNotify from '../common/withNotify';
import withLoader from '../common/withLoader';
import API from '../utils/utils';
import withUser from '../common/withUser';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisabled: false,
    };
  }

  _onSubmitPassword = (formData) => {
    this.setState({ btnDisabled: true });
    const data = {
      oldPassword: formData.old_password,
      newPassword: formData.password,
      confirmPassword: formData.retype_password
    };
    const {
      setLoader, notify, setUser, user
    } = this.props;
    setLoader(true);
    API.changePassword(data).then(async (responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        setUser(user);
        notify({ type: 'success', message });
        this.setState({ btnDisabled: false });
        browserHistory.push('/');
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { btnDisabled } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Change Password</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this._onSubmitPassword)} form="myProfileForm">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group form-row">
                            <label htmlFor="old_password" className="col-sm-3 col-form-label">Old Password</label>
                            <div className="col-sm-9">
                              <Field
                                type="password"
                                name="old_password"
                                className="form-control"
                                placeholder="Old Password"
                                component={renderField}
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <label htmlFor="new_password" className="col-sm-3 col-form-label">Password</label>
                            <div className="col-sm-9">
                              <Field
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="Password"
                                component={renderField}
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <label htmlFor="retype_password" className="col-sm-3 col-form-label">Retype Password</label>
                            <div className="col-sm-9">
                              <Field
                                type="password"
                                name="retype_password"
                                className="form-control"
                                placeholder="Retype Password"
                                component={renderField}
                                validate={[required, passwordsMustMatch]}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <div className="col-sm-9 offset-sm-3 ">
                              <button className="btn btn-primary" type="submit">Submit</button>
                              &nbsp;&nbsp;&nbsp;
                              <button className="btn btn-primary" type="button" disabled={btnDisabled} onClick={() => browserHistory.push('/')}>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const initialValue = {
  old_password: '',
  password: '',
  retype_password: ''
};
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'myProfileForm',
  initialValues: initialValue
});
export default withUser(withForm(withNotify(withLoader(ChangePassword))));
