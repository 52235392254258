import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react/lib/es2015/main/ts';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import { browserHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { renderField, required } from '../../helpers/form-validations';
import API from '../../utils/utils';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import { TINY_CLOUD_API_KEY } from '../../common/config';

class AddUpdateEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isEdit: false
    };
  }

  _getEmailsTemplateById = () => {
    const {
      setLoader, notify, params, dispatch
    } = this.props;
    setLoader(true);
    API.getEmailTemplateById({ id: params.id }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        Object.keys(data).map((key) => {
          dispatch(change('addUpdateEmailTemplate', key, data[key]));
        });
        this.setState({ content: data.content });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onAddUpdateEmailTemplate= (formData) => {
    const {
      setLoader, notify, params, params: { id = null }
    } = this.props;
    const { content } = this.state;
    formData.content = content;
    if (id != null) {
      formData.id = params.id;
      setLoader(true);
      API.updateEmailTemplate(formData).then(async (responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/site-settings/email-template');
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      setLoader(true);
      API.addEmailTemplate(formData).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/site-settings/email-template');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _handleEditorChange = (content) => {
    this.setState({ content });
  };

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('addUpdateEmailTemplate'));
    this.setState({ content: null });
  };

  _onPressCancel = () => {
    browserHistory.push('/site-settings/email-template');
    this._clearForm();
  }

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      if (params.hasOwnProperty('id')) {
        this.setState({ isEdit: true });
        this._getEmailsTemplateById();
      }
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { content, isEdit } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>{(isEdit) ? 'Update Email Template' : 'Create Email Template'}</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this._onAddUpdateEmailTemplate)} form="addUpdateEmailTemplate">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group form-row">
                            <label htmlFor="title" className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-9">
                              <Field
                                name="title"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <label htmlFor="subject" className="col-sm-3 col-form-label">Email Subject</label>
                            <div className="col-sm-9">
                              <Field
                                name="subject"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Email Subject"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <label htmlFor="template_trigger" className="col-sm-3 col-form-label">Email Key</label>
                            <div className="col-sm-9">
                              <Field
                                name="template_trigger"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Email Key"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <label htmlFor="content" className="col-sm-3 col-form-label">Email Content</label>
                            <div className="col-sm-9">
                              <Editor
                                apiKey={TINY_CLOUD_API_KEY}
                                value={content}
                                init={{
                                  selector: 'textarea', // change this value according to your HTML
                                  file_picker_types: 'file image media',
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                                    'searchreplace wordcount visualblocks visualchars code fullscreen',
                                    'insertdatetime media nonbreaking save table contextmenu directionality',
                                    'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help image code'
                                  ],
                                  toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | fontsizeselect',
                                  toolbar2: 'print preview media | forecolor backcolor emoticons | codesample help',
                                  media_live_embeds: true
                                }}
                                onEditorChange={this._handleEditorChange}
                              />
                            </div>
                          </div>
                          <div className="form-group form-row">
                            <div className="col-sm-9 offset-sm-3">
                              <button className="btn btn-primary" type="submit">{(isEdit) ? 'Update' : 'Save'}</button>
&nbsp;&nbsp;
                              <button className="btn btn-primary" type="button" onClick={() => this._onPressCancel()}>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addUpdateEmailTemplate'
});

export default withForm(withNotify(withLoader(withUser(AddUpdateEmailTemplate))));
