import React, { Component } from 'react';
import { trim } from 'lodash';

class ReduxField extends Component {
  _dropDownChange = (event) => {
    const {
      input: { name },
      _dropDownChange,
    } = this.props;
    _dropDownChange({ name, value: event.target.value });
  };

  render() {
    const {
      input,
      type = 'text',
      meta,
      _dropDownChange = null,
      ...rest
    } = this.props;
    return (
      <>
        {type === 'dropdown' ? (
          <select
            className="form-control"
            {...rest}
            {...input}
            {...(_dropDownChange ? { onChange: this._dropDownChange } : {})}
          >
            {rest.placeholder && <option value="">{rest.placeholder}</option>}
            {rest.data.map((value, key) => (
              <option key={`reduxDropdown${key}`}>{value}</option>
            ))}
          </select>
        ) : null}

        {type === 'text' || type === 'password' || type === 'checkbox' ? (
          <input {...rest} {...input} type={type} />
        ) : type === 'textarea' ? (
          <textarea {...rest} {...input} />
        ) : null}

        {meta.touched && meta.error && (
          <span className="pull-right text-danger">
            <i className="fa fa-exclamation-circle text-danger" />
            &nbsp;&nbsp;
            {meta.error}
          </span>
        )}
      </>
    );
  }
}

export const renderField = (props) => <ReduxField {...props} />;

export const required = (value) => (trim(value) ? undefined : 'Required');

export const maxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : '');

export const maxValue = (max) => (value) => (value && value >= max ? `Must be ${max} or less` : undefined);

export const maxValue100 = maxValue(100);

export const minLength = (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const character = (value) => (value && !/^[a-zA-Z]+$/i.test(value) ? 'Must be a character' : undefined);

export const numberReg = /^\d+$/;

export const minValue = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Invalid email address'
  : undefined);

export const passwordsMustMatch = (value, allValues) => (value !== allValues.password ? 'Passwords do not match' : undefined);

// export const PhoneRegEx = (value) => (value && !/^\(?\d{3}\)?[\s-]\d{3}[\s-]\d{4}$/i.test(value)
//   ? 'Invalid Phone No. (Ex. xxx-xxx-xxxx/xxx xxx xxxx)' : undefined);

export const PhoneRegEx = (value) => (value && !/^[0-9. -]{6,16}$/i.test(value) ? 'Invalid Phone No.' : undefined);

export const postalCodeRegEx = (value) => (value && !/^\d{4}?[A-Z] {2}$/i.test(value)
  ? 'Invalid Postal Code (Ex. 1234AB)'
  : undefined);

export const urlRegEx = (value) => (value
  && !/[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/i.test(
    value,
  )
  ? 'Invalid URL'
  : undefined);

export const percentageRegEx = (value) => (value && !/^\d{0,2}(\.\d{1,4})? *?$/i.test(value)
  ? 'Invalid Percentage (Ex. 99.99)'
  : undefined);

export const allowNumberRegEx = (value) => (value && !/^\d*$/i.test(value) ? 'Allow only number' : undefined);
