import React, { Component } from 'react';
import {
  change, reset,
  Field, reduxForm
} from 'redux-form';
import { browserHistory } from 'react-router';
import Select2 from 'react-select2-wrapper';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  email, passwordsMustMatch, renderField, required, PhoneRegEx, maxLength
} from '../../helpers/form-validations';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import { getDays, getMonths, getYears } from '../../common/globals';

class CustomerAddEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      gender: 'male',
      day: '',
      month: '',
      year: ''
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('customerAddEditForm'));
  };

  _getUserById = () => {
    const {
      setLoader, notify, params: { id }, dispatch
    } = this.props;
    setLoader(true);
    API.getCustomerById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message, customer } = responseJson;
      if (status) {
        Object.keys(customer).map((key) => {
          dispatch(change('customerAddEditForm', key, customer[key]));
        });
        this.setState({
          day: moment(customer.dob).format('DD'),
          month: moment(customer.dob).format('MM'),
          year: moment(customer.dob).format('YYYY'),
          gender: (customer.gender) === 1 ? 'male' : 'female'
        });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSave = (formData) => {
    const {
      setLoader, notify, params, params: { id = null }
    } = this.props;
    const { day, month, year } = this.state;
    if (!day || !month || !year) {
      return notify({ type: 'error', message: 'Please select birth date.' });
    }
    let data;
    const today = new Date();
    let date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
    date = new Date(date);
    const selectedDate = new Date(`${year}-${month}-${day}`);
    if (selectedDate > date) {
      return notify({ type: 'error', message: 'DOB date should be less than today!' });
    }
    if ((formData.password === null || formData.password === undefined) && id != null) {
      data = {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone: formData.phone,
        pincode: formData.pincode,
        gender: (formData.gender === 'male' ? 1 : 2),
        dob: `${year}-${month}-${day}`,
        is_verified: true,
        action_role: 'admin-user'
      };
    } else {
      data = {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone: formData.phone,
        pincode: formData.pincode,
        password: formData.password,
        gender: (formData.gender === 'male' ? 1 : 2),
        dob: `${year}-${month}-${day}`,
        is_verified: true,
        action_role: 'admin-user'
      };
    }
    if (id != null) {
      data.id = params.id;
      setLoader(true);
      API.updateCustomer(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/customers');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      setLoader(true);
      API.addCustomer(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/customers');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onChange = ({ target: { value } }) => {
    this.setState({ gender: value });
  }

  _onPressCancel = () => {
    browserHistory.push('/customers');
    this._clearForm();
  }

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      if (params.hasOwnProperty('id')) {
        this.setState({ isEdit: true });
        this._getUserById();
      }
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isEdit, gender, day, month, year
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>{(isEdit) ? 'Update Customer' : 'Create Customer'}</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this._onSave)} name="customerAddEditForm">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
                            <div className="col-sm-9">
                              <Field
                                name="email"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Email"
                                validate={[required, email, maxLength(30)]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="password" className="col-sm-3 col-form-label">Password</label>
                            <div className="col-sm-9">
                              {(isEdit) ? (
                                <Field
                                  name="password"
                                  component={renderField}
                                  type="password"
                                  className="form-control"
                                  placeholder="Password"
                                />
                              )
                                : (
                                  <Field
                                    name="password"
                                    component={renderField}
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    validate={[required, maxLength(30)]}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="confirm_password" className="col-sm-3 col-form-label">Confirm Password</label>
                            <div className="col-sm-9">
                              {(isEdit) ? (
                                <Field
                                  name="confirm_password"
                                  component={renderField}
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirm Password"
                                  validate={[passwordsMustMatch, maxLength(30)]}
                                />
                              )
                                : (
                                  <Field
                                    name="confirm_password"
                                    component={renderField}
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    validate={[required, passwordsMustMatch, maxLength(30)]}
                                  />
                                )}

                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="first_name" className="col-sm-3 col-form-label">
                              First Name
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="first_name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                validate={[required, maxLength(30)]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="last_name" className="col-sm-3 col-form-label">
                              Last Name
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="last_name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                component={renderField}
                                validate={[required, maxLength(30)]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="phone" className="col-sm-3 col-form-label">Phone</label>
                            <div className="col-sm-9">
                              <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                component={renderField}
                                validate={[PhoneRegEx]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="pincode" className="col-sm-3 col-form-label">Zip Code</label>
                            <div className="col-sm-9">
                              <Field
                                name="pincode"
                                type="text"
                                className="form-control"
                                placeholder="Zip Code"
                                component={renderField}
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="gender" className="col-sm-3 col-form-label">Gender</label>
                            <div className="col-sm-9">
                              <div className="selectgroup">
                                <label htmlFor="male" className="selectgroup-item">
                                  <Field
                                    id="male"
                                    type="radio" name="gender"
                                    value="male" className="selectgroup-input-radio"
                                    checked={gender === 'male'}
                                    onChange={({ target: { value } }) => {
                                      this.setState({ gender: value });
                                    }}
                                    component="input"
                                  />
                                  <span className="selectgroup-button">Male</span>
                                </label>
                                <label htmlFor="female" className="selectgroup-item">
                                  <Field
                                    id="female"
                                    type="radio" name="gender"
                                    value="female" component="input"
                                    checked={gender === 'female'}
                                    onChange={({ target: { value } }) => {
                                      this.setState({ gender: value });
                                    }}
                                    className="selectgroup-input-radio"
                                  />
                                  <span className="selectgroup-button">Female</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="dob" className="col-sm-3 col-form-label">DOB</label>
                            <div className="col-sm-9">
                              <div className="input-group">
                                <Select2
                                  style={{ width: '33%' }}
                                  className="form-control"
                                  data={getDays}
                                  options={{ placeholder: 'Select Day' }}
                                  onSelect={({ target: { value } }) => {
                                    this.setState({ day: value });
                                  }}
                                  value={day}
                                />
                                <Select2
                                  style={{ width: '33%' }}
                                  className="form-control"
                                  data={getMonths}
                                  options={{ placeholder: 'Select Month' }}
                                  onSelect={({ target: { value } }) => {
                                    this.setState({ month: value });
                                  }}
                                  value={month}
                                />
                                <Select2
                                  style={{ width: '34%' }}
                                  className="form-control"
                                  data={getYears()}
                                  options={{ placeholder: 'Select Year' }}
                                  onSelect={({ target: { value } }) => {
                                    this.setState({ year: value });
                                  }}
                                  value={year}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">{(isEdit) ? 'Update' : 'Save'}</button>
                            {' '}
                            &nbsp;&nbsp;
                            <button className="btn btn-primary" type="button" onClick={() => this._onPressCancel()}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'customerAddEditForm',
  destroyOnUnmount: false
});
export default withForm(withLoader(withNotify(withUser(CustomerAddEditForm))));
