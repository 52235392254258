import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { browserHistory, Link } from 'react-router';
import { email, renderField, required } from '../helpers/form-validations';
import withLoader from '../common/withLoader';
import withUser from '../common/withUser';
import withStorage from '../common/withStorage';
import withNotify from '../common/withNotify';
import API from '../utils/utils';

class ForgotPassword extends Component {
  _onForgotPassword = (formData) => {
    const {
      setLoader, notify
    } = this.props;
    setLoader(true);
    API.forgotPassword(formData).then(async (responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        browserHistory.push('/signin');
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="card card-primary">
                <div className="padding-20">
                  <div className="text-center">
                    <h4>Forgot Password?</h4>
                  </div>
                  <div className="text-center">
                    <p>
                      When you fill in your registration email
                      address, you will be sent instructions on how to reset your password.
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(this._onForgotPassword)}>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        name="email"
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Email Address"
                        component={renderField}
                        validate={[required, email]}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Reset Password
                      </button>
                    </div>
                    <div className="text-center">
                      <p>
                        Already have an account?&nbsp;
                        <Link to="/signin">
                          Login
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const withForm = reduxForm({
  form: 'forgotPasswordForm',
});

export default withForm(withNotify(withUser(withLoader(withStorage(ForgotPassword)))));
