import { store } from '../redux/store';
import { loaderSet, notifySet } from '../redux/actions';
import {
  userLogout,
  getAuthToken,
  queryString,
  convertFormData,
} from '../common/globals';
import { API_BASE_URL } from '../common/config';

const isTokenExpire = async (responseJson) => {
  const response = await responseJson;
  if (response.message === 'Unauthorized request') {
    await userLogout(true);
    return;
  }
  return response;
};

const handleNetworkError = async () => {
  store.dispatch(loaderSet(false));
  store.dispatch(
    notifySet({
      type: 'error',
      message: 'Network request error. Please try again.',
    }),
  );
};

const AppUtils = {
  login(data) {
    return fetch(`${API_BASE_URL}/admin/user/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async changePassword(data) {
    return fetch(`${API_BASE_URL}/admin/user/change-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async customerWishlist(data) {
    return fetch(
      `${API_BASE_URL}/admin/customer/wishlist/all?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getAdminDashboard() {
    return fetch(`${API_BASE_URL}/admin/order/admin-dashboard-statistics/count-order`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async orderList(data) {
    return fetch(`${API_BASE_URL}/admin/order?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async orderAddress(id) {
    return fetch(`${API_BASE_URL}/admin/order/${id}/address`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async contactUs(data) {
    return fetch(`${API_BASE_URL}/admin/contact-us?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteContactUs(data) {
    return fetch(`${API_BASE_URL}/admin/contact-us/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveContact(data) {
    return fetch(`${API_BASE_URL}/admin/contact-us/active-deactive/${data.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async customerList(data) {
    return fetch(`${API_BASE_URL}/admin/customer?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateCustomer(data) {
    return fetch(`${API_BASE_URL}/admin/customer/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveCustomer(data) {
    return fetch(`${API_BASE_URL}/admin/customer/active-deactive/${data.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addCustomer(data) {
    return fetch(`${API_BASE_URL}/admin/customer`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getCustomerById(data) {
    return fetch(`${API_BASE_URL}/admin/customer/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getAllCustomers(data = {}) {
    return fetch(`${API_BASE_URL}/admin/customer/name?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteCustomer(data) {
    return fetch(`${API_BASE_URL}/admin/customer/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addressList(data) {
    return fetch(
      `${API_BASE_URL}/admin/customer/${data.id}/address?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getAddressById(data) {
    return fetch(`${API_BASE_URL}/admin/customer/address/${data.addressId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addAddress(data) {
    return fetch(`${API_BASE_URL}/admin/customer/${data.id}/address`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateAddress(data) {
    return fetch(
      `${API_BASE_URL}/admin/customer/${data.id}/address/${data.addressId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
        body: JSON.stringify(data),
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteAddress(data) {
    return fetch(
      `${API_BASE_URL}/admin/customer/${data.id}/address/${data.addressId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getAllCountries() {
    return fetch(`${API_BASE_URL}/admin/country/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // supplier
  async getSuppliers(data) {
    return fetch(`${API_BASE_URL}/admin/supplier?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateSupplier(data) {
    return fetch(`${API_BASE_URL}/admin/supplier/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Country
  async getCountries(data) {
    return fetch(`${API_BASE_URL}/admin/country?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addCountry(data) {
    return fetch(`${API_BASE_URL}/admin/country`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteCountry(data) {
    return fetch(`${API_BASE_URL}/admin/country/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateCountry(data) {
    return fetch(`${API_BASE_URL}/admin/country/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getStates(data) {
    return fetch(`${API_BASE_URL}/admin/state?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addState(data) {
    return fetch(`${API_BASE_URL}/admin/state`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteState(data) {
    return fetch(`${API_BASE_URL}/admin/state/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateState(data) {
    return fetch(`${API_BASE_URL}/admin/state/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getStateByCountry(data) {
    return fetch(`${API_BASE_URL}/admin/state/${data.countryId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Best seller
  async getBestSellerProduct(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/best-sellers/all?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Top trending
  async getTopTrendingProduct(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/top-trending/all?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // common
  async uploadImage(data) {
    return fetch(`${API_BASE_URL}/admin/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthToken(true),
      },
      body: await convertFormData(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async uploadBannerImage(data) {
    return fetch(`${API_BASE_URL}/admin/upload-image/banner`, {
      method: 'POST',
      headers: {
        Authorization: await getAuthToken(true),
      },
      body: await convertFormData(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // System Log
  async getSystemLogs(data) {
    return fetch(`${API_BASE_URL}/admin/system-log?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Visitor Log
  async getVisitorLogs(data) {
    return fetch(`${API_BASE_URL}/admin/customer/visitor/log?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Category
  async getCategories(data) {
    return fetch(`${API_BASE_URL}/admin/category?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getCategoryById(data) {
    return fetch(`${API_BASE_URL}/admin/category/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addCategory(data) {
    return fetch(`${API_BASE_URL}/admin/category`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateCategory(data) {
    return fetch(`${API_BASE_URL}/admin/category/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteCategory(id) {
    return fetch(`${API_BASE_URL}/admin/category/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async forgotPassword(data) {
    return fetch(`${API_BASE_URL}/admin/user/forgot-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async resetPassword(data) {
    return fetch(`${API_BASE_URL}/admin/user/reset-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getNewHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/get-all?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addNewHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/new`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },
  async deleteNewHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/new-delete/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/active-deactive/${data.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },
  async activeDeactiveNewHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/new-active-deactive/${data.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },
  async updateNewHomeBanner(data) {
    return fetch(`${API_BASE_URL}/admin/banner/new-update/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getAllEmailsTemplates(data) {
    return fetch(`${API_BASE_URL}/admin/email-template?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getEmailTemplateById(data) {
    return fetch(`${API_BASE_URL}/admin/email-template/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addEmailTemplate(data) {
    return fetch(`${API_BASE_URL}/admin/email-template`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateEmailTemplate(data) {
    return fetch(`${API_BASE_URL}/admin/email-template/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateIsDeleteEmailTemplate(data) {
    return fetch(`${API_BASE_URL}/admin/email-template/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async taxList(data) {
    return fetch(`${API_BASE_URL}/admin/tax?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteTax(data) {
    return fetch(`${API_BASE_URL}/admin/tax/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getTaxById(data) {
    return fetch(`${API_BASE_URL}/admin/tax/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateTax(data) {
    return fetch(`${API_BASE_URL}/admin/tax/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addTax(data) {
    return fetch(`${API_BASE_URL}/admin/tax`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveTax(data) {
    return fetch(`${API_BASE_URL}/admin/tax/active-deactive/${data.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Manage SEO
  async getSeoDetails(data) {
    return fetch(`${API_BASE_URL}/admin/seo?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getSeoDetailById(id) {
    return fetch(`${API_BASE_URL}/admin/seo/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addSeoDetail(data) {
    return fetch(`${API_BASE_URL}/admin/seo`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateSeoDetail(data) {
    return fetch(`${API_BASE_URL}/admin/seo/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteSeoDetail(data) {
    return fetch(`${API_BASE_URL}/admin/seo/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // CMS Pages
  async getCmsPages(data) {
    return fetch(`${API_BASE_URL}/admin/cms?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addCmsPage(data) {
    return fetch(`${API_BASE_URL}/admin/cms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteCmsPage(data) {
    return fetch(`${API_BASE_URL}/admin/cms/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getProductSeoById(id) {
    return fetch(`${API_BASE_URL}/admin/product/seo-details/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProductSeoDetails(data) {
    return fetch(`${API_BASE_URL}/admin/product/seo-details`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateProductSeoDetails(data) {
    return fetch(`${API_BASE_URL}/admin/product/seo-details/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getCmsPageById(data) {
    return fetch(`${API_BASE_URL}/admin/cms/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveCmsPage(data) {
    return fetch(`${API_BASE_URL}/admin/cms/active-deactive/${data.id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateCmsPage(data) {
    return fetch(`${API_BASE_URL}/admin/cms/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // General Setting
  async getGeneralSetting() {
    return fetch(`${API_BASE_URL}/admin/general-settings`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateGeneralSetting(data, query) {
    return fetch(`${API_BASE_URL}/admin/general-settings/${data.id}/${query}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateMaintenanceMode() {
    return fetch(
      `${API_BASE_URL}/admin/general-settings/update-maintenance-mode`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getOrderById(data) {
    return fetch(`${API_BASE_URL}/admin/order/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  //  Customer Notify Me

  async notifyList(data) {
    return fetch(
      `${API_BASE_URL}/admin/customer/notifyMe/all?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteNotifyMe(data) {
    return fetch(`${API_BASE_URL}/admin/customer/notifyMe/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getNotifyById(data) {
    return fetch(`${API_BASE_URL}/admin/customer/notifyMe/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addNotify(data) {
    return fetch(`${API_BASE_URL}/admin/customer/notifyMe`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Products

  async productList(data) {
    return fetch(`${API_BASE_URL}/admin/product?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateProduct(data) {
    return fetch(`${API_BASE_URL}/admin/product/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProduct(data) {
    return fetch(`${API_BASE_URL}/admin/product`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getProductById(data) {
    return fetch(`${API_BASE_URL}/admin/product/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Product Image

  async getProductImgByProductId(data) {
    return fetch(`${API_BASE_URL}/admin/product/imageDetails/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProductImage(data) {
    return fetch(`${API_BASE_URL}/admin/product/imageDetails`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteProductImage(data) {
    return fetch(`${API_BASE_URL}/admin/product/imageDetails/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getBestSellerById(data) {
    return fetch(`${API_BASE_URL}/admin/product/best-seller/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProductToBestSeller(data) {
    return fetch(`${API_BASE_URL}/admin/product/add-to-best-seller`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteProductFromBestSeller(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/remove-from-best-seller/${data.id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getTopTrendingProductById(data) {
    return fetch(`${API_BASE_URL}/admin/product/top-trending/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProductToTopTrending(data) {
    return fetch(`${API_BASE_URL}/admin/product/add-to-top-trending`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteProductFromTopTrending(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/remove-from-top-trending/${data.id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Product Video

  async getProductVideoByProductId(data) {
    return fetch(`${API_BASE_URL}/admin/product/videoDetails/${data.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteProduct(data) {
    return fetch(`${API_BASE_URL}/admin/product/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProductVideo(data) {
    return fetch(`${API_BASE_URL}/admin/product/videoDetails/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteProductVideo(data) {
    return fetch(`${API_BASE_URL}/admin/product/videoDetails/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // Product Review

  async reviewList(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/get/review?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveReviewRating(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/review/active-deactive/${data.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // *************** Notification ****************//
  async getNotification(data) {
    return fetch(`${API_BASE_URL}/admin/notification?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addNotification(data) {
    return fetch(`${API_BASE_URL}/admin/notification`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateNotification(data) {
    return fetch(`${API_BASE_URL}/admin/notification/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async activeDeactiveNotification(id) {
    return fetch(`${API_BASE_URL}/admin/notification/active-deactive/${id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteNotification(data) {
    return fetch(`${API_BASE_URL}/admin/notification/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // *************** Discount Code ****************//

  async getDiscountCode(data) {
    return fetch(`${API_BASE_URL}/admin/discount-code?${queryString(data)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addDiscountCode(data) {
    return fetch(`${API_BASE_URL}/admin/discount-code`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteDiscountCode(data) {
    return fetch(`${API_BASE_URL}/admin/discount-code/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateDiscountCode(data) {
    return fetch(`${API_BASE_URL}/admin/discount-code/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async discountCustomer(data) {
    return fetch(
      `${API_BASE_URL}/admin/customer/customer-discount/discountId?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // ******** Recently View Products **********//

  async recentlyViewProducts(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/recently-view/products?${queryString(
        data,
      )}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async blockUnblockRecentlyProduct(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/recently-view/block-unblock/${data.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  // ********** Product Attribute ************//

  async getAllProducts() {
    return fetch(`${API_BASE_URL}/admin/product/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async getProductAttribute(data) {
    return fetch(
      `${API_BASE_URL}/admin/product/attributes/all?${queryString(data)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: await getAuthToken(true),
        },
      },
    )
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async addProductAttribute(data) {
    return fetch(`${API_BASE_URL}/admin/product/attributes`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async deleteProductAttribute(data) {
    return fetch(`${API_BASE_URL}/admin/product/attributes/${data.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },

  async updateProductAttribute(data) {
    return fetch(`${API_BASE_URL}/admin/product/attributes/${data.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: await getAuthToken(true),
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => await isTokenExpire(response.json()))
      .catch((error) => handleNetworkError(error));
  },
};

export default AppUtils;
