import { isEmpty } from 'lodash';
import { store } from '../redux/store';
import { resetRedux, notifySet } from '../redux/actions';

export const USER_STORAGE_KEY = 'MYBOKEKEN_USER_DATA';

export const clearAll = async (next) => {
  await localStorage.setItem(USER_STORAGE_KEY, null);
  await next(true);
};

// GET USER FROM LOCAL STORAGE
export const getUser = async () => {
  const user = await localStorage.getItem(USER_STORAGE_KEY);
  return user !== 'null' && !isEmpty(user) ? JSON.parse(user) : null;
};

// GET USER TOKEN FOR API OR JUST TOKEN
export const getAuthToken = async (forAPICall = false) => {
  const AuthType = forAPICall ? 'Bearer ' : '';
  const user = await getUser();
  return AuthType + (isEmpty(user) ? '' : user.token);
};

// STORE USER IN LOCAL STORAGE
export const storeUser = (user) => {
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
};

// UPDATE USER IN LOCAL STORAGE
export const updateUser = async (data) => {
  const user = { ...(await getUser()), ...data };
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  return user;
};

// REMOVE USER FROM REDUX & LOCAL STORAGE
export const userLogout = async (isExpired = false) => {
  await clearAll(() => {
    setTimeout(() => {
      store.dispatch(resetRedux());
      if (isExpired) {
        store.dispatch(
          notifySet({
            type: 'warning',
            message: 'Your session has been expired.',
          }),
        );
      } else {
        store.dispatch(
          notifySet({
            type: 'success',
            message: 'You have successfully signed out.',
          }),
        );
      }
      window.location = '/signin';
    }, 500);
  });
};

// CONVERT OBJECT TO QUERY STRING
export const queryString = (obj) => {
  const str = [];
  Object.keys(obj).map((p) => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
    return true;
  });
  return str.join('&');
};

export const defaultProfileImage = '/src/assets/images/no-profile.jpg';

export const buildFormData = (data) => {
  if (
    data
    && typeof data === 'object'
  ) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  }
};

export const getDays = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

export const getMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const getYears = (startYear) => {
  const currentYear = new Date().getFullYear(); const
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years.reverse();
};

export const convertFormData = async (data) => buildFormData(data);

export const getParameterByName = (name, url = window.location.href) => {
  const replaceName = name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${replaceName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
