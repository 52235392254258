import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
// import Select2 from 'react-select2-wrapper';
import { CDN_BANNER_IMAGE_URL } from '../../common/config';
import withNotify from '../../common/withNotify';
import {
  maxLength,
  renderField,
  required,
  allowNumberRegEx,
  urlRegEx,
} from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';

class AddEditHomeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      imageView: '',
      sidebannerImageView1: '',
      sidebannerImageView2: '',
      colorList: [{}],
      selectedColor: null,
      columnList: [{}],
      selectedColumn: null,
      // sliderImage: '',
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditHomeBanner'));
      this.setState({ imageView: '' });
    }
    document.getElementById('modalAddEditSliderImage').click();
  };

  _onCancelImage = () => {
    this._resetForm();
  };

  _onSelectImage = async (imageData, type) => {
    const { notify } = this.props;
    if (imageData) {
      const imageSize = imageData.files[0].size;
      if (imageSize > 1024000) {
        notify({
          type: 'warning',
          message: 'Image size shoud be less than 1Mb.',
        });
        return;
      }
      const mimeType = imageData.files[0].type.split('/')[1];
      if (mimeType !== 'png' && mimeType !== 'jpeg' && mimeType !== 'webp') {
        notify({
          type: 'warning',
          message: 'Only jpeg/png/wepb images are supported.',
        });
        return;
      }
      const readers = new FileReader();
      readers.readAsDataURL(imageData.files[0]);

      const uploadFormData = new FormData();
      uploadFormData.append('file', imageData.files[0]);
      const { setLoader } = this.props;
      setLoader(true);
      const data = { imageFile: imageData.files[0] };
      await API.uploadBannerImage(data).then((responseJson) => {
        setLoader(false);
        const { picture, status } = responseJson;
        if (status) {
          if (type === 'main-banner') {
            this.setState({ imageView: picture });
          } else if (type === 'side-banner-1') {
            this.setState({ sidebannerImageView1: picture });
          } else if (type === 'side-banner-2') {
            this.setState({ sidebannerImageView2: picture });
          }
        }
      });
    }
  };

  _getColors = (column) => {
    if (column === '3') {
      this.setState({
        colorList: [
          { _id: 'redBlock', name: 'Red' },
          { _id: 'lightBlueBlock', name: 'Light Blue' },
          { _id: 'yellowBlock', name: 'Yellow' },
        ],
      });
    } else {
      this.setState({
        colorList: [
          { _id: 'redBlock', name: 'Red' },
          { _id: 'darkBlueBlock', name: 'Dark Blue' },
          { _id: 'lightBlueBlock', name: 'Light Blue' },
          { _id: 'yellowBlock', name: 'Yellow' },
        ],
      });
    }
  };

  _onDeleteImage = () => {
    this.setState({ imageView: '' });
  };

  _onAddImage = async (formData) => {
    const {
      imageView, sidebannerImageView1, sidebannerImageView2, selectedColor, selectedColumn
    } = this.state;
    const { addEditSliderImage } = this.props;

    // if (image !== '') {
    const data = {
      language: '1',
      image_url: imageView,
      side_banner_image_url_1: sidebannerImageView1,
      side_banner_image_url_2: sidebannerImageView2,
      alt_text: formData.alt_text,
      alt_text_1: formData.alt_text_1,
      alt_text_2: formData.alt_text_2,
      // title_en: formData.title_en,
      // title_de: formData.title_de,
      // title_du: formData.title_du,
      // short_title_en: formData.short_title_en,
      // short_title_de: formData.short_title_de,
      // short_title_du: formData.short_title_du,
      banner_color: selectedColor,
      column: selectedColumn,
      click_url: formData.link,
      side_banner_click_url_1: formData.side_banner_click_url_1,
      side_banner_click_url_2: formData.side_banner_click_url_2,
      sequence: formData.sequence,
    };
    addEditSliderImage(data);
    this._resetForm();
    // } else {
    //   notify({ type: 'error', message: 'Please Select Image' });
    // }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const { selectedColumn } = this.state;
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (
      JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0
    ) {
      this.setState({
        imageView: data.image_url,
        sidebannerImageView1: data.side_banner_image_url_1,
        sidebannerImageView2: data.side_banner_image_url_2
      });
      dispatch(change('addEditHomeBanner', 'side_banner_click_url_2',
        data.side_banner_click_url_2));
      dispatch(change('addEditHomeBanner', 'title_en', data.title_en));
      dispatch(change('addEditHomeBanner', 'title_de', data.title_de));
      dispatch(change('addEditHomeBanner', 'title_du', data.title_du));
      dispatch(
        change('addEditHomeBanner', 'short_title_en', data.short_title_en),
      );
      dispatch(
        change('addEditHomeBanner', 'short_title_de', data.short_title_de),
      );
      dispatch(
        change('addEditHomeBanner', 'short_title_du', data.short_title_du),
      );
      dispatch(change('addEditHomeBanner', 'link', data.link));
      dispatch(change('addEditHomeBanner', 'side_banner_click_url_1',
        data.side_banner_click_url_1));

      dispatch(change('addEditHomeBanner', 'alt_text', data.alt_text));
      dispatch(change('addEditHomeBanner', 'alt_text_1', data.alt_text_1));
      dispatch(change('addEditHomeBanner', 'alt_text_2', data.alt_text_2));
      dispatch(change('addEditHomeBanner', 'sequence', data.sequence));
      this.setState({ selectedColumn: data.column });
      if (data.column === 3) {
        this.setState({
          colorList: [
            { _id: 'redBlock', name: 'Red' },
            { _id: 'lightBlueBlock', name: 'Light Blue' },
            { _id: 'yellowBlock', name: 'Yellow' },
          ],
        });
      } else {
        this.setState({
          colorList: [
            { _id: 'redBlock', name: 'Red' },
            { _id: 'darkBlueBlock', name: 'Dark Blue' },
            { _id: 'lightBlueBlock', name: 'Light Blue' },
            { _id: 'yellowBlock', name: 'Yellow' },
          ],
        });
      }
      this.setState({ selectedColor: data.banner_color });
    } else {
      this.setState({
        columnList: [
          { _id: '2', name: 'Two Blocks' },
          { _id: '3', name: 'Three Blocks' },
        ],
        // imageView: '',
        // sidebannerImageView1: '',
        // sidebannerImageView2: '',
      });
      if (Object.keys(data).length === 0) {
        // this.setState({
        //   imageView: '',
        //   sidebannerImageView1: '',
        //   sidebannerImageView2: '',
        // });
        // dispatch(reset('addEditHomeBanner'));
      }
    }
  }

  render() {
    const { handleSubmit, data } = this.props;
    // colorList, columnList, selectedColor, selectedColumn
    const {
      imageView, sidebannerImageView1, sidebannerImageView2
    }
      = this.state;
    return (
      <div
        className="modal fade"
        id="modalAddEditSliderImage"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">
                {`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} Image`}
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => this._resetForm()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              name="addEditHomeBanner"
              onSubmit={handleSubmit(this._onAddImage)}
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="font-weight-bold text-dark">Main Banner</h6>
                    <div className="form-group form-row">
                      <label
                        htmlFor="image"
                        className="col-sm-3 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-9">
                        <label
                          htmlFor="cat_image"
                          className="col-form-label from-row"
                        >
                          <small style={{ color: 'red' }}>
                            File Size (1116 * 453)
                          </small>
                        </label>
                        <div className="row ml-3">
                          <img
                            src={
                              imageView === ''
                                ? '/assets/images/no-image-icon.png'
                                : CDN_BANNER_IMAGE_URL + imageView
                              // : IMAGE_BASE_URL + imageView
                            }
                            onError={() => {
                              this.onerror = null;
                              this.src = '/assets/images/no-image-icon.png';
                            }}
                            style={{ width: 50 }}
                            className="col-3"
                            alt="image"
                          />
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm col-6 ml-3 mb-1"
                              onClick={() => document.getElementById('selectedFile').click()}
                            >
                              {imageView === ''
                                ? 'Upload Image'
                                : 'Change Image'}
                            </button>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="selectedFile"
                              onChange={(e) => this._onSelectImage(e.target, 'main-banner')}
                            />
                            {imageView !== '' && (
                              <button
                                type="button"
                                className="btn btn-sm btn-danger col-6 ml-3"
                                onClick={() => this._onDeleteImage()}
                              >
                                Delete Image
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="column"
                        className="col-sm-3 col-form-label"
                      >
                        Banner Block
                      </label>
                      <div className="col-sm-9">
                        <Select2
                          style={{ width: '100%' }}
                          className="form-control"
                          data={columnList.map(({ name, _id }) => ({
                            id: _id,
                            text: name,
                          }))}
                          options={{ placeholder: 'Select block' }}
                          value={selectedColumn}
                          onSelect={({ target: { value } }) => {
                            this.setState({ selectedColumn: value }, () => {
                              this._getColors(value);
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="banner_color"
                        className="col-sm-3 col-form-label"
                      >
                        Banner Block Color
                      </label>
                      <div className="col-sm-9">
                        <Select2
                          style={{ width: '100%' }}
                          className="form-control"
                          data={colorList.map(({ name, _id }) => ({
                            id: _id,
                            text: name,
                          }))}
                          options={{ placeholder: 'Select color' }}
                          value={selectedColor}
                          onSelect={({ target: { value } }) => {
                            this.setState({ selectedColor: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Banner Sequence
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="sequence"
                          component={renderField}
                          className="form-control"
                          placeholder="Add sequence (Number)"
                          validate={[required, allowNumberRegEx]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*  <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Title(en)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_en"
                          component={renderField}
                          className="form-control"
                          placeholder="Title(en)"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Title(de))
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_de"
                          component={renderField}
                          className="form-control"
                          placeholder="Title(de))"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Title(du)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_du"
                          component={renderField}
                          className="form-control"
                          placeholder="Title(du)"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Alt Text
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="alt_text"
                          component={renderField}
                          className="form-control"
                          placeholder="Alt Text"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Short Title(en)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_title_en"
                          component={renderField}
                          className="form-control"
                          placeholder="Short Title(en)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Short Title(de)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_title_de"
                          component={renderField}
                          className="form-control"
                          placeholder="Short Title(de)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Short Title(du)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_title_du"
                          component={renderField}
                          className="form-control"
                          placeholder="Short Title(du)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="link" className="col-sm-3 col-form-label">
                        Link
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="link"
                          component={renderField}
                          className="form-control"
                          placeholder="Link"
                          validate={[required, urlRegEx]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="font-weight-bold text-dark">Side Banner 1</h6>
                    <div className="form-group form-row">
                      <label
                        htmlFor="image"
                        className="col-sm-3 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-9">
                        <label
                          htmlFor="cat_image"
                          className="col-form-label from-row"
                        >
                          <small style={{ color: 'red' }}>
                            File Size (1116 * 453)
                          </small>
                        </label>
                        <div className="row ml-3">
                          <img
                            src={
                              sidebannerImageView1 === ''
                                ? '/assets/images/no-image-icon.png'
                                : CDN_BANNER_IMAGE_URL + sidebannerImageView1
                            }
                            onError={() => {
                              this.onerror = null;
                              this.src = '/assets/images/no-image-icon.png';
                            }}
                            style={{ height: 75, width: 50 }}
                            className="col-3"
                            alt="image"
                          />
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm col-6 ml-3 mb-1"
                              onClick={() => document.getElementById('selectedFile1').click()}
                            >
                              {sidebannerImageView1 === ''
                                ? 'Upload'
                                : 'Change'}
                            </button>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="selectedFile1"
                              onChange={(e) => this._onSelectImage(e.target, 'side-banner-1')}
                            />
                            {sidebannerImageView1 !== '' && (
                              <button
                                type="button"
                                className="btn btn-sm btn-danger col-6 ml-3"
                                onClick={() => this._onDeleteImage()}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h6 className="font-weight-bold text-dark">Side Banner 2</h6>
                    <div className="form-group form-row">
                      <label
                        htmlFor="image"
                        className="col-sm-3 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-9">
                        <label
                          htmlFor="cat_image"
                          className="col-form-label from-row"
                        >
                          <small style={{ color: 'red' }}>
                            File Size (1116 * 453)
                          </small>
                        </label>
                        <div className="row ml-3">
                          <img
                            src={
                              sidebannerImageView2 === ''
                                ? '/assets/images/no-image-icon.png'
                                : CDN_BANNER_IMAGE_URL + sidebannerImageView2
                            }
                            onError={() => {
                              this.onerror = null;
                              this.src = '/assets/images/no-image-icon.png';
                            }}
                            style={{ height: 75, width: 50 }}
                            className="col-3"
                            alt="image"
                          />
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm col-6 ml-3 mb-1"
                              onClick={() => document.getElementById('selectedFile2').click()}
                            >
                              {sidebannerImageView2 === ''
                                ? 'Upload'
                                : 'Change'}
                            </button>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="selectedFile2"
                              onChange={(e) => this._onSelectImage(e.target, 'side-banner-2')}
                            />
                            {sidebannerImageView2 !== '' && (
                              <button
                                type="button"
                                className="btn btn-sm btn-danger col-6 ml-3"
                                onClick={() => this._onDeleteImage()}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Alt Text
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="alt_text_1"
                          component={renderField}
                          className="form-control"
                          placeholder="Alt Text"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Alt Text
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="alt_text_2"
                          component={renderField}
                          className="form-control"
                          placeholder="Alt Text"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-row">
                      <label htmlFor="link" className="col-sm-3 col-form-label">
                        Link
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="side_banner_click_url_1"
                          component={renderField}
                          className="form-control"
                          placeholder="Link"
                          validate={[required, urlRegEx]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-row">
                      <label htmlFor="link" className="col-sm-3 col-form-label">
                        Link
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="side_banner_click_url_2"
                          component={renderField}
                          className="form-control"
                          placeholder="Link"
                          validate={[required, urlRegEx]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">
                  {Object.keys(data).length > 0 ? 'Update' : 'Save'}
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditHomeBanner',
});
export default withLoader(withForm(withNotify(AddEditHomeBanner)));
