import React, { PureComponent } from 'react';
import { browserHistory } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import Pagination from '../Pagination';

class CustomerDiscountListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: 'created_date',
      sortOrder: 'asc'
    };
  }

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  _onPressCancel = () => {
    browserHistory.push('/site-settings/discount-code');
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const { sortKey, sortOrder, } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>
                Customer Discount List
                { ' ' }
                {/* {`${customerName ? `(Customer Name : ${customerName} )` : ''} `}
                {`${productName ? `(Product Name : ${productName} )` : ''} `} */}
              </h4>
              <div className="card-header-action home-banner-button">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this._onPressCancel()}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'discount_name' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'discount_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Discount Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'discount_type' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'discount_type', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Customer Type
                      </th>
                      <th
                        className={`sort ${sortKey === 'customer_name' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'customer_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Customer Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'customer_email' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'customer_email', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Customer Email
                      </th>
                      <th>
                        Discount Amount
                      </th>
                      <th>
                        VAT Amount
                      </th>
                      <th>
                        Payable Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((item, key) => (
                        <tr key={key}>
                          <td>{item.discount_name}</td>
                          <td>{(item.discount_type ? 'Special' : 'Public') || '-'}</td>
                          <td>
                            {item.customer_name || '-'}
                          </td>
                          <td>
                            {item.customer_email || '-'}
                          </td>
                          <td>{item.discount_amt}</td>
                          <td>{item.vat_amt}</td>
                          <td>{item.payable_amount}</td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNotify(withLoader(CustomerDiscountListSection));
