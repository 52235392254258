import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import withUser from '../common/withUser';

const Footer = (props) => {
  const { user } = props;
  return (
    !isEmpty(user) ? (
      <footer className="main-footer">
        <div className="footer-left">
          Copyright &copy;
          {' '}
          {moment().format('YYYY')}
          {' '}
          <Link to="/"> MyBoeken </Link>
        </div>
        <div className="footer-right" />
      </footer>
    ) : <></>
  );
};

export default withUser(Footer);
