import moment from 'moment';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import ConfirmBox from '../Models/ConfirmBox';
import WarningBox from '../Models/WarningBox';
import Pagination from '../Pagination';

class ContactUsSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      selectedId: null,
      sortKey: 'created_date',
      sortOrder: 'desc',
      activeId: null,
      isActiveOpen: false,
      contactStatus: null
    };
  }

  _onActiveDeactiveAlert = (id, status) => {
    this.setState({ isActiveOpen: true, activeId: id, contactStatus: status });
  };

  _onPressActiveDeactiveIcon = () => {
    const { onActiveDeactive } = this.props;
    const { activeId } = this.state;
    this.setState({ isActiveOpen: false, activeId: null });
    onActiveDeactive(activeId);
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onPressDeleteIcon = () => {
    const { onDelete } = this.props;
    const { selectedId } = this.state;
    this.setState({ isDeleteConfirmOpen: false, selectedId: null });
    onDelete(selectedId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const {
      isDeleteConfirmOpen, sortKey, sortOrder, isActiveOpen, contactStatus
    } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Contact Us List</h4>
              <div className="card-header-action" />
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'created_date' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'created_date', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Date
                      </th>
                      <th
                        className={`sort ${sortKey === 'name' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'email' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'email', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Email
                      </th>
                      <th
                        className={`sort ${sortKey === 'phoneno' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'phoneno', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Phone No.
                      </th>
                      <th>
                        Comment
                      </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((user, key) => (
                        <tr key={key}>
                          <td>
                            {moment(user.created_date).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            {user.name}
                          </td>
                          <td>{(user.email)}</td>
                          <td>{user.phoneno}</td>
                          <td>
                            {(user.comment)
                              ? (
                                <span>
                                  <input
                                    data-toggle="tooltip"
                                    title={user.comment}
                                    type="radio"
                                    name="rGroup"
                                    className="fas fa-info-circle"
                                  />
                                  <label className="btn btn-default" htmlFor="rGroup">{' '}</label>
                                </span>
                              )
                              : '-'}
                          </td>
                          <td>
                            <div className="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`checkbox-${key}`}
                                checked={user.status === 1}
                                onChange={() => this._onActiveDeactiveAlert(user._id, user.status)}
                                aria-labelledby={`label-${key}`}
                              />
                              <label
                                htmlFor={`checkbox-${key}`}
                                id={`label-${key}`}
                                className="custom-control-label"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>
                          <td>
                            <Link title="Delete" onClick={() => this._onDeleteAlert(user._id)} className="cursor" aria-label="Delete">
                              <i className="far fa-trash-alt" aria-hidden="true" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Contact Request"
          text="Are you sure want to delete this contact request?"
          onConfirm={() => this._onPressDeleteIcon()}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false, selectedId: null });
          }}
        />
        <WarningBox
          isOpen={isActiveOpen}
          title={contactStatus ? 'Reject Contact Request' : 'Approve Contact Request'}
          text={contactStatus
            ? 'Are you sure you want to reject contact request?'
            : 'Are you sure you want to approve contact request?'}
          onConfirm={this._onPressActiveDeactiveIcon}
          onCancel={() => {
            this.setState({ isActiveOpen: false });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(ContactUsSection));
