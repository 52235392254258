import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import {
  change, reset,
  Field, reduxForm
} from 'redux-form';
import { browserHistory } from 'react-router';
import { isEmpty } from 'lodash';
import {
  maxLength, PhoneRegEx, postalCodeRegEx,
  renderField, required
} from '../../helpers/form-validations';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';

class AddressAddEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      selectedState: null,
      selectedCountry: null,
      countries: [],
      states: [],
      customerName: null,
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('addressAddEditForm'));
  };

  _getAddressById = () => {
    const {
      setLoader, notify, params: { addressId }, dispatch
    } = this.props;
    setLoader(true);
    API.getAddressById({ addressId }).then((responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      this.setState({
        selectedCountry: data.country_id,
        selectedState: data.state_id
      });
      this._getStates(data.country_id);
      if (status) {
        Object.keys(data).map((key) => {
          dispatch(change('addressAddEditForm', key, data[key]));
        });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _getName = () => {
    const {
      setLoader, params: { id }
    } = this.props;
    setLoader(true);
    API.getCustomerById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, customer } = responseJson;
      if (status) {
        this.setState({
          customerName: `${customer.first_name} ${customer.last_name}`
        });
      }
    });
  }

  _onSave = (formData) => {
    const {
      setLoader, notify, params, params: { id, addressId = null }
    } = this.props;
    const { selectedCountry, selectedState } = this.state;
    if (selectedCountry === null) {
      notify({ type: 'error', message: 'Please Select Country' });
    } else if (selectedState === null) {
      notify({ type: 'error', message: 'Please Select State' });
    } else {
      const data = {
        id,
        addressId,
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        city: formData.city,
        country_id: selectedCountry,
        state_id: selectedState,
        pincode: formData.pincode,
        address_title: formData.address_title,
        phoneNo: formData.phoneNo,
        action_role: 'admin-user'
      };

      if (addressId != null) {
        data.addressId = params.addressId;
        setLoader(true);
        API.updateAddress(data).then((responseJson) => {
          setLoader(false);
          const { status, message } = responseJson;
          if (status) {
            notify({ type: 'success', message });
            browserHistory.push(`/customers/${id}/address`);
            this._clearForm();
          } else {
            notify({ type: 'error', message });
          }
        });
      } else {
        setLoader(true);
        API.addAddress(data).then((responseJson) => {
          setLoader(false);
          const { status, message } = responseJson;
          if (status) {
            notify({ type: 'success', message });
            browserHistory.push(`/customers/${id}/address`);
            this._clearForm();
          } else {
            notify({ type: 'error', message });
          }
        });
      }
    }
  };

  _onChange = ({ target: { value } }) => {
    this.setState({ gender: value });
  }

  _onPressCancel = () => {
    const { id } = this.props.params;
    browserHistory.push(`/customers/${id}/address`);
    this._clearForm();
  }

  _getCountries = () => {
    const { notify } = this.props;
    API.getAllCountries().then((responseJson) => {
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countries: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _getStates = (countryId) => {
    const { setLoader, notify } = this.props;
    API.getStateByCountry({ countryId }).then((responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ states: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      this._getCountries();
      this._getName();
      if (params.hasOwnProperty('addressId')) {
        this.setState({ isEdit: true }, () => {
          this._getAddressById();
        });
      }
      this._clearForm();
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isEdit, selectedState, selectedCountry, countries, states, customerName
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>
                      {(isEdit) ? 'Update Address' : 'Create Address'}
                      {'  '}
                      {`${customerName ? `(Customer Name : ${customerName} )` : ''} `}
                    </h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this._onSave)} name="addressAddEditForm">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="address_title" className="col-sm-3 col-form-label">Address Title</label>
                            <div className="col-sm-9">
                              <Field
                                name="address_title"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Address title"
                                validate={[maxLength(300)]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="address_line_1" className="col-sm-3 col-form-label">Address Line 1</label>
                            <div className="col-sm-9">
                              <Field
                                name="address_line_1"
                                component={renderField}
                                type="textarea"
                                className="form-control"
                                placeholder="Address Line 1"
                                validate={[required, maxLength(300)]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="address_line_2" className="col-sm-3 col-form-label">Address Line 2</label>
                            <div className="col-sm-9">
                              <Field
                                name="address_line_2"
                                component={renderField}
                                type="textarea"
                                className="form-control"
                                placeholder="Address Line 2"
                                validate={[required, maxLength(300)]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="country" className="col-sm-3 col-form-label">Country</label>
                            <div className="col-sm-9">
                              <Select2
                                style={{ width: '100%' }}
                                className="form-control"
                                data={
                                  countries.map(({ name, _id }) => ({ id: _id, text: name }))
                                }
                                options={{ placeholder: 'Select Country' }}
                                value={selectedCountry}
                                onSelect={({ target: { value } }) => {
                                  this.setState({ selectedCountry: value }, () => {
                                    this._getStates(value);
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="state" className="col-sm-3 col-form-label">State</label>
                            <div className="col-sm-9">
                              <Select2
                                style={{ width: '100%' }}
                                className="form-control"
                                data={states.map(({ name, _id }) => ({ id: _id, text: name }))}
                                onSelect={({ target: { value } }) => {
                                  this.setState({ selectedState: value });
                                }}
                                value={selectedState}
                                options={{
                                  placeholder: 'Select State',
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="city" className="col-sm-3 col-form-label">City</label>
                            <div className="col-sm-9">
                              <Field
                                name="city"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="City"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="pincode" className="col-sm-3 col-form-label">
                              Postal code
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="pincode"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Postal code"
                                validate={[required, postalCodeRegEx]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="phoneNo" className="col-sm-3 col-form-label">
                              Mobile number
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="phoneNo"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Mobile number"
                                validate={[required, PhoneRegEx]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">{(isEdit) ? 'Update' : 'Save'}</button>
                            {' '}
                            &nbsp;&nbsp;
                            <button className="btn btn-primary" type="button" onClick={() => this._onPressCancel()}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addressAddEditForm',
  destroyOnUnmount: false
});
export default withForm(withLoader(withNotify(withUser(AddressAddEditForm))));
