import React, { PureComponent } from 'react';
import NotificationSystem from 'react-notification-system';
import withNotify from '../common/withNotify';

class Notify extends PureComponent {
  constructor(props) {
    super(props);
    this.notificationSystem = null;
  }

  addNotification = ({ message, type }) => {
    this.notificationSystem.addNotification({
      uid: message,
      title: type.capitalize(),
      dismissible: true,
      autoDismiss: 5,
      position: 'tr',
      message,
      level: type,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notifyValue } = nextProps;
    this.addNotification(notifyValue);
  }

  render() {
    return (
      <div>
        <NotificationSystem
          ref={(refNotification) => {
            this.notificationSystem = refNotification;
          }}
        />
      </div>
    );
  }
}

export default withNotify(Notify);
