import React, { Component } from 'react';

class viewSystemLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_role: '',
      created_date: '',
      rowData: {}
    };
  }

  _resetForm = () => {
    document.getElementById('modelViewSystemLog').click();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0) {
      this.setState({ rowData: data.requested_data });
      this.setState({ user_role: data.user_role });
      this.setState({ created_date: data.created_date.split('T')[0] });
    }
  }

  render() {
    const { user_role, rowData, created_date } = this.state;
    const jsonPretty = JSON.stringify(rowData, null, 4);
    return (
      <div
        className="modal fade" id="modelViewSystemLog"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            {/* <div className="modal-header bg-light">
              <h5 className="modal-title pull-left" id="formModal">View Details</h5>
              <button type="button" className="close"
               onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {user_role
                    && (
                      <>
                        <div className="row">
                          <strong className="col-3"> USER ROLE: </strong>
                          <span className="col-9">
                            {user_role}
                          </span>
                        </div>
                        <hr />
                      </>
                    )}
                  <div className="row">
                    <strong className="col-3"> ACTION DATE: </strong>
                    <span className="col-9">
                      {created_date}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <pre>{JSON.stringify(rowData)}</pre>
                  </div>
                  {rowData
                    && Object.keys(rowData).map((key, i) => (
                      <>
                        <div key={i} className="row">
                          <strong className="col-3">
                            {key.toUpperCase()}
                            {': '}
                          </strong>
                          <span className="col-9">
                            {
                              (typeof (rowData[key]) === 'object')
                                ? (Object.keys(rowData[key]).map((keyItem, j) => (
                                  <>
                                    <div key={j} className="row bg-secondary">
                                      <strong className="col-5">
                                        {keyItem.toUpperCase()}
                                        {': '}
                                      </strong>
                                      <span className="col-7">
                                        {JSON.stringify(rowData[key][keyItem])}
                                      </span>
                                    </div>
                                  </>
                                ))

                                )
                                : (
                                  <>
                                    {(rowData[key]) || '-'}
                                  </>
                                )
                            }
                          </span>
                        </div>
                        <hr />
                      </>
                    ))}
                </div>
              </div>
            </div> */}
            <div className="modal-header bg-light">
              <h4 className="modal-title pull-left">Log Details </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this._resetForm()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="row">
                <div className="col-md-12">
                  {user_role
                    && (
                      <>
                        <div className="row">
                          <strong className="col-3"> USER ROLE: </strong>
                          <span className="col-9">
                            {user_role}
                          </span>
                        </div>
                        <hr />
                      </>
                    )}
                  <div className="row">
                    <strong className="col-3"> ACTION DATE: </strong>
                    <span className="col-9">
                      {created_date}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>
              <div className="table-responsive">
                <pre>{jsonPretty}</pre>
              </div>
            </div>
            <div className="modal-footer br">
              <button
                type="button" className="btn btn-dark"
                onClick={() => this._resetForm()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default viewSystemLog;
