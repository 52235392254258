import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import OrderListSearchSection from '../../components/Orders/OrderListSearchSection';
import OrderListSection from '../../components/Orders/OrderListSection';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      searchParams: {},
      page: 0,
      countOrderList: 0,
      customerName: null,
      productName: null,
      orderStatus: null,
      withStatus: false,
      uniqueStatus: []
    };
  }

  _orderList = (searchParams) => {
    const { setLoader, notify, params: { productId, customerId, orderStatus } } = this.props;
    setLoader(true);
    if (!productId && !customerId && !orderStatus) {
      API.orderList(searchParams).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ order: data });
          const uStatus = [...new Set(data.map((item) => item.status))];
          this.setState({ uniqueStatus: uStatus });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (productId) {
      API.orderList({ productId, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ order: data });
          const uStatus = [...new Set(data.map((item) => item.status))];
          this.setState({ uniqueStatus: uStatus });
          Object.keys(data).map((key) => {
            this.setState({ productName: data[key].productName });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (orderStatus) {
      API.orderList({ orderStatus, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ order: data });
          Object.keys(data).map((key) => {
            this.setState({ orderStatus: data[key].status });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.orderList({ customerId, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ order: data });
          const uStatus = [...new Set(data.map((item) => item.status))];
          this.setState({ uniqueStatus: uStatus });
          Object.keys(data).map((key) => {
            this.setState({ customerName: data[key].customer_name });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  }

  _countOrderList = (searchData) => {
    const { setLoader, notify, params: { productId, customerId, orderStatus } } = this.props;
    setLoader(true);
    if (!productId && !customerId && !orderStatus) {
      API.orderList({ count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countOrderList: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (productId) {
      API.orderList({ productId, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countOrderList: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (orderStatus) {
      API.orderList({ orderStatus, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countOrderList: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.orderList({ customerId, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countOrderList: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._orderList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._orderList(searchParams);
    this._countOrderList(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._orderList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      this._orderList({});
      this._countOrderList({});
    }
    if (params.hasOwnProperty('orderStatus')) {
      this.setState({ withStatus: true });
    }
  }

  render() {
    const {
      order, countOrderList, page, productName, customerName, withStatus, orderStatus,
      uniqueStatus
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <OrderListSearchSection
              onSearch={this._onSearch}
              uniqueStatus={uniqueStatus}
              withStatus={withStatus}
            />
            <OrderListSection
              data={order}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countOrderList}
              customerName={customerName}
              productName={productName}
              orderStatus={orderStatus}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(OrderList)));
