import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import withNotify from '../common/withNotify';
import withLoader from '../common/withLoader';
import withUser from '../common/withUser';
import withMenu from '../common/withMenu';
import { userLogout } from '../common/globals';
import LogoutBox from './Models/LogoutBox';

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLogoutConfirmOpen: false
    };
  }

  _onPressContinue = () => {
    const { isLogoutConfirmOpen } = this.state;
    this.setState({ isLogoutConfirmOpen: !isLogoutConfirmOpen });
  }

  render() {
    const { user, menu } = this.props;
    const { isLogoutConfirmOpen } = this.state;
    return (
      !isEmpty(user) ? (
        <>
          <div className="navbar-bg" />
          <nav className="navbar navbar-expand-lg main-navbar sticky">
            <div className="form-inline mr-auto">
              <ul className="navbar-nav mr-3">
                <li>
                  <Link data-toggle="sidebar" className="nav-link nav-link-lg collapse-btn">
                    <i data-feather="align-justify" />
                  </Link>
                </li>
                <li>
                  <Link className="nav-link nav-link-lg fullscreen-btn">
                    <i data-feather="maximize" />
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav navbar-right">
              <li className="dropdown dropdown-list-toggle">
                <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                  <div className="dropdown-header">
                    Messages
                    <div className="float-right">
                      <Link>Mark All As Read</Link>
                    </div>
                  </div>
                  <div className="dropdown-list-content dropdown-list-message">
                    <Link className="dropdown-item">
                      <span className="dropdown-item-avatar text-white">
                        <img alt="image" src="/assets/images/no-profile.jpg" className="rounded-circle" />
                      </span>
                      <span className="dropdown-item-desc">
                        <span className="message-user">John Deo</span>
                        <span className="time messege-text">Please check your mail !!</span>
                        <span className="time">2 Min Ago</span>
                      </span>
                    </Link>
                    {' '}
                    <Link className="dropdown-item">
                      {' '}
                      <span
                        className="dropdown-item-avatar text-white"
                      >
                        <img alt="image" src="/assets/images/no-profile.jpg" className="rounded-circle" />
                      </span>
                      {' '}
                      <span className="dropdown-item-desc">
                        {' '}
                        <span className="message-user">
                          Sarah
                          Smith
                        </span>
                        {' '}
                        <span className="time messege-text">
                          Request for leave
                          application
                        </span>
                        <span className="time">5 Min Ago</span>
                      </span>
                    </Link>
                    {' '}
                    <Link className="dropdown-item">
                      {' '}
                      <span
                        className="dropdown-item-avatar text-white"
                      >
                        <img alt="image" src="/assets/images/no-profile.jpg" className="rounded-circle" />
                      </span>
                      {' '}
                      <span className="dropdown-item-desc">
                        {' '}
                        <span className="message-user">
                          Jacob
                          Ryan
                        </span>
                        {' '}
                        <span className="time messege-text">
                          Your payment invoice is
                          generated.
                        </span>
                        {' '}
                        <span className="time">12 Min Ago</span>
                      </span>
                    </Link>
                    {' '}
                    <Link className="dropdown-item">
                      {' '}
                      <span
                        className="dropdown-item-avatar text-white"
                      >
                        <img alt="image" src="/assets/images/no-profile.jpg" className="rounded-circle" />
                      </span>
                      {' '}
                      <span className="dropdown-item-desc">
                        {' '}
                        <span className="message-user">
                          Lina
                          Smith
                        </span>
                        {' '}
                        <span className="time messege-text">
                          hii John, I have upload
                          doc
                          related to task.
                        </span>
                        {' '}
                        <span className="time">
                          30
                          Min Ago
                        </span>
                      </span>
                    </Link>
                    {' '}
                    <Link className="dropdown-item">
                      {' '}
                      <span
                        className="dropdown-item-avatar text-white"
                      >
                        <img alt="image" src="/assets/images/no-profile.jpg" className="rounded-circle" />
                      </span>
                      {' '}
                      <span className="dropdown-item-desc">
                        {' '}
                        <span className="message-user">
                          Jalpa
                          Joshi
                        </span>
                        {' '}
                        <span className="time messege-text">
                          Please do as specify.
                          Let me
                          know if you have any query.
                        </span>
                        {' '}
                        <span className="time">
                          1
                          Days Ago
                        </span>
                      </span>
                    </Link>
                    {' '}
                    <Link className="dropdown-item">
                      {' '}
                      <span
                        className="dropdown-item-avatar text-white"
                      >
                        <img alt="image" src="/assets/images/no-profile.jpg" className="rounded-circle" />
                      </span>
                      {' '}
                      <span className="dropdown-item-desc">
                        {' '}
                        <span className="message-user">
                          Sarah
                          Smith
                        </span>
                        {' '}
                        <span className="time messege-text">Client Requirements</span>
                        <span className="time">2 Days Ago</span>
                      </span>
                    </Link>
                  </div>
                  <div className="dropdown-footer text-center">
                    <Link>
                      View All
                      <i className="fas fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </li>
              <li className="dropdown">
                <Link
                  data-toggle="dropdown"
                  className="nav-link dropdown-toggle nav-link-lg nav-link-user"
                >
                  <img alt="image" src="/assets/images/no-profile.jpg" className="user-img-radious-style" />
                  <span className="d-sm-none d-lg-inline-block" />
                </Link>
                <div className="dropdown-menu dropdown-menu-right pullDown">
                  <div className="dropdown-title">
                    Hello&nbsp;&nbsp;
                    {user.username}
                  </div>
                  <Link to="/change-password" className="dropdown-item has-icon">
                    <i className="far fa-user" />
                    {' '}
                    Change Password
                  </Link>
                  <div className="dropdown-divider" />
                  <Link onClick={() => userLogout()} className="dropdown-item has-icon text-danger">
                    <i className="fas fa-sign-out-alt" />
                    {' '}
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
          <div className="main-sidebar sidebar-style-2">
            <aside id="sidebar-wrapper">
              <div className="sidebar-brand">
                <Link to="/">
                  <img alt="image" src="/assets/images/login.png" className="header-logo" />
                </Link>
              </div>
              <div className="sidebar-user">
                <div className="sidebar-user-picture">
                  <img
                    alt="image" src="/assets/images/no-profile.jpg"
                    onError={() => {
                      this.onerror = null;
                      this.src = '/assets/images/no-profile.jpg';
                    }}
                  />
                </div>
                <div className="sidebar-user-details">
                  <div className="user-name">
                    {user.first_name}
                    &nbsp;
                    {user.last_name}
                  </div>
                </div>
              </div>
              <ul className="sidebar-menu">
                <li className={classNames({ active: menu.length > 0 && menu[0] === 1 })}>
                  <Link className="nav-link" to="/">
                    <i data-feather="monitor" />
                    {' '}
                    <span>Dashboard</span>
                  </Link>
                </li>
                {/* <li className={
                  classNames({ active: menu.length > 0 && menu[0] === 2, dropdown: true })
                }
                >
                  <Link className="menu-toggle nav-link has-dropdown">
                    <i data-feather="users" />
                    <span>Customers</span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 2
                     && menu[1] === 1 })}>
                      <Link className="nav-link" to="/customers">Customers List</Link>
                    </li>
                  </ul>
                </li> */}
                <li className={
                  classNames({ active: menu.length > 0 && menu[0] === 3, dropdown: true })
                }
                >
                  <Link className="menu-toggle nav-link has-dropdown">
                    <i data-feather="book-open" />
                    <span>Products</span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 3 && menu[1] === 1 })}>
                      <Link className="nav-link" to="/products">Product List</Link>
                    </li>
                  </ul>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 3 && menu[1] === 2 })}>
                      <Link className="nav-link" to="/products/review-rating">Review Rating</Link>
                    </li>
                  </ul>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 3 && menu[1] === 3 })}>
                      <Link className="nav-link" to="/products/notifyMe/all">Notify Me</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 3 && menu[1] === 4 })}>
                      <Link className="nav-link" to="/products/wishlist">Wishlist</Link>
                    </li>
                  </ul>
                  {/* <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length >
                     0 && menu[0] === 3 && menu[1] === 5 })}>
                      <Link className="nav-link" to="/products/recently-view">Recently View</Link>
                    </li>
                  </ul> */}
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 3 && menu[1] === 7 })}>
                      <Link className="nav-link" to="/products/best-seller">Best Seller</Link>
                    </li>
                  </ul>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 3 && menu[1] === 8 })}>
                      <Link className="nav-link" to="/products/top-trending">Top Trending</Link>
                    </li>
                  </ul>
                </li>
                {/* <li className={
                  classNames({ active: menu.length > 0 && menu[0] === 4, dropdown: true })
                }
                >
                  <Link className="menu-toggle nav-link has-dropdown">
                    <i data-feather="list" />
                    <span>Orders</span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 4
                     && menu[1] === 1 })}>
                      <Link className="nav-link" to="/orders">Order List</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className={classNames({
                  active: menu.length > 0 && menu[0] === 5, dropdown: true
                })}
                >
                  <Link className="menu-toggle nav-link has-dropdown">
                    <i data-feather="tool" />
                    <span>Master Section</span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 &&
                     menu[0] === 5 && menu[1] === 1 })}>
                      <Link className="nav-link" to="/master-section/country">Country</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 &&
                     menu[0] === 5 && menu[1] === 2 })}>
                      <Link className="nav-link" to="/master-section/state">State</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 &&
                     menu[0] === 5 && menu[1] === 3 })}>
                      <Link className="nav-link" to="/master-section/category">Category</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 &&
                     menu[0] === 5 && menu[1] === 4 })}>
                      <Link className="nav-link" to="/master-section/manage-supplier-delivery-time">
                      Manage Supplier</Link>
                    </li>
                  </ul>
                </li> */}
                <li className={classNames({
                  active: menu.length > 0 && menu[0] === 6, dropdown: true
                })}
                >
                  <Link className="menu-toggle nav-link has-dropdown">
                    <i data-feather="settings" />
                    <span>Site Settings</span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 1 })}>
                      <Link className="nav-link" to="/site-settings/home-banner">Home Banner</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 1 })}>
                      <Link className="nav-link" to="/site-settings/new-home-banner">New Home Banner</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 2 })}>
                      <Link className="nav-link" to="/site-settings/email-template">Email Template</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 7 })}>
                      <Link className="nav-link" to="/site-settings/contact-us">Contact Us</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 3 })}>
                      <Link className="nav-link" to="/site-settings/general-setting">General Setting</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 4 })}>
                      <Link className="nav-link" to="/site-settings/cms-pages">CMS Pages</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 9 })}>
                      <Link className="nav-link" to="/site-settings/manage-seo">Manage SEO</Link>
                    </li>
                    {/* <li
                    className={
                      classNames({ 'active-sub': menu.length > 0
                      && menu[0] === 6 && menu[1] === 5 })}
                    >
                      <Link
                      className="nav-link" to="/site-settings/shipping-charge">
                      Shipping Charge
                      </Link>
                    </li> */}
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 6 })}>
                      <Link className="nav-link" to="/site-settings/discount-code">Discount Code</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 8 })}>
                      <Link className="nav-link" to="/site-settings/notification">Notification</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 10 })}>
                      <Link className="nav-link" to="/site-settings/system-log">System Log</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 11 })}>
                      <Link className="nav-link" to="/site-settings/visitor-log">Visitor Log</Link>
                    </li>
                    <li className={classNames({ 'active-sub': menu.length > 0 && menu[0] === 6 && menu[1] === 6 })}>
                      <Link className="nav-link" to="/site-settings/maintenance">Maintenance</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </aside>
          </div>
          <div className="settingSidebar">
            <Link className="settingPanelToggle">
              <i className="fa fa-spin fa-cog" />
            </Link>
            <div className="settingSidebar-body ps-container ps-theme-default">
              <div className=" fade show active">
                <div className="setting-panel-header">Setting Panel</div>
                <div className="p-15 border-bottom">
                  <h6 className="font-medium m-b-10">Select Layout</h6>
                  <div className="selectgroup layout-color w-50">
                    <label className="selectgroup-item">
                      <input
                        type="radio"
                        name="value"
                        value="1"
                        className="selectgroup-input-radio select-layout"
                      />
                      <span className="selectgroup-button">Light</span>
                    </label>
                    <label className="selectgroup-item">
                      <input
                        type="radio"
                        name="value"
                        value="2"
                        className="selectgroup-input-radio select-layout"
                      />
                      <span className="selectgroup-button">Dark</span>
                    </label>
                  </div>
                </div>
                <div className="p-15 border-bottom">
                  <h6 className="font-medium m-b-10">Sidebar Color</h6>
                  <div className="selectgroup selectgroup-pills sidebar-color">
                    <label className="selectgroup-item">
                      <input
                        type="radio"
                        name="icon-input"
                        value="1"
                        className="selectgroup-input select-sidebar"
                        aria-label="Light Sidebar"
                      />
                      <span
                        className="selectgroup-button selectgroup-button-icon"
                        data-toggle="tooltip"
                        data-original-title="Light Sidebar"
                      >
                        <i className="fas fa-sun" aria-hidden="true" />
                      </span>
                    </label>
                    <label className="selectgroup-item">
                      <input
                        type="radio"
                        name="icon-input"
                        value="2"
                        className="selectgroup-input select-sidebar"
                        aria-label="Dark Sidebar"
                      />
                      <span
                        className="selectgroup-button selectgroup-button-icon"
                        data-toggle="tooltip"
                        data-original-title="Dark Sidebar"
                      >
                        <i className="fas fa-moon" aria-hidden="true" />
                      </span>
                    </label>
                  </div>
                </div>
                <div className="p-15 border-bottom">
                  <h6 className="font-medium m-b-10">Color Theme</h6>
                  <div className="theme-setting-options">
                    <ul className="choose-theme list-unstyled mb-0">
                      <li title="white" className="active">
                        <div className="white" />
                      </li>
                      <li title="cyan">
                        <div className="cyan" />
                      </li>
                      <li title="black">
                        <div className="black" />
                      </li>
                      <li title="purple">
                        <div className="purple" />
                      </li>
                      <li title="orange">
                        <div className="orange" />
                      </li>
                      <li title="green">
                        <div className="green" />
                      </li>
                      <li title="red">
                        <div className="red" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="p-15 border-bottom">
                  <div className="theme-setting-options">
                    <label className="m-b-0">
                      <input
                        type="checkbox"
                        name="custom-switch-checkbox"
                        className="custom-switch-input"
                        id="mini_sidebar_setting"
                      />
                      <span className="custom-switch-indicator" />
                      <span className="control-label p-l-10">Mini Sidebar</span>
                    </label>
                  </div>
                </div>
                <div className="p-15 border-bottom">
                  <div className="theme-setting-options">
                    <label className="m-b-0">
                      <input
                        type="checkbox"
                        name="custom-switch-checkbox"
                        className="custom-switch-input"
                        id="sticky_header_setting"
                      />
                      <span className="custom-switch-indicator" />
                      <span className="control-label p-l-10">Sticky Header</span>
                    </label>
                  </div>
                </div>
                <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                  <Link className="btn btn-icon icon-left btn-primary btn-restore-theme">
                    <i className="fas fa-undo" />
                    {' '}
                    Restore Default
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <LogoutBox
            isOpen={isLogoutConfirmOpen}
            title="Logout"
            text="Do you want to stay or logout ?"
            onConfirm={() => userLogout()}
            onCancel={this._onPressContinue}
          />
        </>
      ) : <> </>
    );
  }
}

export default withUser(withLoader(withNotify(withMenu(Header))));
