import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import UserListSection from '../../components/Customers/CustomerListSection';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import UserMaterListSearchSection from '../../components/Customers/CustomerListSearchSection';
import API from '../../utils/utils';

class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      searchParams: {},
      page: 0,
      countUsers: 0,
    };
  }

  _customerList = (searchParams) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.customerList(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ customers: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _countCustomer = (searchData) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.customerList({ count: 1, ...searchData }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countUsers: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteCustomer({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        this._customerList({});
        this._countCustomer({});
        notify({ type: 'success', message });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onActiveDeactive = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.activeDeactiveCustomer({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._customerList({});
        this._countCustomer({});
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._customerList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._customerList(searchParams);
    this._countCustomer(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._customerList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._customerList({});
      this._countCustomer({});
    }
  }

  render() {
    const { customers, countUsers, page } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <UserMaterListSearchSection onSearch={this._onSearch} />
            <UserListSection
              data={customers}
              onDelete={this._onDelete}
              onActiveDeactive={this._onActiveDeactive}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countUsers}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(CustomerList)));
