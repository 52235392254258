import React, { Component } from 'react';
import {
  change, reset, Field, reduxForm
} from 'redux-form';
import { browserHistory } from 'react-router';
import Select2 from 'react-select2-wrapper';
import { isEmpty } from 'lodash';
import { number, renderField, required } from '../../helpers/form-validations';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';

class TaxAddEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      selectedCountry: null,
      selectedTax: null,
      countries: [],
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('shippingChargeAddEditForm'));
  };

  _getTaxById = () => {
    const {
      setLoader,
      notify,
      params: { id },
      dispatch,
    } = this.props;
    setLoader(true);
    API.getTaxById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({
          selectedCountry: data.country_id,
          selectedTax: data.name,
        });
        Object.keys(data).map((key) => {
          dispatch(change('shippingChargeAddEditForm', key, data[key]));
        });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSave = (formData) => {
    const {
      setLoader,
      notify,
      params,
      params: { id = null },
    } = this.props;
    const { selectedCountry, selectedTax } = this.state;
    if (selectedCountry === null) {
      notify({ type: 'error', message: 'Please Select Country.' });
    } else if (selectedTax === null) {
      notify({ type: 'error', message: 'Please Select Shipping Charge.' });
    } else {
      const data = {
        name: selectedTax,
        display_order: formData.display_order,
        country_id: selectedCountry,
        percentage: formData.percentage,
        delivery_charge: formData.delivery_charge,
      };
      if (id != null) {
        data.id = params.id;
        setLoader(true);
        API.updateTax(data).then((responseJson) => {
          setLoader(false);
          const { status, message } = responseJson;
          if (status) {
            notify({ type: 'success', message });
            browserHistory.push('/site-settings/shipping-charge');
            this._clearForm();
          } else {
            notify({ type: 'error', message });
          }
        });
      } else {
        setLoader(true);
        API.addTax(data).then((responseJson) => {
          setLoader(false);
          const { status, message } = responseJson;
          if (status) {
            notify({ type: 'success', message });
            browserHistory.push('/site-settings/shipping-charge');
            this._clearForm();
          } else {
            notify({ type: 'error', message });
          }
        });
      }
    }
  };

  _onChange = ({ target: { value } }) => {
    this.setState({ gender: value });
  };

  _onPressCancel = () => {
    browserHistory.push('/site-settings/shipping-charge');
    this._clearForm();
  };

  _getCountries = () => {
    const { notify } = this.props;
    API.getAllCountries().then((responseJson) => {
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countries: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    this._getCountries();
    if (!isEmpty(user)) {
      if (params.hasOwnProperty('id')) {
        this.setState({ isEdit: true });
        this._getTaxById();
      }
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isEdit, selectedCountry, countries, selectedTax
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>{isEdit ? 'Update Shipping Charge' : 'Create Shipping Charge'}</h4>
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(this._onSave)}
                      name="shippingChargeAddEditForm"
                    >
                      <div className="row">
                        <div className="col-md-8">
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="name"
                              className="col-sm-3 col-form-label"
                            >
                              Shipping Charge
                            </label>
                            <div className="col-sm-9">
                              <Select2
                                style={{ width: '100%' }}
                                className="form-control"
                                data={['VAT']}
                                options={{ placeholder: 'Select Charge' }}
                                value={selectedTax}
                                onSelect={({ target: { value } }) => {
                                  this.setState({ selectedTax: value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="country"
                              className="col-sm-3 col-form-label"
                            >
                              Country
                            </label>
                            <div className="col-sm-9">
                              <Select2
                                style={{ width: '100%' }}
                                className="form-control"
                                data={countries.map(({ name, _id }) => ({
                                  id: _id,
                                  text: name,
                                }))}
                                options={{ placeholder: 'Select Country' }}
                                value={selectedCountry}
                                onSelect={({ target: { value } }) => {
                                  this.setState({ selectedCountry: value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="delivery_charge"
                              className="col-sm-3 col-form-label"
                            >
                              Charge(€)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="delivery_charge"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Delivery Charge"
                                validate={[required, number]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">
                              {isEdit ? 'Update' : 'Save'}
                            </button>
                            {' '}
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => this._onPressCancel()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'shippingChargeAddEditForm',
  destroyOnUnmount: false,
});
export default withForm(withLoader(withNotify(withUser(TaxAddEditForm))));
