import React, { PureComponent } from 'react';
import Indicator from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import Notify from './components/Notify';
import { sentryConnect } from './utils/sentry';

class App extends PureComponent {
  UNSAFE_componentWillMount() {
    sentryConnect();
    window.resetCustomJs();
    this.props.router.listen(() => {
      window.resetCustomJs();
    });
  }

  render() {
    const { component } = this.props;
    return (
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <Notify />
          <Header />
          <Indicator />
          {component}
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
