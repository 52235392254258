import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import withNotify from '../../common/withNotify';
import { renderField, required } from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class AddEditNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditNotification'));
    }
    document.getElementById('modalAddEditNotification').click();
  };

  _onAddState = async (formData) => {
    const { addEditNotification, notify } = this.props;
    if (Object.keys(formData).length === 0) {
      return notify({ type: 'error', message: 'Notification content is required.' });
    }
    const data = {
      content_en: formData.content_en,
      content_de: formData.content_de,
      content_du: formData.content_du,
    };
    addEditNotification(data);
    this._resetForm();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0) {
      dispatch(change('addEditNotification', 'content_en', data.content_en));
      dispatch(change('addEditNotification', 'content_de', data.content_de));
      dispatch(change('addEditNotification', 'content_du', data.content_du));
    }
    // if (Object.keys(data).length === 0) {
    //   dispatch(change('addEditNotification', 'content', ''));
    // }
  }

  render() {
    const { handleSubmit, data } = this.props;
    return (
      <div
        className="modal fade" id="modalAddEditNotification"
        role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">{`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} Notification`}</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addEditNotification" onSubmit={handleSubmit(this._onAddState)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">Content (en): </label>
                      <div className="col-sm-9">
                        <Field
                          type="textarea"
                          name="content_en"
                          component={renderField}
                          className="form-control"
                          placeholder="Enter content(en)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">Content (de): </label>
                      <div className="col-sm-9">
                        <Field
                          type="textarea"
                          name="content_de"
                          component={renderField}
                          className="form-control"
                          placeholder="Enter content(de)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">Content (du): </label>
                      <div className="col-sm-9">
                        <Field
                          type="textarea"
                          name="content_du"
                          component={renderField}
                          className="form-control"
                          placeholder="Enter content(du)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">{Object.keys(data).length > 0 ? 'Update' : 'Save'}</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditNotification'
});
export default withLoader(withForm(withNotify(AddEditNotification)));
