import React, { useReducer, useState } from 'react';
import { browserHistory } from 'react-router';
import Select2 from 'react-select2-wrapper';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';

const OrderListSearchSection = (props) => {
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const initialState = {
    focusedInput: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'focusChange':
        return { ...state, focusedInput: action.payload };
      case 'dateChange':
        return action.payload;
      case 'reset':
        return action.payload;
      default:
        throw new Error();
    }
  };

  const _onSearch = () => {
    setSearch(search);
    setSelectedStatus(selectedStatus);
    const { onSearch } = props;
    onSearch({
      search, selectedStatus
    });
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch(search);
      const { onSearch } = props;
      onSearch({
        search
      });
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const _onReset = () => {
    const { onSearch } = props;
    setSearch('');
    setSelectedStatus('');
    onSearch({ search: '', selectedStatus: '' });
    dispatch({ type: 'reset', payload: initialState });
  };

  const _onPressCancel = () => {
    window.history.back();
  };

  const _onShowAll = () => {
    browserHistory.push('/orders');
  };

  const tempArr = [];
  props.uniqueStatus.map((data) => {
    if (data === 0) {
      tempArr.push({ _id: 0, name: 'Draft' });
    }
    if (data === 1) {
      tempArr.push({ _id: 1, name: 'Pending' });
    }
    if (data === 2) {
      tempArr.push({ _id: 2, name: 'Processing' });
    }
    if (data === 3) {
      tempArr.push({ _id: 3, name: 'Partial Updated' });
    }
    if (data === 4) {
      tempArr.push({ _id: 4, name: 'Shipment' });
    }
    if (data === 5) {
      tempArr.push({ _id: 5, name: 'Completed' });
    }
    if (data === 6) {
      tempArr.push({ _id: 6, name: 'Cancelled' });
    }
  });

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4>Customers Orders</h4>
            <div className="card-header-action home-banner-button">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => _onPressCancel()}
              >
                Back
              </button>
            </div>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Search</label>
                    <input
                      type="text"
                      placeholder="Search keyword"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      value={state.search}
                      onKeyDown={(e) => handleKeypress(e)}
                    />
                  </div>
                </div>
                {(!props.withStatus) && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="search">Select Status</label>
                      <Select2
                        style={{ width: '100%' }}
                        className="form-control"
                        data={
                          tempArr.map(({ name, _id }) => ({ id: _id, text: name }))
                        }
                        options={{ placeholder: 'Select Status' }}
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-3">
                  <div className="form-group button-center">
                    <div className="">
                      <button className="btn btn-primary mr-1" type="button" onClick={_onSearch}>Search</button>
                      <button className="btn btn-primary mr-1" type="reset" onClick={_onReset}>
                        Reset
                      </button>
                      {(props.withStatus) && (
                        <button
                          type="button"
                          className="btn btn-primary mr01"
                          onClick={() => _onShowAll()}
                        >
                          Show All
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNotify(withLoader(OrderListSearchSection));
