// export const BASE_URL = 'https://mbmsb-api.ctasis.in';
// export const BASE_URL = 'https://mbmsb-api-staging.mbordermanagement.com';
export const BASE_URL = 'https://rest.myboeken.nl';
export const API_BASE_URL = `${BASE_URL}/api`;
export const IMAGE_BASE_URL = `${BASE_URL}/assets/uploads/`;
export const GOOGLE_CATPCHA_KEY = '6LeVPI4bAAAAAPOJM0Bw4c_K8BPQ1Eu2VeYliSGA';
export const TINY_CLOUD_API_KEY = 'lui79vz5eieu9nmh0nnk6q8imf1c3trwxjmdrz7hzkoql2gd';
// export const MB_FRONT_URL = 'http://mb-staging.ctasis.in/';
// export const MB_FRONT_URL = 'https://mbmsb-staging.mbordermanagement.com/';
export const MB_FRONT_URL = 'https://myboeken.nl/';
export const CDN_IMAGE_URL = 'https://myboeken-images.s3.eu-central-1.amazonaws.com/';
export const CDN_BANNER_IMAGE_URL = 'https://myboeken-images.s3.eu-central-1.amazonaws.com/banner-images/';
