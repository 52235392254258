import React, { Component } from 'react';
import moment from 'moment';
import { browserHistory } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';

class OrderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      products: [],
    };
  }

  _getOrderById = () => {
    const {
      setLoader,
      notify,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getOrderById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message, order } = responseJson;
      if (status) {
        this.setState({ order });
        this.setState({ products: order.product_ids });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onPressCancel = () => {
    browserHistory.push('/orders');
  };

  UNSAFE_componentWillMount() {
    this._getOrderById();
  }

  render() {
    const { order, products } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Order Details</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h5>{`${order.user_name}`}</h5>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <label
                          htmlFor="status"
                          className="col-sm-4 col-form-label font-weight-bold"
                        >
                          Status :
                        </label>

                        {order.status === 0 && (
                          <span
                            className="badge rounded-pill bg-secondary font-weight-bold p-1"
                            style={{ fontSize: '13px' }}
                          >
                            Draft
                          </span>
                        )}
                        {order.status === 1 && (
                          <span
                            className="badge rounded-pill bg-pending font-weight-bold text-light p-1"
                            style={{ fontSize: '13px' }}
                          >
                            Pending
                          </span>
                        )}
                        {order.status === 2 && (
                          <span
                            className="badge rounded-pill bg-running font-weight-bold p-1"
                            style={{ fontSize: '13px' }}
                          >
                            Processing
                          </span>
                        )}
                        {order.status === 3 && (
                          <span
                            className="badge rounded-pill bg-secondry font-weight-bold p-1 text-light"
                            style={{ fontSize: '13px' }}
                          >
                            Partial Updated
                          </span>
                        )}
                        {order.status === 4 && (
                          <span
                            className="badge rounded-pill bg-shipment font-weight-bold p-1"
                            style={{ fontSize: '13px' }}
                          >
                            Shipment
                          </span>
                        )}
                        {order.status === 5 && (
                          <span
                            className="badge rounded-pill bg-completed font-weight-bold p-1 text-light"
                            style={{ fontSize: '13px' }}
                          >
                            Completed
                          </span>
                        )}
                        {order.status === 6 && (
                          <span
                            className="badge rounded-pill bg-cancel font-weight-bold p-1 text-light"
                            style={{ fontSize: '13px' }}
                          >
                            Cancelled
                          </span>
                        )}
                      </div>
                      {order.order_date && (
                        <div className="col-md-6">
                          <label
                            htmlFor="order_date"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Order Date :
                          </label>
                          {order.order_date.slice(0, 10)}
                          {' '}
                          {order.order_date.slice(11, 19)}
                          {/* {moment(order.order_date).format('DD/MM/YYYY')} */}
                        </div>
                      )}
                      {order.order_number && (
                        <div className="col-md-6">
                          <label
                            htmlFor="order_number"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Order Number :
                          </label>
                          <span className="text-success font-weight-bold">
                            {order.order_number}
                          </span>
                        </div>
                      )}
                      <div className="col-md-6">
                        <label
                          htmlFor="is_guest_customer"
                          className="col-sm-4 col-form-label font-weight-bold"
                        >
                          Guest Customer ?
                        </label>
                        {order.is_guest_customer ? 'Yes' : 'No'}
                      </div>
                      {order.is_guest_customer && order.guest_customer_id && (
                        <div className="col-md-6">
                          <label
                            htmlFor="guest_customer_id"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Guest Customer ID :
                          </label>
                          {order.guest_customer_id}
                        </div>
                      )}
                      <div className="col-md-6">
                        <label
                          htmlFor="is_apply_discount"
                          className="col-sm-4 col-form-label font-weight-bold"
                        >
                          Applied Discount ?
                        </label>
                        {order.is_apply_discount ? 'Yes' : 'No'}
                      </div>
                      {order.is_apply_discount && order.discount_amount && (
                        <>
                          <div className="col-md-6">
                            <label
                              htmlFor="discount_amount"
                              className="col-sm-4 col-form-label font-weight-bold"
                            >
                              Discount Amount(€) :
                            </label>
                            <span className="text-danger">
                              {order.discount_amount}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="discount_code"
                              className="col-sm-4 col-form-label font-weight-bold"
                            >
                              Discount Code :
                            </label>
                            <span className="text-danger">
                              {order.discount_code}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="col-md-6">
                        <label
                          htmlFor="use_vat"
                          className="col-sm-4 col-form-label font-weight-bold"
                        >
                          Varified VAT ?
                        </label>
                        {order.use_vat ? 'Yes' : 'No'}
                      </div>
                      {order.use_vat && order.tax_amount && (
                        <div className="col-md-6">
                          <label
                            htmlFor="total_amount"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            VAT amount :
                          </label>
                          {order.tax_amount}
                        </div>
                      )}
                      {order.total_amount && (
                        <div className="col-md-6">
                          <label
                            htmlFor="total_amount"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Total amount(€) :
                          </label>
                          <span className="font-weight-bold text-danger">
                            {order.total_amount}
                          </span>
                        </div>
                      )}
                      <div className="col-md-6">
                        <label
                          htmlFor="is_paid"
                          className="col-sm-4 col-form-label font-weight-bold"
                        >
                          Is paid :
                        </label>
                        {order.is_paid ? 'Yes' : 'No'}
                      </div>
                      {order.paid_time && order.paid_amount && (
                        <div className="col-md-6">
                          <label
                            htmlFor="paid_amount"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Paid Amount(€):
                          </label>
                          {order.paid_amount}
                        </div>
                      )}
                      {order.paid_time && (
                        <div className="col-md-6">
                          <label
                            htmlFor="paid_time"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Paid Date :
                          </label>
                          {moment(order.paid_time).format('DD/MM/YYYY')}
                        </div>
                      )}
                      {order.payment_type && (
                        <div className="col-md-6">
                          <strong>
                            <label
                              htmlFor="payment_type"
                              className="col-sm-4 col-form-label font-weight-bold"
                            >
                              Payment Type :
                            </label>
                            {order.payment_type}
                          </strong>
                        </div>
                      )}
                      {order.ip_address && (
                        <div className="col-md-6">
                          <strong>
                            <label
                              htmlFor="ip_address"
                              className="col-sm-4 col-form-label font-weight-bold"
                            >
                              IP Address :
                            </label>
                            {order.ip_address}
                          </strong>
                        </div>
                      )}
                      {order.paided_amount && (
                        <div className="col-md-6">
                          <strong>
                            <label
                              htmlFor="paided_amount"
                              className="col-sm-4 col-form-label font-weight-bold"
                            >
                              Paided Amount(€):
                            </label>
                            {order.paided_amount}
                          </strong>
                        </div>
                      )}
                      {order.trackingNumber && (
                        <div className="col-md-6">
                          <label
                            htmlFor="trackingNumber"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Tracking Number:
                          </label>
                          {order.trackingNumber}
                        </div>
                      )}
                      {order.transporterCode && (
                        <div className="col-md-6">
                          <label
                            htmlFor="transporterCode"
                            className="col-sm-4 col-form-label font-weight-bold"
                          >
                            Transporter Code:
                          </label>
                          {order.transporterCode}
                        </div>
                      )}
                      {order.trackingURL && (
                        <div className="col-md-12">
                          <label
                            htmlFor="trackingURL"
                            className="col-sm-2 col-form-label font-weight-bold"
                          >
                            Tracking URL:
                          </label>
                          {order.trackingURL}
                        </div>
                      )}
                      <div className="col-md-12">
                        <label
                          htmlFor="trackingURL"
                          className="col-sm-2 col-form-label font-weight-bold"
                        >
                          Updated By:
                        </label>
                        <span className="font-weight-bold text-danger">
                          {order.modified_by === 2 ? 'Customer' : 'Warehouse'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Order-Address List</h4>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div className="border">
                            <div
                              className="p-3 text-center"
                              style={{ backgroundColor: '#f0f3ff' }}
                            >
                              <h6 className="text-center">Delivery Address</h6>
                            </div>
                            <div className="p-3">
                              <strong>{order.address_line_1}</strong>
                              {', '}
                              <br />
                              {order.address_line_2 && (
                                <>
                                  <strong>{order.address_line_2}</strong>
                                  {', '}
                                  <br />
                                </>
                              )}
                              <strong>{order.city}</strong>
                              {', '}
                              <br />
                              <strong>
                                {order.state}
                                {', '}
                                {order.country}
                                {'- '}
                                {order.postalCode}
                              </strong>
                              {' '}
                              <br />
                              <strong>
                                Phone Number :
                                {order.phoneNo}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="border">
                            <div
                              className="p-3 text-center"
                              style={{ backgroundColor: '#f0f3ff' }}
                            >
                              <h6 className="text-center">
                                Billing / Invoice Address:
                              </h6>
                            </div>
                            <div className="p-3">
                              <strong>{order.address_line_1_invoice}</strong>
                              {', '}
                              <br />
                              {order.address_line_2 && (
                                <>
                                  <strong>{order.address_line_2}</strong>
                                  {', '}
                                  <br />
                                </>
                              )}
                              <strong>{order.city_invoice}</strong>
                              {', '}
                              <br />
                              <strong>
                                {order.state_invoice}
                                {', '}
                                {order.country}
                                {'- '}
                                {order.postalCode_invoice}
                              </strong>
                              <br />
                              <strong>
                                Phone Number:
                                {' '}
                                {order.phoneNo_invoice}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th> Isbn13 </th>
                            <th> Title </th>
                            {/* <th> Author </th> */}
                            {/* <th> Publisher </th> */}
                            <th> Unit Price </th>
                            <th> Quantity </th>
                            <th> Status </th>
                            <th> Tracking Number </th>
                            <th> Transporter Code </th>
                            <th> Tracking URL </th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.length > 0
                            && products.map((item, key) => (
                              <tr key={key}>
                                <td>{item.isbn13 || '-'}</td>
                                <td>{item.title || '-'}</td>
                                {/* <td>{item.author || '-'}</td> */}
                                {/* <td>{item.publisher || '-'}</td> */}
                                <td>{item.unit_price || '-'}</td>
                                <td>{item.qty || '-'}</td>
                                <td>
                                  {item.orderItemStatus === 1 && (
                                    <i
                                      title="Pending"
                                      className="dot bg-pending cursor"
                                    />
                                  )}
                                  {item.orderItemStatus === 2 && (
                                    <i
                                      title="Processing"
                                      className="dot bg-running cursor"
                                    />
                                  )}
                                  {item.orderItemStatus === 4 && (
                                    <i
                                      title="Shipment"
                                      className="dot bg-shipment cursor"
                                    />
                                  )}
                                  {item.orderItemStatus === 5 && (
                                    <i
                                      title="Completed"
                                      className="dot bg-completed cursor"
                                    />
                                  )}
                                  {item.orderItemStatus === 6 && (
                                    <i
                                      title="Cancelled"
                                      className="dot bg-cancel cursor"
                                    />
                                  )}
                                </td>
                                <td>{item.trackingNumber || '-'}</td>
                                <td>{item.transporterCode || '-'}</td>
                                <td>{item.trackingURL || '-'}</td>
                              </tr>
                            ))}
                          {products.length === 0 && (
                            <tr>
                              <td colSpan={12}>
                                <center>
                                  <b>Records not available!</b>
                                </center>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(OrderView)));
