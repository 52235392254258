import React, { Component } from 'react';
import {
  Field, reduxForm, reset
} from 'redux-form';
import withNotify from '../../common/withNotify';
import { renderField, required } from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class AddProductVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video_link: '',
    };
  }

  _resetForm = () => {
    document.getElementById('modalAddProductVideo').click();
  };

  _onAddVideo = async (formData) => {
    const { addProductVideo, productId, dispatch } = this.props;
    // const { dispatch } = this.props;
    let src = formData.video_link.split('src="')[1].split(/[ >]/)[0];
    src = src.split('"');

    const data = {
      product_id: productId,
      video_link: src[0],
      status: 1,
    };
    addProductVideo(data);
    this._resetForm();
    dispatch(reset('addProductVideo'));
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div
        className="modal fade" id="modalAddProductVideo"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">Add Product Video</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addProductVideo" onSubmit={handleSubmit(this._onAddVideo)}>
              <div className="modal-body">
                <div className="form-group form-row">
                  <label htmlFor="video_link" className="col-sm-3 col-form-label">Video Link</label>
                  <div className="col-sm-9">
                    <div className="card-header-action">
                      <Field
                        type="text"
                        name="video_link"
                        component={renderField}
                        className="form-control"
                        placeholder="Enter product video url"
                        validate={[required]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">Save</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addProductVideo'
});
export default withLoader(withForm(withNotify(AddProductVideo)));
