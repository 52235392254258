import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import ProductListSection from '../../components/Products/ProductListSection';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import ProductListSearchSection from '../../components/Products/ProductListSearchSection';
import API from '../../utils/utils';

class ProductList extends Component {
  ttlcnt = 0;

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      searchParams: {},
      page: 0,
      countProducts: 0,
    };
  }

  _productList = (searchParams) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.productList(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        if (searchParams.search && searchParams.search !== '') {
          this._countProduct(searchParams);
        } else {
          this.setState({ countProducts: this.ttlcnt });
        }
        this.setState({ products: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _countProduct = (searchData) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.productList({ count: 1, ...searchData }).then(async (responseJson) => {
      const { status, message, data } = responseJson;
      setLoader(false);
      if (status) {
        if (searchData.search === undefined) {
          this.ttlcnt = data;
          this.setState({ countProducts: this.ttlcnt });
        } else {
          this.setState({ countProducts: data });
        }
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteProduct({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        this._productList({});
        // this._countProduct({});
        // if (countProducts > 1) {
        this.setState({ countProducts: this.ttlcnt - 1 });
        // }
        notify({ type: 'success', message });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._productList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._productList(searchParams);
    // this._countProduct(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._productList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._productList({});
      this._countProduct({});
    }
  }

  render() {
    const {
      products, countProducts, page, searchParams
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <ProductListSearchSection onSearch={this._onSearch} />
            <ProductListSection
              data={products}
              onDelete={this._onDelete}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={searchParams.search && searchParams.search !== '' ? 0 : countProducts}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(ProductList)));
