import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import { CDN_BANNER_IMAGE_URL } from '../../common/config';
import ConfirmBox from '../../components/Models/ConfirmBox';
import WarningBox from '../../components/Models/WarningBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';
import AddEditNewHomeBanner from '../../components/Models/AddEditNewHomeBanner';

class NewHomeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeBanners: [],
      isOpenAddEditSliderImage: false,
      selectedData: {},
      isDeleteConfirmOpen: false,
      selectedId: null,
      searchParams: {},
      isActiveOpen: false,
      activeId: null,
      page: 0,
      countUsers: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: '',
    };
  }

  _getNewHomeBanner = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getNewHomeBanner(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ homeBanners: data });
      }
    });
  };

  _countHomeBanner = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getNewHomeBanner({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countUsers: data });
      }
    });
  };

  _addEditNewSliderImage = (data) => {
    const { setLoader, notify } = this.props;
    const { selectedData, selectedId } = this.state;
    setLoader(true);
    if (Object.keys(selectedData).length > 0 && selectedId != null) {
      data.id = selectedId;
      API.updateNewHomeBanner(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getNewHomeBanner({});
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.addNewHomeBanner(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({
            type: 'success',
            message: 'Home banner added successfully.',
          });
          this._getNewHomeBanner({});
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onActiveDeactiveAlert = (id) => {
    this.setState({ isActiveOpen: true, activeId: id });
  };

  _onActiveDeactive = () => {
    const { activeId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.activeDeactiveNewHomeBanner({ id: activeId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getNewHomeBanner({});
        this.setState({ isActiveOpen: false, activeId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = () => {
    const { selectedId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteNewHomeBanner({ id: selectedId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getNewHomeBanner({});
        this._countHomeBanner({});
        this.setState({ isDeleteConfirmOpen: false, selectedId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onOpenAddEditModal = (selectedData, index = null) => {
    this.setState({
      selectedData,
      isOpenAddEditSliderImage: true,
      selectedId: index,
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getNewHomeBanner(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getNewHomeBanner(searchParams);
    this._countHomeBanner(searchParams);
  };

  handleKeypress = (e, searchData) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let { searchParams } = this.state;
      searchParams.page = 0;
      searchParams = { ...searchParams, ...searchData };
      this.setState({ searchParams, page: 0 });
      this._getNewHomeBanner(searchParams);
      this._countHomeBanner(searchParams);
    }
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== pageParam - 1) {
      this._getNewHomeBanner({ ...searchParams, page: pageParam - 1 });
    }
  };

  bannerTypeName = (type) => {
    if (type === 'home_banner') {
      return 'Home Banner';
    } if (type === 'full_banner') {
      return 'Full Banner';
    } if (type === 'sub_banner') {
      return 'Sub Banner';
    }
    return 'Special Banner';
  };

  UNSAFE_componentWillMount() {
    this._getNewHomeBanner({});
    this._countHomeBanner({});
  }

  render() {
    const {
      homeBanners,
      isOpenAddEditSliderImage,
      selectedData,
      isDeleteConfirmOpen,
      isActiveOpen,
      sortKey,
      sortOrder,
      search,
      countUsers,
      page,
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>New Home Banners</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                              onKeyDown={(e) => this.handleKeypress(e, { search })}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button
                                className="btn btn-primary mr-1"
                                type="button"
                                onClick={() => this._onSearch({ search })}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1"
                                type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>New Home Banners</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalNewAddEditSliderImage"
                        onClick={() => this._onOpenAddEditModal({})}
                      >
                        Add New Banner
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th
                              className={`sort ${
                                sortKey === 'title_de'
                                  ? `sort-by-${sortOrder}`
                                  : ''
                              } `}
                              onClick={this._onSort.bind(
                                this,
                                'title_de',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Title
                            </th>
                            <th
                              className={`sort ${
                                sortKey === 'link' ? `sort-by-${sortOrder}` : ''
                              } `}
                              onClick={this._onSort.bind(
                                this,
                                'link',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Click Url
                            </th>
                            <th
                              className={`sort ${
                                sortKey === 'banner_type'
                                  ? `sort-by-${sortOrder}`
                                  : ''
                              } `}
                              onClick={this._onSort.bind(
                                this,
                                'banner_type',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Banner Type
                            </th>
                            <th
                              className={`sort ${
                                sortKey === 'banner_sequence'
                                  ? `sort-by-${sortOrder}`
                                  : ''
                              } `}
                              onClick={this._onSort.bind(
                                this,
                                'banner_sequence',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Order By
                            </th>
                            <th>Is-Activated</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(homeBanners)
                            && homeBanners.map((item, key) => (
                              <tr key={key}>
                                <td>
                                  <img
                                    src={
                                      item.image_url === null
                                      || item.image_url === ''
                                        ? '/assets/images/no-image-icon.png'
                                        : CDN_BANNER_IMAGE_URL + item.image_url
                                    }
                                    onError={() => {
                                      this.onerror = null;
                                      this.src
                                        = '/assets/images/no-image-icon.png';
                                    }}
                                    style={{ height: 50, width: 50 }}
                                    alt="image"
                                  />
                                </td>
                                <td>{item.title_de || item.alt_text}</td>
                                <td>{item.link}</td>
                                <td>{this.bannerTypeName(item.banner_type)}</td>
                                <td>{item.banner_sequence}</td>
                                <td>
                                  <div className="custom-checkbox custom-control">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={key}
                                      checked={item.status === 1}
                                      onChange={() => this._onActiveDeactiveAlert(item._id)}
                                    />
                                    <label
                                      htmlFor={key}
                                      className="custom-control-label"
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <Link
                                    title="Edit"
                                    data-toggle="modal"
                                    data-target="#modalNewAddEditSliderImage"
                                    onClick={() => this._onOpenAddEditModal(
                                      {
                                        image_url: item.image_url,
                                        link: item.link,
                                        alt_text: item.alt_text,
                                        title_en: item.title_en,
                                        title_de: item.title_de,
                                        title_du: item.title_du,
                                        short_title_en: item.short_title_en,
                                        short_title_de: item.short_title_de,
                                        short_title_du: item.short_title_du,
                                        banner_sequence: item.banner_sequence,
                                        banner_type: item.banner_type,
                                      },
                                      item._id,
                                    )}
                                  >
                                    <i className="fas fa-pencil-alt" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                  <Link
                                    title="Delete"
                                    className="cursor"
                                    onClick={() => this._onDeleteAlert(item._id)}
                                  >
                                    <i className="far fa-trash-alt" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          {homeBanners.length === 0 && (
                            <tr>
                              <td colSpan={12}>
                                <center>
                                  <b>Records not available!</b>
                                </center>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countUsers}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Home Banner"
          text="Are you sure want to delete this home banner?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
        <WarningBox
          isOpen={isActiveOpen}
          title="Active/Deactive"
          text="Are you sure you want to continue ?"
          onConfirm={this._onActiveDeactive}
          onCancel={() => {
            this.setState({ isActiveOpen: false });
          }}
        />
        <AddEditNewHomeBanner
          isOpen={isOpenAddEditSliderImage}
          onCancel={() => {
            this.setState({ isOpenAddEditSliderImage: false });
          }}
          addEditSliderImage={this._addEditNewSliderImage}
          data={selectedData}
        />
      </div>
    );
  }
}
export default withLoader(withNotify(NewHomeBanner));
