import React from 'react';

const defaultProps = {
  initialPage: 1,
  pageSize: 50,
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  setPage = (page) => {
    const { items, pageSize } = this.props;
    let { pager } = this.state;

    /* if (page < 1 || page > pager.totalPages) {
      return;
    } */

    // get new pager object for specified page
    pager = this.getPager(items, page, pageSize);

    // update state
    this.setState({ pager });

    // call change page function in parent component
    this.props.onChangePage(pager.currentPage);
  };

  getPager = (totalItems, currentPage = 1, pageSize = 10) => {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage;
    let endPage;
    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i,
    );

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  };

  componentDidUpdate = (prevProps) => {
    // reset page if items array has changed
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  };

  UNSAFE_componentWillMount = () => {
    // set page if items array isn't empty
    if (this.props.items && this.props.items > 0) {
      this.setPage(this.props.initialPage);
    }
  };

  render() {
    const { pager } = this.state;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }
    return (
      <>
        <div className="mt-2 mr-3 float-right">
          {((pager.currentPage * pager.pageSize) - pager.pageSize) + 1}
          {' '}
          -
          {' '}
          {this.props.items < (pager.currentPage * pager.pageSize)
            ? (this.props.items)
            : (pager.currentPage * pager.pageSize)}
          {' '}
          of
          {' '}
          {pager.totalItems}
          {' '}
          Records
        </div>
        <div>
          <ul className="pagination flex-wrap">
            <li
              className={
                pager.currentPage === 1 ? 'disabled page-item' : 'page-item'
              }
            >
              <button
                type="button"
                className="page-link"
                onClick={() => this.setPage(1)}
              >
                First
              </button>
            </li>
            <li
              className={
                pager.currentPage === 1 ? 'disabled page-item' : 'page-item'
              }
            >
              <button
                type="button"
                className="page-link"
                onClick={() => this.setPage(pager.currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {pager.pages.map((page, index) => (
              <li
                key={index}
                className={
                  pager.currentPage === page ? 'page-item active' : 'page-item'
                }
              >
                <button
                  type="button"
                  className="page-link"
                  onClick={() => this.setPage(page)}
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={
                pager.currentPage === pager.totalPages
                  ? 'disabled page-item'
                  : 'page-item'
              }
            >
              <button
                type="button"
                className="page-link"
                onClick={() => this.setPage(pager.currentPage + 1)}
              >
                Next
              </button>
            </li>
            <li
              className={
                pager.currentPage === pager.totalPages
                  ? 'disabled page-item'
                  : 'page-item'
              }
            >
              <button
                type="button"
                className="page-link"
                onClick={() => this.setPage(pager.totalPages)}
              >
                Last
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

Pagination.defaultProps = defaultProps;
export default Pagination;
