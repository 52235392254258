import React, { Component } from 'react';
import {
  change, reset, reduxForm
} from 'redux-form';
import { browserHistory, Link } from 'react-router';
import Imgix from 'react-imgix';
import { CDN_IMAGE_URL, IMAGE_BASE_URL } from '../../common/config';
// import { number, renderField, required } from '../../helpers/form-validations';
import ConfirmBox from '../../components/Models/ConfirmBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import AddProductVideo from '../../components/Models/AddProductVideo';
import WarningBox from '../../components/Models/WarningBox';
import AddProductImage from '../../components/Models/AddProductImage';

class ProductEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      videos: [],
      bestSeller: {},
      topTrending: {},
      products: {},
      isDeleteConfirmOpen: false,
      isAddConfirmOpen: false,
      selectedId: null,
      selectedImageId: null,
      productId: null,
      isOpenAddProductVideo: false,
      isOpenAddProductImage: false,
      paramsId: null,
      bestSellerFlag: false,
      topTrendFlag: false,
      deleteImageFlag: false,
      imageExist: false,
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('productEditForm'));
  };

  _setImageExist = (imageExist) => {
    this.setState({ imageExist });
  }

  _getProductById = () => {
    const {
      setLoader,
      notify,
      params: { id },
      dispatch,
    } = this.props;
    setLoader(true);
    this.setState({ paramsId: id });
    API.getProductById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message, product } = responseJson;
      this.setState({ products: product });
      if (status) {
        Object.keys(product).map((key) => {
          dispatch(change('productEditForm', key, product[key]));
        });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _getProductImageById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getProductImgByProductId({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ images: data });
      } else {
        this.setState({ images: [] });
      }
    });
  };

  _getBestSellerById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getBestSellerById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ bestSeller: data });
      } else {
        this.setState({ bestSeller: {} });
      }
    });
  };

  _getTopTrendingById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getTopTrendingProductById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ topTrending: data });
      } else {
        this.setState({ topTrending: {} });
      }
    });
  };

  _getProductVideoById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getProductVideoByProductId({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ videos: data });
      } else {
        this.setState({ videos: [] });
      }
    });
  };

  _onSave = (formData) => {
    const {
      setLoader,
      notify,
      params,
      params: { id = null },
    } = this.props;
    const data = {
      stock: formData.stock,
      price: formData.price,
    };
    if (id != null) {
      data.id = params.id;
      setLoader(true);
      API.updateProduct(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/products');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  // Product Video

  _onOpenAddModal = (selectedData, index = null) => {
    this.setState({
      selectedData,
      isOpenAddProductVideo: true,
      selectedId: index,
    });
  };

  _addProductVideo = (data) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.addProductVideo(data).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getProductVideoById();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onDelete = () => {
    const { selectedId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteProductVideo({ id: selectedId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getProductVideoById();
        this.setState({ isDeleteConfirmOpen: false, selectedId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  // Product Images

  _onOpenAddImageModal = () => {
    this.setState({ isOpenAddProductImage: true });
  };

  _addProductImage = (data) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.addProductImage(data).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getProductImageById();
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDeleteImageAlert = (id) => {
    this.setState({
      isDeleteConfirmOpen: true,
      selectedImageId: id,
      deleteImageFlag: true,
    });
  };

  _onDeleteImage = () => {
    const { selectedImageId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteProductImage({ id: selectedImageId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getProductImageById();
        this.setState({
          isDeleteConfirmOpen: false,
          selectedImageId: null,
          deleteImageFlag: false,
        });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  // Best seller product

  _onBestSellerDeleteAlert = () => {
    const {
      params: { id },
    } = this.props;
    const { bestSeller } = this.state;
    if (bestSeller.length > 0) {
      this.setState({
        isDeleteConfirmOpen: true,
        productId: id,
        bestSellerFlag: true,
      });
    } else {
      this.setState({
        isAddConfirmOpen: true,
        productId: id,
        bestSellerFlag: true,
      });
    }
  };

  _onActiveBestSeller = () => {
    const { bestSeller } = this.state;
    const { setLoader, notify, params } = this.props;
    setLoader(true);
    if (bestSeller.length > 0) {
      API.deleteProductFromBestSeller({ id: bestSeller[0]._id }).then(
        (responseJson) => {
          setLoader(false);
          const { status, message } = responseJson;
          if (status) {
            notify({ type: 'success', message });
            this.setState({
              isDeleteConfirmOpen: false,
              productId: null,
              bestSellerFlag: false,
            });
            this._getBestSellerById();
          }
        },
      );
    } else {
      const data = {
        product_id: params.id,
      };
      API.addProductToBestSeller(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getBestSellerById();
          this.setState({
            isAddConfirmOpen: false,
            productId: null,
            bestSellerFlag: false,
          });
        }
      });
    }
  };

  // Top Trendig product

  _onTopTrendingDeleteAlert = () => {
    const {
      params: { id },
    } = this.props;
    const { topTrending } = this.state;
    this.setState({
      bestSellerFlag: false,
    });
    if (topTrending.length > 0) {
      this.setState({
        isDeleteConfirmOpen: true,
        productId: id,
        topTrendFlag: true,
      });
    } else {
      this.setState({
        isAddConfirmOpen: true,
        productId: id,
        topTrendFlag: true,
      });
    }
  };

  _onActiveTopTrending = () => {
    const { topTrending } = this.state;
    const { setLoader, notify, params } = this.props;
    setLoader(true);
    if (topTrending.length > 0) {
      API.deleteProductFromTopTrending({ id: topTrending[0]._id }).then(
        (responseJson) => {
          setLoader(false);
          const { status, message } = responseJson;
          if (status) {
            notify({ type: 'success', message });
            this._getTopTrendingById();
            this.setState({
              isDeleteConfirmOpen: false,
              productId: null,
              topTrendFlag: false,
            });
          }
        },
      );
    } else {
      const data = {
        product_id: params.id,
      };
      API.addProductToTopTrending(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getTopTrendingById();
          this.setState({
            isAddConfirmOpen: false,
            productId: null,
            topTrendFlag: false,
          });
        }
      });
    }
  };

  _onPressCancel = () => {
    browserHistory.push('/products');
  };

  UNSAFE_componentWillMount() {
    this._getProductById();
    this._getProductImageById();
    this._getProductVideoById();
    this._getBestSellerById();
    this._getTopTrendingById();
  }

  render() {
    // const { handleSubmit } = this.props;
    const {
      videos,
      images,
      products,
      bestSeller,
      topTrending,
      paramsId,
      bestSellerFlag,
      topTrendFlag,
      deleteImageFlag,
      isOpenAddProductVideo,
      isOpenAddProductImage,
      isDeleteConfirmOpen,
      isAddConfirmOpen,
      imageExist,
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product Image Gallary</h4>
                    <div className="card-header-action home-banner-button">
                      {imageExist || images.length > 0
                        ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-toggle="modal"
                            style={{ display: 'none' }}
                            data-target="#modalAddProductImage"
                            onClick={() => this._onOpenAddImageModal({})}
                          >
                            Add Product Image
                          </button>
                        )
                        : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#modalAddProductImage"
                            onClick={() => this._onOpenAddImageModal({})}
                          >
                            Add Product Image
                          </button>
                        )}
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <img
                      src={CDN_IMAGE_URL + products.product_image}
                      alt=""
                      onError={() => {
                        this._setImageExist(false);
                      }}
                      onLoad={() => {
                        this._setImageExist(true);
                      }}
                    />
                  </div>
                  {imageExist
                    ? (
                      <div className="card-body">
                        <div className="row">
                          <div className="ml-3">
                            <Imgix
                              sizes="(min-width: 960px) 33vw, (min-width: 640px) 50vw, 100vw"
                              src={CDN_IMAGE_URL + products.product_image}
                              imgixParams={{
                                fit: 'crop',
                                fm: 'jpg',
                              }}
                              width={200}
                              height={200}
                              border={1}
                            />
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className="card-body">
                        <div className="row">
                          {images.map((item, key) => (
                            <div key={key}>
                              <div className="cursor float-right mr-2">
                                <Link
                                  title="Delete"
                                  onClick={() => this._onDeleteImageAlert(item._id)}
                                >
                                  <i className="fa fa-times fa-lg" />
                                </Link>
                              </div>
                              <div className="m-2">
                                <Link to="/products">
                                  <Imgix
                                    sizes="(min-width: 960px) 33vw,
                                    (min-width: 640px) 50vw,(padding: 640px) 100vw"
                                    src={IMAGE_BASE_URL + item.image}
                                    imgixParams={{
                                      fit: 'crop',
                                      fm: 'jpg',
                                    }}
                                    width={177}
                                    height={180}
                                  />
                                </Link>
                              </div>
                            </div>
                          ))}
                          {images.length === 0 && (
                            <div>
                              <div className="ml-3">
                                <Imgix
                                  sizes="(min-width: 960px) 33vw, (min-width: 640px) 50vw, 100vw"
                                  src="/assets/images/no-image-icon.png"
                                  imgixParams={{
                                    fit: 'crop',
                                    fm: 'jpg',
                                  }}
                                  width={200}
                                  height={200}
                                  border={1}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product You Tube Link</h4>
                    {videos.length === 0 && (
                      <div className="card-header-action home-banner-button">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#modalAddProductVideo"
                          onClick={() => this._onOpenAddModal({})}
                        >
                          Add Product Video
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {videos.length > 0
                        && videos.map((item, key) => (
                          <div key={key} className="col-4 p-3">
                            <div className="cursor float-right">
                              {/* <Link title="Edit" onClick={() => this._onDeleteAlert(item._id)}>
                                <i className="fa fa-times fa-lg" />
                              </Link> */}
                              &nbsp;
                              <Link
                                title="Delete"
                                onClick={() => this._onDeleteAlert(item._id)}
                              >
                                <i className="fa fa-times fa-lg" />
                              </Link>
                            </div>
                            <iframe
                              width="290"
                              title="Image"
                              height="250"
                              src={item.video_link}
                              frameBorder="0"
                              allow="autoplay; encrypted-media"
                              allowFullScreen
                            />
                          </div>
                        ))}
                      {videos.length === 0 && (
                        <div>
                          <div className="ml-3">
                            <center>
                              <b>Video not available!</b>
                            </center>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Update Product</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(this._onSave)}
                      name="productEditForm"
                    >
                      <div className="row">
                        <div className="col-md-8">
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="stock"
                              className="col-sm-3 col-form-label"
                            >
                              Stock
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="stock"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="Stock"
                                validate={[required, number]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="price"
                              className="col-sm-3 col-form-label"
                            >
                              Price(€)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="price"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="price"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">
                              Update
                            </button>
                            {' '}
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => this._onPressCancel()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Best Seller & Top Trending Product</h4>
                  </div>
                  <div className="card-body">
                    <div className="row m-2">
                      <div className="col-4">
                        <div className="custom-checkbox custom-control">
                          <input
                            type="checkbox"
                            id="test"
                            className="custom-control-input"
                            checked={bestSeller.length > 0}
                            onChange={() => this._onBestSellerDeleteAlert()}
                          />
                          <label
                            htmlFor="test"
                            className="custom-control-label"
                          >
                            Best Seller
                          </label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="custom-checkbox custom-control">
                          <input
                            type="checkbox"
                            id="test1"
                            className="custom-control-input"
                            checked={topTrending.length > 0}
                            onChange={() => this._onTopTrendingDeleteAlert()}
                          />
                          <label
                            htmlFor="test1"
                            className="custom-control-label"
                          >
                            Top Trending
                          </label>
                        </div>
                      </div>
                    </div>
                    <span className="text-danger m-3">
                      Note: You can add/delete product in best seller and top
                      trending
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title={
            bestSellerFlag
              ? 'Delete product from Best seller'
              : topTrendFlag
                ? 'Delete product from Top Trending'
                : deleteImageFlag
                  ? 'Delete Product Image'
                  : 'Delete Product Video'
          }
          text="Are you sure want to delete this one?"
          onConfirm={
            bestSellerFlag
              ? this._onActiveBestSeller
              : topTrendFlag
                ? this._onActiveTopTrending
                : deleteImageFlag
                  ? this._onDeleteImage
                  : this._onDelete
          }
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
        <WarningBox
          isOpen={isAddConfirmOpen}
          title={
            bestSellerFlag
              ? 'Add product to Best seller'
              : 'Add product to Top Trending'
          }
          text="Are you sure you want to continue ?"
          onConfirm={
            bestSellerFlag
              ? this._onActiveBestSeller
              : this._onActiveTopTrending
          }
          onCancel={() => {
            this.setState({ isAddConfirmOpen: false });
          }}
        />
        <AddProductVideo
          isOpen={isOpenAddProductVideo}
          onCancel={() => {
            this.setState({ isOpenAddProductVideo: false });
          }}
          addProductVideo={this._addProductVideo}
          productId={paramsId}
        />
        <AddProductImage
          isOpen={isOpenAddProductImage}
          onCancel={() => {
            this.setState({ isOpenAddProductImage: false });
          }}
          addProductImage={this._addProductImage}
          productId={paramsId}
        />
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'productEditForm',
  destroyOnUnmount: false,
});
export default withForm(withLoader(withNotify(withUser(ProductEditForm))));
