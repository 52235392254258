import React, { Component } from 'react';
import {
  reduxForm, reset
} from 'redux-form';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import { IMAGE_BASE_URL } from '../../common/config';

class AddProductImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      imageView: '',
    };
  }

  _resetForm = () => {
    const { dispatch } = this.props;
    this.setState({ imageView: '' });
    dispatch(reset('addProductImage'));
    document.getElementById('modalAddProductImage').click();
  };

  _onSelectImage = async (imageData) => {
    const { notify } = this.props;
    if (imageData) {
      const imageSize = imageData.files[0].size;
      if (imageSize > 1024000) {
        notify({ type: 'warning', message: 'Image size shoud be less than 1Mb.' });
        return;
      }
      const readers = new FileReader();
      readers.readAsDataURL(imageData.files[0]);

      const uploadFormData = new FormData();
      uploadFormData.append('file', imageData.files[0]);
      const { setLoader } = this.props;
      setLoader(true);
      const data = { imageFile: imageData.files[0] };
      await API.uploadImage(data).then((responseJson) => {
        setLoader(false);
        const { picture, status } = responseJson;
        if (status) {
          this.setState({ imageView: picture });
        }
      });
    }
  }

  _onCancelImage = () => {
    this._resetForm();
  };

  _onDeleteImage = () => {
    this.setState({ imageView: '' });
  }

  _onAddImage = async () => {
    const { imageView } = this.state;
    const { addProductImage, productId, notify } = this.props;
    if (imageView !== '') {
      const data = {
        product_id: productId,
        image: imageView,
        status: 1,
      };
      addProductImage(data);
      this._resetForm();
    } else {
      notify({ type: 'error', message: 'Please Select Image' });
    }
  };

  render() {
    const { imageView } = this.state;
    const { handleSubmit } = this.props;
    return (
      <div
        className="modal fade" id="modalAddProductImage"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">Add Product Image</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addProductImage" onSubmit={handleSubmit(this._onAddImage)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="image" className="col-sm-3 col-form-label">Image</label>
                      <div className="col-sm-9">
                        <label htmlFor="cat_image" className="col-form-label from-row">
                          <small style={{ color: 'red' }}>
                            File Size (250*200)
                          </small>
                        </label>
                        <div className="row ml-3">
                          <img
                            src={imageView === ''
                              ? '/assets/images/no-image-icon.png'
                              : IMAGE_BASE_URL + imageView}
                            onError={() => {
                              this.onerror = null;
                              this.src = '/assets/images/no-image-icon.png';
                            }}
                            style={{ height: 50, width: 50 }}
                            className="col-3"
                            alt="image"
                          />
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm col-6 ml-3 mb-1"
                              onClick={() => document.getElementById('selectedFile').click()}
                            >
                              {(imageView === '') ? 'Upload Image' : 'Change Image'}
                            </button>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="selectedFile"
                              onChange={(e) => this._onSelectImage(e.target)}
                            />
                            {(imageView !== '')
                              && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger col-6 ml-3"
                                  onClick={() => this._onDeleteImage()}
                                >
                                  Delete Image
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">Save</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addProductImage'
});
export default withLoader(withForm(withNotify(AddProductImage)));
