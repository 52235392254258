import React, { useReducer, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';

const ProductListSearchSection = (props) => {
  const [search, setSearch] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);

  const initialState = {
    focusedInput: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'focusChange':
        return { ...state, focusedInput: action.payload };
      case 'dateChange':
        return action.payload;
      case 'reset':
        return action.payload;
      default:
        throw new Error();
    }
  };

  const options = [
    { _id: 'title', name: 'Title' },
    { _id: 'isbn13', name: 'ISBN' },
    { _id: 'titleprefix', name: 'Title prefix' },
    { _id: 'publisher', name: 'Publisher' },
    { _id: 'author', name: 'Author' },
  ];

  const onSelect = (selectedList) => {
    setSelectedFields(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedFields(selectedList);
  };

  const _onSearch = () => {
    setSearch(search);
    setSelectedFields(selectedFields);
    const { onSearch } = props;
    // const fields = selectedFields.map((x) => x._id);
    onSearch({
      // search, fields
      search
    });
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch(search);
      const { onSearch } = props;
      onSearch({
        search
      });
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const _onReset = () => {
    const { onSearch } = props;
    setSearch('');
    setSelectedFields('');
    onSearch({ search: '' });
    // window.location.reload(false);
    dispatch({ type: 'reset', payload: initialState });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4>Products</h4>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Search</label>
                    <input
                      type="text"
                      placeholder="Search keyword"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      value={state.search}
                      onKeyDown={(e) => handleKeypress(e)}
                    />
                  </div>
                </div>
                <div className="col-md-5" hidden>
                  <div className="form-group">
                    <label htmlFor="search">Select Status</label>
                    <Multiselect
                      className="form-control"
                      placeholder="Select Fields"
                      options={options} // Options to display in the dropdown
                      selectedValues={state.selectedFields}
                      onSelect={onSelect} // Function will trigger on select event
                      onRemove={onRemove} // Function will trigger on remove event
                      disablePreSelectedValues="true"
                      displayValue="name" // Property name to display in the dropdown options
                      showCheckbox="true"
                      style={{
                        chips: {
                          background: '#6777ef'
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group button-center">
                    <div className="">
                      <button className="btn btn-primary mr-1" type="button" onClick={_onSearch} disabled={search === ''}>Search</button>
                      <button className="btn btn-primary mr-1" type="reset" onClick={_onReset}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNotify(withLoader(ProductListSearchSection));
