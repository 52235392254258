import React from 'react';
import {
  withRouter, browserHistory, Route, Router
} from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { isEmpty } from 'lodash';
import { persistor, store } from './redux/store';
import App from './App';
import Dashboard from './pages/Dashboard';
import { notifySet, userSet, menuSet } from './redux/actions';
import { getUser } from './common/globals';
import SignIn from './pages/SignIn';
import CustomerList from './pages/Customers/CustomerList';
import AddressList from './pages/Customers/AddressList';
import CustomerAddEditForm from './pages/Customers/CustomerAddEditForm';
import HomeBanner from './pages/SiteSettings/HomeBanner';
import AddressAddEditForm from './pages/Customers/AddressAddEditForm';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import EmailTemplates from './pages/SiteSettings/EmailTemplates.js';
import AddUpdateEmailTemplate from './pages/SiteSettings/AddUpdateEmailTemplate';
import TaxList from './pages/SiteSettings/TaxList';
import TaxAddEditForm from './pages/SiteSettings/TaxAddEditForm';
import NotFound from './pages/NotFound';
import CmsPage from './pages/SiteSettings/CmsPage';
import Country from './pages/MasterSections/Country';
import CmsAddEditForm from './pages/SiteSettings/CmsAddEditForm';
import State from './pages/MasterSections/State';
import GeneralSetting from './pages/SiteSettings/GeneralSettingList';
import MaintenanceMode from './pages/SiteSettings/MaintenanceMode';
import ProductList from './pages/Products/ProductList';
import Wishlist from './pages/Products/Wishlist';
import NotifyList from './pages/Products/NotifyList';
import ContactUs from './pages/SiteSettings/ContactUs';
import ProductEditForm from './pages/Products/ProductEditForm';
import ProductViewList from './pages/Products/ProductViewList';
import OrderList from './pages/Orders/OrderList';
import OrderView from './pages/Orders/OrderView';
import ProductReviewList from './pages/Products/ProductReviewList';
import ProductSeoAddEditForm from './pages/Products/ProductSeoAddEditForm';
import DiscountCode from './pages/SiteSettings/DiscountCode';
import Notification from './pages/SiteSettings/Notification';
import RecentlyViewProductList from './pages/Products/RecentlyViewProductList';
import ProductAttribute from './pages/Products/ProductAttribute';
import Category from './pages/MasterSections/Category';
import BestSeller from './pages/Products/BestSeller';
import TopTrending from './pages/Products/TopTrending';
import SeoList from './pages/SiteSettings/SeoList';
import SeoAddEditForm from './pages/SiteSettings/SeoAddEditForm';
import SystemLogList from './pages/SiteSettings/SystemLogList';
import CustomerDiscount from './pages/SiteSettings/CustomerDiscount';
import SupplierDeliveryTime from './pages/MasterSections/SupplierDeliveryTime';
import VisiterLogList from './pages/SiteSettings/VisiterLogList';
import NewHomeBanner from './pages/SiteSettings/NewHomeBanner.js';

class Routes extends React.Component {
  _requireAuth = (menu) => async (nextState, replace) => {
    const user = await getUser();
    if (isEmpty(user)) {
      store.dispatch(notifySet({ type: 'error', message: 'Login required' }));
      browserHistory.push('/signin');
      replace(nextState.location);
    } else {
      menu.length > 0 && store.dispatch(menuSet(menu));
    }
    window.scrollTo(0, 0);
  };

  _checkAuth = async () => {
    const user = await getUser();
    if (!isEmpty(user)) {
      browserHistory.push('/');
    }
    window.scrollTo(0, 0);
  };

  async UNSAFE_componentWillMount() {
    store.dispatch(userSet(await getUser()));
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={browserHistory}>
            <Route component={App}>
              <Route
                exact
                path="/"
                components={{ component: Dashboard }}
                onEnter={this._requireAuth([1])}
              />
              <Route
                path="/signin"
                components={{ component: SignIn }}
                onEnter={this._checkAuth}
              />
              <Route
                exact
                path="/change-password"
                components={{ component: ChangePassword }}
                onEnter={this._requireAuth([1])}
              />
              <Route
                path="/customers"
                components={{ component: CustomerList }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/customers/create"
                components={{ component: CustomerAddEditForm }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/customers/:id"
                components={{ component: CustomerAddEditForm }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/customers/:id/address"
                components={{ component: AddressList }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/customers/:id/address/create"
                components={{ component: AddressAddEditForm }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/customers/:id/address/:addressId"
                components={{ component: AddressAddEditForm }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                exact
                path="/forgot-password"
                components={{ component: ForgotPassword }}
                onEnter={this._checkAuth}
              />
              <Route
                exact
                path="/reset-password/:code"
                components={{ component: ResetPassword }}
                onEnter={this._checkAuth}
              />
              <Route
                path="/products"
                components={{ component: ProductList }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/products/view/:id"
                components={{ component: ProductViewList }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/products/edit/:id"
                components={{ component: ProductEditForm }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/products/review-rating"
                components={{ component: ProductReviewList }}
                onEnter={this._requireAuth([3, 2])}
              />
              <Route
                path="/products/notifyMe/all"
                components={{ component: withRouter(NotifyList) }}
                onEnter={this._requireAuth([3, 3])}
              />
              <Route
                path="/products/notifyMe/:productId"
                components={{ component: withRouter(NotifyList) }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/customers/notifyMe/:customerId"
                components={{ component: withRouter(NotifyList) }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/products/wishlist"
                components={{ component: withRouter(Wishlist) }}
                onEnter={this._requireAuth([3, 4])}
              />
              <Route
                path="/products/wishlist/:productId"
                components={{ component: withRouter(Wishlist) }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/customers/wishlist/:customerId"
                components={{ component: withRouter(Wishlist) }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/products/seo/:productId"
                components={{ component: ProductSeoAddEditForm }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/products/seo/:productId/:id"
                components={{ component: ProductSeoAddEditForm }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/products/recently-view"
                components={{ component: RecentlyViewProductList }}
                onEnter={this._requireAuth([3, 5])}
              />
              <Route
                path="/products/attributes"
                components={{ component: ProductAttribute }}
                onEnter={this._requireAuth([3, 6])}
              />
              <Route
                path="/products/best-seller"
                components={{ component: BestSeller }}
                onEnter={this._requireAuth([3, 7])}
              />
              <Route
                path="/products/top-trending"
                components={{ component: TopTrending }}
                onEnter={this._requireAuth([3, 8])}
              />
              <Route
                path="/master-section/country"
                components={{ component: Country }}
                onEnter={this._requireAuth([5, 1])}
              />
              <Route
                path="/master-section/state"
                components={{ component: State }}
                onEnter={this._requireAuth([5, 2])}
              />
              <Route
                path="/master-section/category"
                components={{ component: Category }}
                onEnter={this._requireAuth([5, 3])}
              />
              <Route
                path="/master-section/manage-supplier-delivery-time"
                components={{ component: SupplierDeliveryTime }}
                onEnter={this._requireAuth([5, 4])}
              />
              <Route
                path="/site-settings/home-banner"
                components={{ component: HomeBanner }}
                onEnter={this._requireAuth([6, 1])}
              />
              <Route
                path="/site-settings/new-home-banner"
                components={{ component: NewHomeBanner }}
                onEnter={this._requireAuth([6, 1])}
              />
              <Route
                path="/site-settings/email-template"
                components={{ component: EmailTemplates }}
                onEnter={this._requireAuth([6, 2])}
              />
              <Route
                path="/email-templates/create"
                components={{ component: AddUpdateEmailTemplate }}
                onEnter={this._requireAuth([6, 2])}
              />
              <Route
                path="/email-templates/:id"
                components={{ component: AddUpdateEmailTemplate }}
                onEnter={this._requireAuth([6, 2])}
              />
              <Route
                path="/site-settings/shipping-charge"
                components={{ component: TaxList }}
                onEnter={this._requireAuth([6, 5])}
              />
              <Route
                path="/shipping-charge/create"
                components={{ component: TaxAddEditForm }}
                onEnter={this._requireAuth([6, 5])}
              />
              <Route
                path="/shipping-charge/:id"
                components={{ component: TaxAddEditForm }}
                onEnter={this._requireAuth([6, 5])}
              />
              <Route
                path="/site-settings/discount-code"
                components={{ component: DiscountCode }}
                onEnter={this._requireAuth([6, 6])}
              />
              <Route
                path="/site-settings/discount-code/:discountID"
                components={{ component: withRouter(CustomerDiscount) }}
                onEnter={this._requireAuth([6, 6])}
              />
              <Route
                path="/site-settings/notification"
                components={{ component: Notification }}
                onEnter={this._requireAuth([6, 8])}
              />
              <Route
                path="/site-settings/general-setting"
                components={{ component: GeneralSetting }}
                onEnter={this._requireAuth([6, 3])}
              />
              <Route
                path="/site-settings/system-log"
                components={{ component: SystemLogList }}
                onEnter={this._requireAuth([6, 10])}
              />
              <Route
                path="/site-settings/visitor-log"
                components={{ component: VisiterLogList }}
                onEnter={this._requireAuth([6, 11])}
              />
              <Route
                path="/site-settings/maintenance"
                components={{ component: MaintenanceMode }}
                onEnter={this._requireAuth([6, 6])}
              />
              <Route
                path="/site-settings/cms-pages"
                components={{ component: CmsPage }}
                onEnter={this._requireAuth([6, 4])}
              />
              <Route
                path="/site-settings/contact-us"
                components={{ component: ContactUs }}
                onEnter={this._requireAuth([6, 7])}
              />
              <Route
                path="/site-settings/manage-seo"
                components={{ component: SeoList }}
                onEnter={this._requireAuth([6, 9])}
              />
              <Route
                path="/seo-detail/add"
                components={{ component: SeoAddEditForm }}
                onEnter={this._requireAuth([6, 9])}
              />
              <Route
                path="/seo-detail/:id"
                components={{ component: SeoAddEditForm }}
                onEnter={this._requireAuth([6, 9])}
              />
              <Route
                path="/cms-pages/create"
                components={{ component: CmsAddEditForm }}
                onEnter={this._requireAuth([6, 4])}
              />
              <Route
                path="/cms-pages/:id"
                components={{ component: CmsAddEditForm }}
                onEnter={this._requireAuth([6, 4])}
              />
              <Route
                path="/orders"
                components={{ component: withRouter(OrderList) }}
                onEnter={this._requireAuth([4, 1])}
              />
              <Route
                path="/orders/:orderStatus"
                components={{ component: withRouter(OrderList) }}
                onEnter={this._requireAuth([4, 1])}
              />
              <Route
                path="/products/orders/:productId"
                components={{ component: withRouter(OrderList) }}
                onEnter={this._requireAuth([3, 1])}
              />
              <Route
                path="/customers/orders/:customerId"
                components={{ component: withRouter(OrderList) }}
                onEnter={this._requireAuth([2, 1])}
              />
              <Route
                path="/orders/view/:id"
                components={{ component: OrderView }}
                onEnter={this._requireAuth([4, 1])}
              />
              <Route path="*" components={{ component: NotFound }} />
            </Route>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default Routes;
