import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import CmsListSection from '../../components/SiteSettings/CmsListSection';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import CmsListSearchSection from '../../components/SiteSettings/CmsListSearchSection';
import API from '../../utils/utils';

class CmsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsPages: [],
      searchParams: {},
      page: 0,
      countCmsPages: 0,
      activeId: null,
    };
  }

  _cmsList = (searchParams) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.getCmsPages(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ cmsPages: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _countCmsPages = (searchData) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.getCmsPages({ count: 1, ...searchData }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countCmsPages: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteCmsPage({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        this._cmsList({});
        this._countCmsPages({});
        notify({ type: 'success', message });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onActiveDeactive = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.activeDeactiveCmsPage({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._cmsList({});
        // this.setState({ isActiveOpen: false, activeId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._cmsList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._cmsList(searchParams);
    this._countCmsPages(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._cmsList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._cmsList({});
      this._countCmsPages({});
    }
  }

  render() {
    const { cmsPages, countCmsPages, page } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <CmsListSearchSection onSearch={this._onSearch} />
            <CmsListSection
              data={cmsPages}
              onDelete={this._onDelete}
              onActiveDeactive={this._onActiveDeactive}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countCmsPages}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(CmsList)));
