import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';
import ViewSystemLog from '../../components/Models/ViewSystemLog';

class SystemLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      isOpenView: false,
      selectedId: null,
      selectedData: {},
      searchParams: {},
      page: 0,
      countLogs: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: ''
    };
  }

  _getLogs = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getSystemLogs(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ logs: data });
      }
    });
  };

  _countLogs = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getSystemLogs({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countLogs: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getLogs(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getLogs(searchParams);
    this._countLogs(searchParams);
  };

  handleKeypress = (e, searchData) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let { searchParams } = this.state;
      searchParams.page = 0;
      searchParams = { ...searchParams, ...searchData };
      this.setState({ searchParams, page: 0 });
      this._getLogs(searchParams);
      this._countLogs(searchParams);
    }
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getLogs({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onOpenViewModel = (selectedData, index = null) => {
    this.setState({ selectedData, isOpenView: true, selectedId: index });
  };

  UNSAFE_componentWillMount() {
    this._getLogs({});
    this._countLogs({});
  }

  render() {
    const {
      logs, selectedData, isOpenView,
      sortKey, sortOrder, search, countLogs, page
    }
      = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>System Logs</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                              onKeyDown={(e) => this.handleKeypress(e, { search })}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button className="btn btn-primary mr-1" type="button" onClick={() => this._onSearch({ search })}>
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1" type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>All Logs List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th
                              className={`sort ${sortKey === 'created_date' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'created_date', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Action Date
                            </th>
                            <th
                              className={`sort ${sortKey === 'user_role' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'user_role', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              User Role
                            </th>
                            <th
                              className={`sort ${sortKey === 'action_message' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'action_message', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Action Message
                            </th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !_.isEmpty(logs)
                            && logs.map((item, key) => (
                              <tr key={key}>
                                <td>
                                  {moment(item.created_date).format('DD/MM/YYYY')}
                                </td>
                                <td>{item.user_role}</td>
                                <td>{item.action_message}</td>
                                <td>
                                  <Link
                                    title="View more"
                                    data-toggle="modal"
                                    data-target="#modelViewSystemLog"
                                    onClick={() => this._onOpenViewModel(
                                      item,
                                      item._id
                                    )}
                                  >
                                    <i className="fas fa-eye" />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          }
                          {
                            logs.length === 0
                            && (
                              <tr>
                                <td colSpan={12}>
                                  <center><b>Records not available!</b></center>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countLogs}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ViewSystemLog
          isOpen={isOpenView}
          onCancel={() => {
            this.setState({ isOpenView: false });
          }}
          data={selectedData}
        />
      </div>
    );
  }
}
export default withLoader(SystemLog);
