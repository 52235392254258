import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Multiselect from 'multiselect-react-dropdown';
import withNotify from '../../common/withNotify';
import API from '../../utils/utils';
import {
  maxLength,
  number,
  renderField,
  required,
  percentageRegEx,
  minLength
} from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class AddEditDiscountCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      hasMinOrder: 'false',
      discountType: 'fix',
      isCampaign: 'false',
      selectedOptions: [],
      customerName: [],
      search: '',
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditDiscountCode'));
      this.setState({ selectedOptions: [] });
    }
    document.getElementById('modalAddEditDiscountCode').click();
  };

  //   _getDropdownButtonLabel({ placeholderButtonLabel, value }) {
  //   if (value && value.some((o) => o.value === "*")) {
  //     return `${placeholderButtonLabel}: All`;
  //   } else {
  //     return `${placeholderButtonLabel}: ${value.length} selected`;
  //   }
  // }

  loadOptions = (inputValue) => {
    setTimeout(() => {
      const { notify } = this.props;
      API.getAllCustomers({ search: inputValue }).then((res) => {
        const { status, message, data } = res;
        if (status) {
          this.setState({ customerName: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    }, 1000);
  }

  _getAllActiveCustomer = () => {
    const { notify } = this.props;
    API.getAllCustomers().then((responseJson) => {
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ customerName: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onAddDiscountCode = async (formData) => {
    const { addEditDiscountCode, notify } = this.props;
    const {
      startDate,
      endDate,
      hasMinOrder,
      discountType,
      isCampaign,
      selectedOptions,
    } = this.state;
    if (isCampaign === 'true' && selectedOptions.length === 0) {
      return notify({ type: 'error', message: 'Please select customer, if you want to assign discount to special customer.' });
    }
    const data = {
      name: formData.name,
      is_campaign: isCampaign === 'true' ? 1 : 0,
      code: formData.code,
      discount_type: discountType === 'fix' ? 1 : 0,
      amount: formData.amount,
      max_discount: formData.max_discount,
      has_min_order: hasMinOrder === 'true' ? 1 : 0,
      min_order_amount: hasMinOrder === 'true' ? formData.min_order_amount : '',
      start_date: startDate,
      end_date: endDate,
      selected_customer: selectedOptions,
      status: 1,
    };
    addEditDiscountCode(data);
    this._resetForm();
  };

  _onRemove(value) {
    const { selectedOptions } = this.state;
    const newSelectedOptions = selectedOptions.filter(
      (item) => item.value !== value.value
    );
    this.setState({ selectedOptions: newSelectedOptions });
  }

  _onChange(value, event) {
    const { customerName } = this.state;
    if (event.action === 'select-option' && event.option.value === '*') {
      this.setState({ selectedOptions: customerName });
    } else if (
      event.action === 'deselect-option'
      && event.option.value === '*'
    ) {
      this.setState({ selectedOptions: [] });
    } else if (event.action === 'deselect-option') {
      this.setState({ selectedOptions: value.filter((o) => o.value !== '*') });
    } else {
      this.setState({ selectedOptions: value });
    }
  }

  UNSAFE_componentWillMount() {
    const { customerName } = this.state;
    this._getAllActiveCustomer();
    this.setState({
      selectedOptions: [...customerName],
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (
      JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0
    ) {
      this.setState({
        isCampaign: data.is_campaign === true ? 'true' : 'false',
      });
      this.setState({
        discountType: data.discount_type === true ? 'fix' : 'percentage',
      });
      this.setState({
        hasMinOrder: data.has_min_order === true ? 'true' : 'false',
      });
      this.setState({
        selectedOptions: data.selected_customer,
      });
      this.setState({ startDate: new Date(data.start_date) });
      this.setState({ endDate: new Date(data.end_date) });
      dispatch(change('addEditDiscountCode', 'name', data.name));
      dispatch(change('addEditDiscountCode', 'is_campaign', data.is_campaign));
      dispatch(change('addEditDiscountCode', 'code', data.code));
      dispatch(change('addEditDiscountCode', 'amount', data.amount));
      dispatch(
        change('addEditDiscountCode', 'discount_type', data.discount_type),
      );
      dispatch(
        change('addEditDiscountCode', 'max_discount', data.max_discount),
      );
      dispatch(
        change('addEditDiscountCode', 'has_min_order', data.has_min_order),
      );
      dispatch(
        change(
          'addEditDiscountCode',
          'min_order_amount',
          data.min_order_amount,
        ),
      );
      dispatch(change('addEditDiscountCode', 'start_date', data.start_date));
      dispatch(change('addEditDiscountCode', 'end_date', data.end_date));
      dispatch(
        change(
          'addEditDiscountCode',
          'selected_customer',
          data.selected_customer,
        ),
      );
    }
    // if (Object.keys(data).length === 0) {
    //   this.setState({
    //     isCampaign: 'false',
    //   });
    //   this.setState({
    //     discountType: 'fix',
    //   });
    //   this.setState({
    //     hasMinOrder: 'false',
    //   });
    //   this.setState({
    //     selectedOptions: [],
    //   });
    //   this.setState({ startDate: new Date() });
    //   this.setState({ endDate: new Date() });
    //   dispatch(change('addEditDiscountCode', 'is_campaign', 0));
    //   dispatch(change('addEditDiscountCode', 'discount_type', ''));
    //   dispatch(change('addEditDiscountCode', 'has_min_order', ''));
    //   dispatch(change('addEditDiscountCode', 'selected_customer', ''));
    //   dispatch(change('addEditDiscountCode', 'start_date', ''));
    //   dispatch(change('addEditDiscountCode', 'end_date', ''));
    //   dispatch(change('addEditDiscountCode', 'name', ''));
    //   dispatch(change('addEditDiscountCode', 'code', ''));
    //   dispatch(change('addEditDiscountCode', 'amount', ''));
    //   dispatch(change('addEditDiscountCode', 'max_discount', ''));
    //   dispatch(change('addEditDiscountCode', 'min_order_amount', ''));
    // }
  }

  render() {
    const { handleSubmit, data, isView } = this.props;
    const {
      discountType,
      hasMinOrder,
      startDate,
      endDate,
      isCampaign,
      selectedOptions,
      customerName,
    } = this.state;
    return (
      <div
        className="modal fade"
        id="modalAddEditDiscountCode"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">
                {/* {`${Object.keys(data).length > 0 && { isView } ? 'View Discount' : ''}`} */}
                {`${Object.keys(data).length > 0 ? (isView ? 'View' : 'Edit') : 'Add'} Discount`}
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => this._resetForm()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              name="addEditDiscountCode"
              onSubmit={handleSubmit(this._onAddDiscountCode)}
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">
                        Name
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="name"
                          component={renderField}
                          className="form-control"
                          placeholder="Offer name"
                          validate={[required]}
                          disabled={isView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 float-right">
                    <div className="form-group form-row">
                      <div className="col-sm-9">
                        <input
                          type="checkbox"
                          name="has_min_order"
                          value={isCampaign}
                          checked={isCampaign === 'true'}
                          disabled={isView}
                          onChange={({ target: { value } }) => {
                            this.setState((s) => ({
                              ...s,
                              isCampaign: value === 'true' ? 'false' : 'true',
                            }));
                            this.setState({ selectedOptions: [] });
                          }}
                        />
                        <label
                          htmlFor="is_campaign"
                          className="col-sm-6 col-form-label"
                        >
                          Is Campaign
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {isCampaign === 'true' && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group form-row">
                        <label
                          htmlFor="customers"
                          className="col-sm-3 col-form-label"
                        >
                          Customers
                        </label>
                        <div className="col-sm-9">
                          {/* <ReactMultiSelectCheckboxes
                            style={{ width: '99%' }}
                            options={[...customerName]}
                            placeholderButtonLabel="Customer"
                            // getDropdownButtonLabel={this._getDropdownButtonLabel}
                            value={selectedOptions}
                            onChange={(value, event) => this._onChange(value, event)}
                            setState={selectedOptions}
                            onSearch={this.loadOptions}
                          /> */}
                          <Multiselect
                            displayValue="label"
                            showCheckbox
                            onRemove={(value, event) => this._onRemove(value, event)}
                            selectedValues={selectedOptions}
                            options={[...customerName]}
                            values={selectedOptions}
                            setState={selectedOptions}
                            onSelect={(value, event) => this._onChange(value, event)}
                            onSearch={(value) => this.loadOptions(value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {isView
                     && (selectedOptions.length > 0)
                     && (
                     <p>
                       Selected Customer:
                       {Object.keys(selectedOptions).map((item) => {
                         item.label;
                       })}
                     </p>
                     )} */}
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="code" className="col-sm-3 col-form-label">
                        Code
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="code"
                          component={renderField}
                          className="form-control"
                          placeholder="Discount Code"
                          validate={[required, maxLength(20), minLength(3)]}
                          disabled={isView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="discount_type"
                        className="col-sm-3 col-form-label"
                      >
                        Discount Type
                      </label>
                      <div className="col-sm-9">
                        <div className="selectgroup">
                          <label htmlFor="fix" className="selectgroup-item">
                            <Field
                              id="fix"
                              type="radio"
                              name="discount_type"
                              value="fix"
                              className="selectgroup-input-radio"
                              checked={discountType === 'fix'}
                              onChange={({ target: { value } }) => {
                                this.setState({ discountType: value });
                              }}
                              component="input"
                              disabled={isView}
                            />
                            <span className="selectgroup-button">Fix</span>
                          </label>
                          <label
                            htmlFor="percentage"
                            className="selectgroup-item"
                          >
                            <Field
                              id="percentage"
                              type="radio"
                              name="discount_type"
                              value="percentage"
                              component="input"
                              checked={discountType === 'percentage'}
                              onChange={({ target: { value } }) => {
                                this.setState({ discountType: value });
                              }}
                              className="selectgroup-input-radio"
                              disabled={isView}
                            />
                            <span className="selectgroup-button">
                              Percentage
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {discountType === 'fix' ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group form-row">
                        <label
                          htmlFor="amount"
                          className="col-sm-3 col-form-label"
                        >
                          Amount(€)
                        </label>
                        <div className="col-sm-9">
                          <Field
                            type="text"
                            name="amount"
                            component={renderField}
                            className="form-control"
                            placeholder="Amount"
                            validate={[required, number]}
                            disabled={isView}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group form-row">
                        <label
                          htmlFor="amount"
                          className="col-sm-3 col-form-label"
                        >
                          Amount (In Percentage)
                        </label>
                        <div className="col-sm-9">
                          <Field
                            type="text"
                            name="amount"
                            component={renderField}
                            className="form-control"
                            placeholder="Amount (In Percentage)"
                            validate={[required, percentageRegEx]}
                            disabled={isView}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="max_discount"
                        className="col-sm-3 col-form-label"
                      >
                        Max Discount(€)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="max_discount"
                          component={renderField}
                          className="form-control"
                          placeholder="Maximum Discount"
                          validate={[required, number]}
                          disabled={isView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-12 float-right">
                    <div className="form-group form-row">
                      <div className="col-sm-9">
                        <input
                          type="checkbox"
                          name="has_min_order"
                          value={hasMinOrder}
                          checked={hasMinOrder === 'true'}
                          disabled={isView}
                          onChange={({ target: { value } }) => {
                            this.setState((s) => ({
                              ...s,
                              hasMinOrder: value === 'true' ? 'false' : 'true',
                            }));
                          }}
                        />
                        <label
                          htmlFor="has_min_order"
                          className="col-sm-6 col-form-label"
                        >
                          Has minimum Order?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {hasMinOrder === 'true' && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group form-row">
                        <label
                          htmlFor="min_order_amount"
                          className="col-sm-3 col-form-label"
                        >
                          Minimum Order Amount(€)
                        </label>
                        <div className="col-sm-9">
                          <Field
                            type="text"
                            name="min_order_amount"
                            component={renderField}
                            className="form-control"
                            placeholder="Minimum Order Amount"
                            validate={[required, number]}
                            disabled={isView}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="start_date"
                        className="col-sm-3 col-form-label"
                      >
                        Start Date
                      </label>
                      <div className="col-sm-9">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select Start Date"
                          className="form-control"
                          style={{ width: '100%' }}
                          selected={startDate}
                          selectsStart
                          // startDate={startDate}
                          // endDate={startDate}
                          // minDate={startDate}
                          // isClearable
                          onChange={(value) => {
                            this.setState({ startDate: value });
                          }}
                          disabled={isView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="start_date"
                        className="col-sm-3 col-form-label"
                      >
                        End Date
                      </label>
                      <div className="col-sm-9">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select End Date"
                          className="form-control"
                          style={{ width: '100%' }}
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          // isClearable
                          onChange={(value) => this.setState({ endDate: value })}
                          disabled={isView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isView
                && (
                  <div className="modal-footer bg-whitesmoke br">
                    <button type="submit" className="btn btn-primary">
                      {Object.keys(data).length > 0 ? 'Update' : 'Save'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => this._resetForm()}
                    >
                      Cancel
                    </button>
                  </div>
                )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditDiscountCode'
});
export default withLoader(withForm(withNotify(AddEditDiscountCode)));
