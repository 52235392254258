import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import SeoListSearchSection from '../../components/SiteSettings/SeoListSearchSection';
import SeoListSection from '../../components/SiteSettings/SeoListSection';
import API from '../../utils/utils';

class SeoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seoPages: [],
      searchParams: {},
      page: 0,
      countCmsPages: 0,
    };
  }

  _seoPageList = (searchParams) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.getSeoDetails(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ seoPages: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _countSeoPages = (searchData) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.getSeoDetails({ count: 1, ...searchData }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countCmsPages: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteSeoDetail({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        this._seoPageList({});
        this._countSeoPages({});
        notify({ type: 'success', message });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._seoPageList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._seoPageList(searchParams);
    this._countSeoPages(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._seoPageList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._seoPageList({});
      this._countSeoPages({});
    }
  }

  render() {
    const { seoPages, countCmsPages, page } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <SeoListSearchSection onSearch={this._onSearch} />
            <SeoListSection
              data={seoPages}
              onDelete={this._onDelete}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countCmsPages}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(SeoList)));
