import React, { Component } from 'react';
import {
  change, reset,
  Field, reduxForm
} from 'redux-form';
import { browserHistory } from 'react-router';
import { isEmpty } from 'lodash';
import {
  renderField, required
} from '../../helpers/form-validations';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';

class ProductSeoAddEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('productSeoAddEditForm'));
  };

  _getSeoDetailsById = () => {
    const {
      setLoader, params: { productId, id }, dispatch
    } = this.props;
    if (id === 'undefined' || id === '-') {
      this.setState({ isEdit: false });
      this._getProductById();
    } else {
      this.setState({ isEdit: true });
      setLoader(true);
      API.getProductSeoById(productId).then((responseJson) => {
        setLoader(false);
        const { status, data } = responseJson;
        if (status && data) {
          Object.keys(data).map((key) => {
            dispatch(change('productSeoAddEditForm', key, data[key]));
          });
        }
      });
    }
  };

  _getProductById = () => {
    const {
      setLoader, notify, params: { productId }, dispatch
    } = this.props;
    setLoader(true);
    API.getProductById({ id: productId }).then((responseJson) => {
      setLoader(false);
      const { status, message, product } = responseJson;
      if (status) {
        dispatch(change('productSeoAddEditForm', 'meta_tag_en', product.title));
        dispatch(change('productSeoAddEditForm', 'meta_title_en', product.title));
        dispatch(change('productSeoAddEditForm', 'meta_description_en', (`${product.isbn13}, ${product.title} ,  ${product.author}`)));
        dispatch(change('productSeoAddEditForm', 'meta_keyword_en', product.isbn13));
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSave = (formData) => {
    const {
      setLoader, notify, params, params: { id = null }
    } = this.props;
    const data = {
      meta_tag_en: formData.meta_tag_en,
      meta_title_en: formData.meta_title_en,
      meta_description_en: formData.meta_description_en,
      meta_keyword_en: formData.meta_keyword_en,
      meta_tag_de: formData.meta_tag_de,
      meta_title_de: formData.meta_title_de,
      meta_description_de: formData.meta_description_de,
      meta_keyword_de: formData.meta_keyword_de,
      meta_tag_du: formData.meta_tag_du,
      meta_title_du: formData.meta_title_du,
      meta_description_du: formData.meta_description_du,
      meta_keyword_du: formData.meta_keyword_du,
      status: 1,
    };
    if (id === 'undefined' || id === '-') {
      data.product_id = params.productId;
      setLoader(true);
      API.addProductSeoDetails(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/products');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      data.id = params.id;
      data.product_id = params.productId;
      setLoader(true);
      API.updateProductSeoDetails(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/products');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onPressCancel = () => {
    browserHistory.push('/products');
    this._clearForm();
  }

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      if (params.hasOwnProperty('id')) {
        this._getSeoDetailsById();
      }
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isEdit
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>{(isEdit) ? 'Update Product SEO Details' : 'Add Product SEO Details'}</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this._onSave)} name="productSeoAddEditForm">
                      <div className="row">
                        <div className="col-md-8">
                          <h3>English</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_tag_en" className="col-sm-3 col-form-label">Meta Tag (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_tag_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_title_en" className="col-sm-3 col-form-label">Meta Title (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_description_en" className="col-sm-3 col-form-label">Meta Description (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_keyword_en" className="col-sm-3 col-form-label">Meta Keyword (en)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <h3>German</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_tag_de" className="col-sm-3 col-form-label">Meta Tag (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_tag_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_title_de" className="col-sm-3 col-form-label">Meta Title (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_description_de" className="col-sm-3 col-form-label">Meta Description (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_keyword_de" className="col-sm-3 col-form-label">Meta Keyword (de)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <h3>Dutch</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_tag_du" className="col-sm-3 col-form-label">Meta Tag (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_tag_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_title_du" className="col-sm-3 col-form-label">Meta Title (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_description_du" className="col-sm-3 col-form-label">Meta Description (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label htmlFor="meta_keyword_du" className="col-sm-3 col-form-label">Meta Keyword (du)</label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">{(isEdit) ? 'Update' : 'Save'}</button>
                            {' '}
                            &nbsp;&nbsp;
                            <button className="btn btn-primary" type="button" onClick={() => this._onPressCancel()}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'productSeoAddEditForm',
  destroyOnUnmount: false
});
export default withForm(withLoader(withNotify(withUser(ProductSeoAddEditForm))));
