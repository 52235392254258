import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import ConfirmBox from '../../components/Models/ConfirmBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';
import AddEditCategory from '../../components/Models/AddEditCategory';
import { IMAGE_BASE_URL } from '../../common/config';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      isOpenAddEditCategory: false,
      isDeleteConfirmOpen: false,
      selectedId: null,
      selectedData: {},
      searchParams: {},
      page: 0,
      countCategory: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: ''
    };
  }

  _getCategories = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getCategories(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ categories: data });
      }
    });
  };

  _countCategory = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getCategories({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countCategory: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getCategories(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getCategories(searchParams);
    this._countCategory(searchParams);
  };

  handleKeypress = (e, searchData) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let { searchParams } = this.state;
      searchParams.page = 0;
      searchParams = { ...searchParams, ...searchData };
      this.setState({ searchParams, page: 0 });
      this._getCategories(searchParams);
      this._countCategory(searchParams);
    }
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getCategories({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onOpenAddEditModal = (selectedData, index = null) => {
    this.setState({ selectedData, isOpenAddEditCategory: true, selectedId: index });
  };

  _addEditCategory = (data) => {
    const { setLoader, notify } = this.props;
    const { selectedData, selectedId } = this.state;
    setLoader(true);
    if (Object.keys(selectedData).length > 0 && selectedId != null) {
      data.id = selectedId;
      API.updateCategory(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getCategories({});
          this._countCategory({});
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.addCategory(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message: 'Category added successfully.' });
          this._getCategories({});
          this._countCategory({});
          this.setState({ isOpenAddEditCategory: false });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onDelete = () => {
    const { selectedId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteCategory(selectedId).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getCategories({});
        this._countCategory({});
        this.setState({ isDeleteConfirmOpen: false, selectedId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    this._getCategories({});
    this._countCategory({});
  }

  render() {
    const {
      categories, isDeleteConfirmOpen, selectedData, isOpenAddEditCategory,
      sortKey, sortOrder, search, countCategory, page
    }
      = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Categories</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                              onKeyDown={(e) => this.handleKeypress(e, { search })}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button className="btn btn-primary mr-1" type="button" onClick={() => this._onSearch({ search })}>
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1" type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>All Categories List</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        hidden
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalAddEditCategory"
                        onClick={() => this._onOpenAddEditModal(
                          {

                          }
                        )}
                      >
                        Add New Category
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th
                              className={`sort ${sortKey === 'title_en' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'title_en', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Title (en)
                            </th>
                            <th
                              className={`sort ${sortKey === 'title_de' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'title_de', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Title (de)
                            </th>
                            <th
                              className={`sort ${sortKey === 'title_du' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'title_du', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Title (du)
                            </th>
                            <th
                              className={`sort ${sortKey === 'bic_group' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'bic_group', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Bic group
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !_.isEmpty(categories)
                            && categories.map((item, key) => (
                              <tr key={key}>
                                <td>
                                  <img
                                    src={item.cat_image === null || item.cat_image === ''
                                      ? '/assets/images/no-image-icon.png'
                                      : IMAGE_BASE_URL + item.cat_image}
                                    onError={() => {
                                      this.onerror = null;
                                      this.src = '/assets/images/no-image-icon.png';
                                    }}
                                    style={{ height: 50, width: 50 }}
                                    alt="image"
                                  />
                                </td>
                                <td>{item.title_en}</td>
                                <td>{item.title_de}</td>
                                <td>{item.title_du}</td>
                                <td>{item.bic_group ? item.bic_group : '-'}</td>
                                <td>
                                  <Link
                                    title="Edit"
                                    data-toggle="modal"
                                    data-target="#modalAddEditCategory"
                                    aria-label="Edit"
                                    onClick={() => this._onOpenAddEditModal(
                                      {
                                        title_en: item.title_en,
                                        title_de: item.title_de,
                                        title_du: item.title_du,
                                        bic_group: item.bic_group,
                                        cat_image: item.cat_image,
                                      },
                                      item._id
                                    )}
                                  >
                                    <i className="fas fa-pencil-alt" aria-hidden="true" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                  <Link
                                    title="Delete"
                                    className="cursor"
                                    aria-label="Delete"
                                    onClick={() => this._onDeleteAlert(item._id)}
                                  >
                                    <i className="far fa-trash-alt" aria-hidden="true" />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          }
                          {
                            categories.length === 0
                            && (
                              <tr>
                                <td colSpan={12}>
                                  <center><b>Records not available!</b></center>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countCategory}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Category"
          text="Are you sure want to delete this category?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
        <AddEditCategory
          isOpen={isOpenAddEditCategory}
          onCancel={() => {
            this.setState({ isOpenAddEditCategory: false });
          }}
          addEditCategory={this._addEditCategory}
          data={selectedData}
        />
      </div>
    );
  }
}
export default withLoader(withNotify(Category));
