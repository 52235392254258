import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import moment from 'moment';
import ConfirmBox from '../../components/Models/ConfirmBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';

class BestSeller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isDeleteConfirmOpen: false,
      selectedId: null,
      searchParams: {},
      page: 0,
      countProducts: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: ''
    };
  }

  _getBestSeller = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getBestSellerProduct(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ products: data });
      } else {
        this.setState({ products: [] });
      }
    });
  };

  _countBestSeller = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getBestSellerProduct({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countProducts: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getBestSeller(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getBestSeller(searchParams);
    this._countBestSeller(searchParams);
  };

  handleKeypress = (e, searchData) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let { searchParams } = this.state;
      searchParams.page = 0;
      searchParams = { ...searchParams, ...searchData };
      this.setState({ searchParams, page: 0 });
      this._getBestSeller(searchParams);
      this._countBestSeller(searchParams);
    }
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getBestSeller({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onDelete = () => {
    const { selectedId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteProductFromBestSeller({ id: selectedId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getBestSeller({});
        this._countBestSeller({});
        this.setState({ isDeleteConfirmOpen: false, selectedId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    this._getBestSeller({});
    this._countBestSeller({});
  }

  render() {
    const {
      products, isDeleteConfirmOpen,
      sortKey, sortOrder, search, countProducts, page
    }
      = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Best Seller</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                              onKeyDown={(e) => this.handleKeypress(e, { search })}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button className="btn btn-primary mr-1" type="button" onClick={() => this._onSearch({ search })}>
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1" type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>All Best Seller Product</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th
                              className={`sort ${sortKey === 'isbn13' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'isbn13', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Product ISBN
                            </th>
                            <th
                              className={`sort ${sortKey === 'title' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'title', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Product title
                            </th>
                            <th
                              className={`sort ${sortKey === 'created_date' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'created_date', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Added Date
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !_.isEmpty(products)
                            && products.map((item, key) => (
                              <tr key={key}>
                                <td>{item.isbn13}</td>
                                <td>
                                  {item.title ? `${item.title.slice(0, 50)}...` : '-'}
                                </td>
                                <td>
                                  {item.created_date && (
                                    moment(item.created_date).format('DD/MM/YYYY')
                                  )}
                                </td>
                                <td>
                                  <Link
                                    title="Delete"
                                    className="cursor"
                                    onClick={() => this._onDeleteAlert(item._id)}
                                    aria-label="Delete"
                                  >
                                    <i className="far fa-trash-alt" aria-hidden="true" />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          }
                          {
                            products.length === 0
                            && (
                              <tr>
                                <td colSpan={12}>
                                  <center><b>Records not available!</b></center>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countProducts}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete"
          text="Are you sure want to delete this product from best seller?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
      </div>
    );
  }
}
export default withLoader(withNotify(BestSeller));
