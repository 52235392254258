import { connect } from 'react-redux';
import { menuSet } from '../redux/actions';

const mapDispatchToProps = (dispatch) => ({
  setMenu: (data) => {
    dispatch(menuSet(data));
  }
});

const mapStateToProps = (state) => ({
  menu: state.menu ? state.menu : []
});

export default connect(mapStateToProps, mapDispatchToProps);
