import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

class ConfirmBox extends Component {
  render() {
    const {
      isOpen = false, title, text, onConfirm, onCancel, confirmButtonText = 'Yes, delete it!', confirmButtonStyle = 'danger'
    } = this.props;
    return (
      isOpen && (
      <SweetAlert
        danger
        showCancel
        confirmBtnText={confirmButtonText}
        confirmBtnBsStyle={confirmButtonStyle}
        title={title}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        focusCancelBtn
      >
        {text}
      </SweetAlert>
      )
    );
  }
}

export default ConfirmBox;
