import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react/lib/es2015/main/ts';
import {
  change, reset, Field, reduxForm
} from 'redux-form';
import { browserHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { renderField, required } from '../../helpers/form-validations';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import { TINY_CLOUD_API_KEY } from '../../common/config';

class CmsAddEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCategory: 'myboeken',
      contentOne: null,
      contentTwo: null,
      contentThree: null,
      isEdit: false,
    };
  }

  _clearForm = () => {
    const { dispatch } = this.props;
    dispatch(reset('cmsAddEditForm'));
    this.setState({ contentOne: null });
    this.setState({ contentTwo: null });
    this.setState({ contentThree: null });
  };

  _getCmsPageById = () => {
    const {
      setLoader,
      notify,
      params: { id },
      dispatch,
    } = this.props;
    setLoader(true);
    API.getCmsPageById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        Object.keys(data).map((key) => {
          dispatch(change('cmsAddEditForm', key, data[key]));
        });
        this.setState({ contentOne: data.description_en });
        this.setState({ contentTwo: data.description_de });
        this.setState({ contentThree: data.description_du });
        this.setState({ selectCategory: data.cms_category });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSave = (formData) => {
    const {
      setLoader,
      notify,
      params,
      params: { id = null },
    } = this.props;
    const {
      contentOne, contentTwo, contentThree, selectCategory
    } = this.state;

    const data = {
      cms_category: selectCategory,
      title_en: formData.title_en,
      description_en: contentOne,
      meta_title_en: formData.meta_title_en,
      meta_description_en: formData.meta_description_en,
      meta_keyword_en: formData.meta_keyword_en,
      title_de: formData.title_de,
      description_de: contentTwo,
      meta_title_de: formData.meta_title_de,
      meta_description_de: formData.meta_description_de,
      meta_keyword_de: formData.meta_keyword_de,
      title_du: formData.title_du,
      description_du: contentThree,
      meta_title_du: formData.meta_title_du,
      meta_description_du: formData.meta_description_du,
      meta_keyword_du: formData.meta_keyword_du,
      status: 1,
    };

    if (id != null) {
      data.id = params.id;
      setLoader(true);
      API.updateCmsPage(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/site-settings/cms-pages');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      setLoader(true);
      API.addCmsPage(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          browserHistory.push('/site-settings/cms-pages');
          this._clearForm();
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  handleCategoryChange = (e) => {
    this.setState({ selectCategory: e.target.value });
  };

  _handleEditorChangeOne = (contentOne) => {
    this.setState({ contentOne });
  };

  _handleEditorChangeTwo = (contentTwo) => {
    this.setState({ contentTwo });
  };

  _handleEditorChangeThree = (contentThree) => {
    this.setState({ contentThree });
  };

  _onPressCancel = () => {
    browserHistory.push('/site-settings/cms-pages');
    this._clearForm();
  };

  UNSAFE_componentWillMount() {
    const { params, user } = this.props;
    if (!isEmpty(user)) {
      if (params.hasOwnProperty('id')) {
        this.setState({ isEdit: true });
        this._getCmsPageById();
      }
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const {
      isEdit, contentOne, contentTwo, contentThree
    } = this.state;

    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>{isEdit ? 'Update CMS Page' : 'Create CMS Page'}</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(this._onSave)}
                      name="cmsAddEditForm"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <h3>English</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="title_en"
                              className="col-sm-3 col-form-label"
                            >
                              Select Category
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                value={this.state.selectCategory}
                                onChange={this.handleCategoryChange}
                              >
                                <option value="myboeken">MYBOEKEN.NL</option>
                                <option value="orderInfo">
                                  Ordering Info.
                                </option>
                                <option value="customerService">
                                  Customer service
                                </option>
                                <option value="others">Others(Hidden)</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="title_en"
                              className="col-sm-3 col-form-label"
                            >
                              Title (en)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="title_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>

                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="description_en"
                              className="col-sm-3 col-form-label"
                            >
                              Description (en)
                            </label>
                            <div
                              className="col-sm-9"
                              style={{
                                border: '1px solid grey',
                                overflow: 'scroll',
                                height: '300px',
                              }}
                            >
                              <Editor
                                apiKey={TINY_CLOUD_API_KEY}
                                value={contentOne}
                                init={{
                                  selector: 'textarea', // change this value according to your HTML
                                  file_picker_types: 'file image media',
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                                    'searchreplace wordcount visualblocks visualchars code fullscreen',
                                    'insertdatetime media nonbreaking save table contextmenu directionality',
                                    'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help image code',
                                  ],
                                  toolbar1:
                                    'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | fontsizeselect',
                                  toolbar2:
                                    'print preview media | forecolor backcolor emoticons | codesample help',
                                  media_live_embeds: true,
                                }}
                                onEditorChange={this._handleEditorChangeOne}
                              />
                            </div>
                          </div>

                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_title_en"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Title (en)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_description_en"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Description (en)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_keyword_en"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Keyword (en)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_en"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <h3>German</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="title_de"
                              className="col-sm-3 col-form-label"
                            >
                              Title (de)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="title_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="description_de"
                              className="col-sm-3 col-form-label"
                            >
                              Description (de)
                            </label>
                            <div className="col-sm-9">
                              <Editor
                                apiKey={TINY_CLOUD_API_KEY}
                                value={contentTwo}
                                init={{
                                  selector: 'textarea', // change this value according to your HTML
                                  file_picker_types: 'file image media',
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                                    'searchreplace wordcount visualblocks visualchars code fullscreen',
                                    'insertdatetime media nonbreaking save table contextmenu directionality',
                                    'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help image code',
                                  ],
                                  toolbar1:
                                    'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | fontsizeselect',
                                  toolbar2:
                                    'print preview media | forecolor backcolor emoticons | codesample help',
                                  media_live_embeds: true,
                                }}
                                onEditorChange={this._handleEditorChangeTwo}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_title_de"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Title (de)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_description_de"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Description (de)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_keyword_de"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Keyword (de)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_de"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <h3>Dutch</h3>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="title_du"
                              className="col-sm-3 col-form-label"
                            >
                              Title (du)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="title_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="description_du"
                              className="col-sm-3 col-form-label"
                            >
                              Description (du)
                            </label>
                            <div className="col-sm-9">
                              <Editor
                                apiKey={TINY_CLOUD_API_KEY}
                                value={contentThree}
                                init={{
                                  selector: 'textarea', // change this value according to your HTML
                                  file_picker_types: 'file image media',
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                                    'searchreplace wordcount visualblocks visualchars code fullscreen',
                                    'insertdatetime media nonbreaking save table contextmenu directionality',
                                    'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc help image code',
                                  ],
                                  toolbar1:
                                    'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | fontsizeselect',
                                  toolbar2:
                                    'print preview media | forecolor backcolor emoticons | codesample help',
                                  media_live_embeds: true,
                                }}
                                onEditorChange={this._handleEditorChangeThree}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_title_du"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Title (du)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_title_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Title"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_description_du"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Description (du)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="textarea"
                                name="meta_description_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Description"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-group form-row">
                            <label
                              htmlFor="meta_keyword_du"
                              className="col-sm-3 col-form-label"
                            >
                              Meta Keyword (du)
                            </label>
                            <div className="col-sm-9">
                              <Field
                                type="text"
                                name="meta_keyword_du"
                                component={renderField}
                                className="form-control"
                                placeholder="Meta Keyword"
                                validate={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-10 p-0 form-row form-group">
                            <button className="btn btn-primary" type="submit">
                              {isEdit ? 'Update' : 'Save'}
                            </button>
                            {' '}
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => this._onPressCancel()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  form: 'cmsAddEditForm',
  destroyOnUnmount: false,
});
export default withForm(withLoader(withNotify(withUser(CmsAddEditForm))));
