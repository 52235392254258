import React, { useReducer, useState } from 'react';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';

const CustomerListSearchSection = (props) => {
  const [search, setSearch] = useState('');

  const initialState = {
    focusedInput: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'focusChange':
        return { ...state, focusedInput: action.payload };
      case 'dateChange':
        return action.payload;
      case 'reset':
        return action.payload;
      default:
        throw new Error();
    }
  };

  const _onSearch = () => {
    setSearch(search);
    const { onSearch } = props;
    onSearch({
      search
    });
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch(search);
      const { onSearch } = props;
      onSearch({
        search
      });
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const _onReset = () => {
    const { onSearch } = props;
    setSearch('');
    onSearch({ search: '' });
    dispatch({ type: 'reset', payload: initialState });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4>Customers</h4>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Search</label>
                    <input
                      type="text"
                      placeholder="Search keyword"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      value={state.search}
                      onKeyDown={(e) => handleKeypress(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group button-center">
                    <div className="">
                      <button className="btn btn-primary mr-1" type="button" onClick={_onSearch}>Search</button>
                      <button className="btn btn-primary mr-1" type="reset" onClick={_onReset}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNotify(withLoader(CustomerListSearchSection));
