import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import API from '../../utils/utils';
import withNotify from '../../common/withNotify';
import { renderField, required } from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class AddEditState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      states: [],
      selectedCountry: null
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditState'));
      this.setState({ selectedCountry: null });
    }
    document.getElementById('modalAddEditState').click();
  };

  _getCountries = () => {
    const { notify } = this.props;
    API.getAllCountries().then((responseJson) => {
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countries: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onAddState = async (formData) => {
    const { addEditState, notify } = this.props;
    const { selectedCountry } = this.state;
    if (selectedCountry === null) {
      notify({ type: 'error', message: 'Please Select Country' });
    } else {
      const data = {
        country_id: selectedCountry,
        name: formData.name,
      };
      addEditState(data);
      this._resetForm();
    }
  };

  UNSAFE_componentWillMount() {
    this._getCountries();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
        && Object.keys(data).length > 0) {
      this.setState({ selectedCountry: data.country_id });
      dispatch(change('addEditState', 'country_id', data.country_id));
      dispatch(change('addEditState', 'name', data.name));
    }
    // if (Object.keys(data).length === 0) {
    //   this.setState({ selectedCountry: null });
    //   dispatch(change('addEditState', 'country_id', ''));
    //   dispatch(change('addEditState', 'name', ''));
    // }
  }

  render() {
    const { handleSubmit, data } = this.props;
    const {
      countries, selectedCountry
    } = this.state;
    return (
      <div
        className="modal fade" id="modalAddEditState"
        role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">{`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} State`}</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addEditState" onSubmit={handleSubmit(this._onAddState)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="country" className="col-sm-3 col-form-label">Country</label>
                      <div className="col-sm-9">
                        <Select2
                          style={{ width: '100%' }}
                          className="form-control"
                          data={
                            countries.map(({ name, _id }) => ({ id: _id, text: name }))
                            }
                          options={{ placeholder: 'Select Country' }}
                          value={selectedCountry}
                          onSelect={({ target: { value } }) => {
                            this.setState({ selectedCountry: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">State</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="name"
                          component={renderField}
                          className="form-control"
                          placeholder="State"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">{Object.keys(data).length > 0 ? 'Update' : 'Save' }</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditState'
});
export default withLoader(withForm(withNotify(AddEditState)));
