import React, { Component } from 'react';
// import { change, reduxForm } from 'redux-form';
import { Link } from 'react-router';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
// import withUser from '../../common/withUser';
import API from '../../utils/utils';
import ConfirmBox from '../../components/Models/ConfirmBox';

class MaintenanceMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenance_mode: null,
      isUpdateConfirmOpen: false,
      settings: [],
    };
  }

  _onUpdateAlert = () => {
    this.setState({ isUpdateConfirmOpen: true });
  };

  _onUpdateMaintenance = () => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.updateMaintenanceMode().then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getMaintenanceMode({});
        this.setState({ isUpdateConfirmOpen: false });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _getMaintenanceMode = () => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getGeneralSetting().then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({
          maintenance_mode: data.maintenance_mode,
          settings: data,
        });
      }
    });
  };

  UNSAFE_componentWillMount() {
    this._getMaintenanceMode();
  }

  render() {
    const { maintenance_mode, isUpdateConfirmOpen } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Update Mode </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="col-10 p-0 form-group form-row">
                          <label
                            htmlFor="name"
                            className="col-sm-3 col-form-label"
                          >
                            Maintenance Mode
                          </label>
                          <div className="col-sm-6">
                            &nbsp;&nbsp;&nbsp;
                            <Link
                              title={
                                maintenance_mode === 1
                                  ? 'On'
                                  : maintenance_mode === 0
                                    ? 'of'
                                    : ''
                              }
                              className="cursor"
                              onClick={() => this._onUpdateAlert()}
                            >
                              {maintenance_mode === 1 ? (
                                <i className="fa fa-toggle-on fa-2x" />
                              ) : maintenance_mode === 0 ? (
                                <i className="fa fa-toggle-off fa-2x" />
                              ) : (
                                ''
                              )}
                            </Link>
                          </div>
                          <br />
                          <div className="mt-5">
                            {maintenance_mode === 1
                              ? 'Currently, site is in maintenance mode'
                              : maintenance_mode === 0
                                ? 'Currently, site is out of maintenance mode'
                                : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmBox
            isOpen={isUpdateConfirmOpen}
            title="Change maintenance mode"
            text="Are you sure want to change maintenance mode?"
            confirmButtonText="Yes, Change it"
            onConfirm={() => this._onUpdateMaintenance()}
            onCancel={() => {
              this.setState({
                isUpdateConfirmOpen: false,
              });
            }}
          />
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(MaintenanceMode));
