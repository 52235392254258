import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const sentryConnect = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn:
        'https://6ba50b412ea045c49a16a83b9f9adc64@o652133.ingest.sentry.io/5766525',
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
};
