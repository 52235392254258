import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import ConfirmBox from '../Models/ConfirmBox';
// import WarningBox from '../Models/WarningBox';
import Pagination from '../Pagination';

class TaxListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      isActiveOpen: false,
      selectedId: null,
      activeId: null,
      sortKey: 'name',
      sortOrder: 'asc',
    };
  }

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onPressDeleteIcon = () => {
    const { onDelete } = this.props;
    const { selectedId } = this.state;
    this.setState({ isDeleteConfirmOpen: false, selectedId: null });
    onDelete(selectedId);
  };

  _onActiveDeactiveAlert = (id) => {
    this.setState({ isActiveOpen: true, activeId: id });
  };

  _onPressActiveDeactiveIcon = () => {
    const { onActiveDeactive } = this.props;
    const { activeId } = this.state;
    this.setState({ isActiveOpen: false, activeId: null });
    onActiveDeactive(activeId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const {
      isDeleteConfirmOpen, sortKey, sortOrder
    }
      = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Shipping Charges List</h4>
              <div className="card-header-action">
                <Link to="/shipping-charge/create" className="btn btn-primary">
                  Add Shipping Charge
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${
                          sortKey === 'name' ? `sort-by-${sortOrder}` : ''
                        } `}
                        onClick={this._onSort.bind(
                          this,
                          'name',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Name
                      </th>
                      <th
                        className={`sort ${
                          sortKey === 'country' ? `sort-by-${sortOrder}` : ''
                        } `}
                        onClick={this._onSort.bind(
                          this,
                          'country',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Country
                      </th>
                      <th
                        className={`sort ${
                          sortKey === 'delivery_charge'
                            ? `sort-by-${sortOrder}`
                            : ''
                        } `}
                        onClick={this._onSort.bind(
                          this,
                          'delivery_charge',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Delivery charge(€)
                      </th>
                      {/* <th>Is-Activated</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0
                      && data.map((item, key) => (
                        <tr key={key}>
                          <td>{item.name}</td>
                          <td>{item.country}</td>
                          <td>
                            {item.delivery_charge}
                          </td>
                          {/* <td>
                            <div className="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={key}
                                checked={item.status === 1}
                                onChange={() => this._onActiveDeactiveAlert(item._id)}
                              />
                              <label
                                htmlFor={key}
                                className="custom-control-label"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td> */}
                          <td>
                            <Link
                              to={`/shipping-charge/${item._id}`}
                              title="Edit"
                              className="cursor"
                            >
                              <i className="fas fa-pencil-alt" />
                            </Link>
                            {/* <Link
                              title="Delete"
                              onClick={() => this._onDeleteAlert(item._id)}
                              className="cursor"
                            >
                              <i className="far fa-trash-alt" />
                            </Link> */}
                          </td>
                        </tr>
                      ))}
                    {data.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <center>
                            <b>Records not available!</b>
                          </center>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Shipping Charge"
          text="Are you sure want to delete this Shipping Charge?"
          onConfirm={() => this._onPressDeleteIcon()}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false, selectedId: null });
          }}
        />
        {/* <WarningBox
          isOpen={isActiveOpen}
          title="Active/Deactive"
          text="Are you sure you want to continue ?"
          onConfirm={this._onPressActiveDeactiveIcon}
          onCancel={() => {
            this.setState({ isActiveOpen: false });
          }}
        /> */}
      </div>
    );
  }
}

export default withNotify(withLoader(TaxListSection));
