import React, { Component } from 'react';
// import { Link } from 'react-router';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import { IMAGE_BASE_URL } from '../../common/config';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import { renderField, required } from '../../helpers/form-validations';
import API from '../../utils/utils';

class AddEditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      imageView: '',
    };
  }

  _onSelectImage = async (imageData) => {
    const { notify } = this.props;
    if (imageData) {
      const imageSize = imageData.files[0].size;
      if (imageSize > 1024000) {
        notify({ type: 'warning', message: 'Image size shoud be less than 1Mb.' });
        return;
      }

      const mimeType = imageData.files[0].type.split('/')[1];
      if (mimeType !== 'png' && mimeType !== 'jpeg' && mimeType !== 'webp') {
        notify({ type: 'warning', message: 'Only jpeg/png/wepb images are supported.' });
        return;
      }
      const readers = new FileReader();
      readers.readAsDataURL(imageData.files[0]);

      const uploadFormData = new FormData();
      uploadFormData.append('file', imageData.files[0]);
      const { setLoader } = this.props;
      setLoader(true);
      const data = { imageFile: imageData.files[0] };
      await API.uploadImage(data).then((responseJson) => {
        setLoader(false);
        const { picture, status } = responseJson;
        if (status) {
          this.setState({ imageView: picture });
        }
      });
    }
  }

  _onCancelImage = () => {
    this._resetForm();
  };

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditCategory'));
      this.setState({ imageView: '' });
    }
    document.getElementById('modalAddEditCategory').click();
  };

  _onAddCategory = async (formData) => {
    const { imageView } = this.state;
    const { addEditCategory } = this.props;
    // if (imageView !== '') {
    const data = {
      title_en: formData.title_en,
      title_de: formData.title_de,
      title_du: formData.title_du,
      bic_group: formData.bic_group,
      cat_image: imageView
    };
    addEditCategory(data);
    this._resetForm();
    // } else {
    //   notify({ type: 'error', message: 'Please Select Image' });
    // }
  };

  _onDeleteImage = () => {
    this.setState({ imageView: '' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0) {
      dispatch(change('addEditCategory', 'title_en', data.title_en));
      dispatch(change('addEditCategory', 'title_de', data.title_de));
      dispatch(change('addEditCategory', 'title_du', data.title_du));
      dispatch(change('addEditCategory', 'bic_group', data.bic_group));
      dispatch(change('addEditCategory', 'cat_image', data.cat_image));
      this.setState({ imageView: data.cat_image });
    }
  }

  render() {
    const { handleSubmit, data } = this.props;
    const { imageView } = this.state;

    return (
      <div
        className="modal fade" id="modalAddEditCategory"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">{`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} Category`}</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addEditCategory" onSubmit={handleSubmit(this._onAddCategory)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="title" className="col-sm-3 col-form-label">Title (en)</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_en"
                          component={renderField}
                          className="form-control"
                          placeholder="Title (en)"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="title" className="col-sm-3 col-form-label">Title (de)</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_de"
                          component={renderField}
                          className="form-control"
                          placeholder="Title (de)"
                          validate={required}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="title" className="col-sm-3 col-form-label">Title (du)</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_du"
                          component={renderField}
                          className="form-control"
                          placeholder="Title (du)"
                          validate={required}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="image" className="col-sm-3 col-form-label">Image</label>
                      <div className="col-sm-9">
                        <label htmlFor="cat_image" className="col-form-label from-row">
                          <small style={{ color: 'red' }}>
                            File Size (250*200)
                          </small>
                        </label>

                        <div className="row ml-3">
                          <img
                            src={imageView === ''
                              ? '/assets/images/no-image-icon.png'
                              : IMAGE_BASE_URL + imageView}
                            onError={() => {
                              this.onerror = null;
                              this.src = '/assets/images/no-image-icon.png';
                            }}
                            style={{ height: 50, width: 50 }}
                            className="col-3"
                            alt="image"
                          />
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm col-6 ml-3 mb-1"
                              onClick={() => document.getElementById('selectedFile').click()}
                            >
                              {(imageView === '') ? 'Upload Image' : 'Change Image'}
                            </button>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="selectedFile"
                              onChange={(e) => this._onSelectImage(e.target)}
                            />
                            {(imageView !== '')
                              && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger col-6 ml-3"
                                  onClick={() => this._onDeleteImage()}
                                >
                                  Delete Image
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="title" className="col-sm-3 col-form-label">Bic Group</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="bic_group"
                          component={renderField}
                          className="form-control"
                          placeholder="Bic Group"
                          disabled={Object.keys(data).length > 0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">{Object.keys(data).length > 0 ? 'Update' : 'Save'}</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditCategory'
});
export default withLoader(withForm(withNotify(AddEditCategory)));
