import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import TaxListSection from '../../components/SiteSettings/TaxListSection';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import TaxListSearchSection from '../../components/SiteSettings/TaxListSearchSection';
import API from '../../utils/utils';

class TaxList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxes: [],
      searchParams: {},
      page: 0,
      countUsers: 0,
    };
  }

  _taxList = (searchParams) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.taxList(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ taxes: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _countTax = (searchData) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.taxList({ count: 1, ...searchData }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countUsers: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteTax({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        this._taxList({});
        this._countTax({});
        notify({ type: 'success', message });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onActiveDeactive = (id) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.activeDeactiveTax({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._taxList({});
        this._countTax({});
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._taxList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._taxList(searchParams);
    this._countTax(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._taxList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._taxList({});
      this._countTax({});
    }
  }

  render() {
    const { taxes, countUsers, page } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <TaxListSearchSection onSearch={this._onSearch} />
            <TaxListSection
              data={taxes}
              onDelete={this._onDelete}
              onActiveDeactive={this._onActiveDeactive}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countUsers}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(TaxList)));
