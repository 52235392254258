import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import Select2 from 'react-select2-wrapper';
import API from '../../utils/utils';
import withNotify from '../../common/withNotify';
import { renderField, required } from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class AddEditProductAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedProduct: null
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditProductAttribute'));
    }
    document.getElementById('modalAddEditProductAttribute').click();
  };

  _getProducts = () => {
    const { notify } = this.props;
    API.getAllProducts().then((responseJson) => {
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ products: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onAddProductAttribute = async (formData) => {
    const { addEditProductAttribute, notify } = this.props;
    const { selectedProduct } = this.state;
    if (selectedProduct === null) {
      notify({ type: 'error', message: 'Please Select Product' });
    } else {
      const data = {
        product_id: selectedProduct,
        attribute_key: formData.attribute_key,
        attribute_value: formData.attribute_value,
      };
      addEditProductAttribute(data);
      this._resetForm();
    }
  };

  UNSAFE_componentWillMount() {
    this._getProducts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
        && Object.keys(data).length > 0) {
      this.setState({ selectedProduct: data.product_id });
      dispatch(change('addEditProductAttribute', 'product_id', data.product_id));
      dispatch(change('addEditProductAttribute', 'attribute_key', data.attribute_key));
      dispatch(change('addEditProductAttribute', 'attribute_value', data.attribute_value));
    }
  }

  render() {
    const { handleSubmit, data } = this.props;
    const {
      products, selectedProduct
    } = this.state;
    return (
      <div
        className="modal fade" id="modalAddEditProductAttribute"
        role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">{`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} Product Attribute`}</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addEditProductAttribute" onSubmit={handleSubmit(this._onAddProductAttribute)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="products" className="col-sm-3 col-form-label">Products</label>
                      <div className="col-sm-9">
                        <Select2
                          style={{ width: '100%' }}
                          className="form-control"
                          data={
                            products.map(({ title, _id }) => ({ id: _id, text: title }))
                            }
                          options={{ placeholder: 'Select Product' }}
                          value={selectedProduct}
                          onSelect={({ target: { value } }) => {
                            this.setState({ selectedProduct: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="attribute_key" className="col-sm-3 col-form-label">Attribute Key</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="attribute_key"
                          component={renderField}
                          className="form-control"
                          placeholder="Attribute Key"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="attribute_value" className="col-sm-3 col-form-label">Attribute Value</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="attribute_value"
                          component={renderField}
                          className="form-control"
                          placeholder="Attribute Value"
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">{Object.keys(data).length > 0 ? 'Update' : 'Save' }</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditProductAttribute'
});
export default withLoader(withForm(withNotify(AddEditProductAttribute)));
