import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import Pagination from '../Pagination';

class NotifyListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: 'created_date',
      sortOrder: 'asc'
    };
  }

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage, customerName, productName
    } = this.props;
    const { sortKey, sortOrder, } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>
                Notify Me List
                { ' ' }
                {`${customerName ? `(Customer Name : ${customerName} )` : ''} `}
                {`${productName ? `(Product Name : ${productName} )` : ''} `}
              </h4>
              <div className="card-header-action" />
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'product_isbn13' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_isbn13', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product ISBN
                      </th>
                      <th
                        className={`sort ${sortKey === 'product_title' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_title', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product Title
                      </th>
                      <th
                        className={`sort ${sortKey === 'product_author' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_author', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product Author
                      </th>
                      <th>
                        Customer Email
                      </th>
                      <th>
                        Notify
                      </th>
                      <th>
                        Notify Type
                      </th>
                      <th>
                        Created date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((user, key) => (
                        <tr key={key}>
                          <td>{user.product_isbn13}</td>
                          <td>{(user.product_title) || '-'}</td>
                          <td>
                            {user.product_author || '-'}
                          </td>
                          <td>{user.email}</td>
                          <td>
                            <Link
                              title={
                                      (user.notify_id)
                                        ? 'Notified'
                                        : (!user.log_data)
                                          ? 'Not Notified'
                                          : ''
                                     }
                              className="cursor"
                            >
                              {
                                      (!user.notify_id)
                                        ? <i className="fas fa-times" />
                                        : (user.notify_id)
                                          ? <i className="fas fa-check" />
                                          : ''
                                    }
                            </Link>
                          </td>
                          <td>
                            {
                              (!user.notify_type)
                                ? '-'
                                : (user.notify_type === 2)
                                  ? 'SMS'
                                  : 'Email'
                                }
                          </td>
                          <td>
                            {moment(user.created_date).format('DD/MM/YYYY')}
                          </td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNotify(withLoader(NotifyListSection));
