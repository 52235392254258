import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import NotifyListSection from '../../components/Products/NotifyListSection';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import NotifyListSearchSection from '../../components/Products/NotifyListSearchSection';
import API from '../../utils/utils';

class NotifyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifyMe: [],
      searchParams: {},
      page: 0,
      countNotification: 0,
      customerName: null,
      productName: null,
    };
  }

  _notifyList = (searchParams) => {
    const { setLoader, notify, params: { productId = null, customerId = null } } = this.props;
    setLoader(true);
    if (!productId && !customerId) {
      API.notifyList(searchParams).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ notifyMe: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (productId) {
      API.notifyList({ productId, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ notifyMe: data });
          Object.keys(data).map((key) => {
            this.setState({ productName: data[key].product_title });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.notifyList({ customerId, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ notifyMe: data });
          Object.keys(data).map((key) => {
            this.setState({ customerName: data[key].customer_name });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  }

  _countNotify = (searchData) => {
    const { setLoader, notify, params: { productId = null, customerId = null } } = this.props;
    setLoader(true);
    if (!productId && !customerId) {
      API.notifyList({ count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countNotification: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (productId) {
      API.notifyList({ productId, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countNotification: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.notifyList({ customerId, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countNotification: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._notifyList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._notifyList(searchParams);
    this._countNotify(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._notifyList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._notifyList({});
      this._countNotify({});
    }
  }

  render() {
    const {
      notifyMe, countNotification, page, customerName, productName
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <NotifyListSearchSection onSearch={this._onSearch} />
            <NotifyListSection
              data={notifyMe}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countNotification}
              customerName={customerName}
              productName={productName}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(NotifyList)));
