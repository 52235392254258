import { connect } from 'react-redux';
import { storageSet } from '../redux/actions';

const mapDispatchToProps = (dispatch) => ({
  setStorage: (data) => {
    dispatch(storageSet(data));
  },
});

const mapStateToProps = (state) => ({
  storage: state.storage ? state.storage : {},
});

export default connect(mapStateToProps, mapDispatchToProps);
