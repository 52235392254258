import {
  LOADER_SET,
  NOTIFY_SET,
  STORAGE_SET,
  RESET_REDUX,
  USER_SET,
  MENU_SET,
} from './actions';

export const loader = (state = false, action) => {
  switch (action.type) {
    case LOADER_SET:
      return action.data;

    default:
      return state;
  }
};

export const notify = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY_SET:
      return action.state;

    default:
      return state;
  }
};

export const storage = (state = {}, action) => {
  switch (action.type) {
    case STORAGE_SET:
      return action.data;
    case RESET_REDUX:
      return {};

    default:
      return state;
  }
};

export const user = (state = null, action) => {
  switch (action.type) {
    case USER_SET:
      return action.user;
    case RESET_REDUX:
      return null;

    default:
      return state;
  }
};

export const menu = (state = [], action) => {
  switch (action.type) {
    case MENU_SET:
      return action.data;
    case RESET_REDUX:
      return [];

    default:
      return state;
  }
};
