import React from 'react';
import ReactDOM from 'react-dom';
import feather from 'feather-icons';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

let times;
(function foo() {
  times = 2;
  (function run() {
    feather.replace();
    if (--times) setTimeout(run, 1000);
  }());
}());

ReactDOM.render(<Routes />, document.getElementById('root'));
serviceWorker.register();
