import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

class WarningBox extends Component {
  render() {
    const {
      isOpen = false, title, text, onConfirm, onCancel, confirmButtonText = 'Yes',
    } = this.props;
    return (
      isOpen && (
      <SweetAlert
        warning
        showCancel
        showCloseButton
        cancelBtnText="No"
        confirmBtnText={confirmButtonText}
        title={title}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        focusCancelBtn
      >
        {text}
      </SweetAlert>
      )
    );
  }
}

export default WarningBox;
