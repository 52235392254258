import React, { PureComponent } from 'react';
import moment from 'moment';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import WarningBox from '../Models/WarningBox';
import Pagination from '../Pagination';

class RecentlyViewListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      blockId: null,
      sortKey: 'created_date',
      sortOrder: 'desc',
      isblockOpen: false,
    };
  }

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  _onBlockUnblockAlert = (id) => {
    this.setState({ isblockOpen: true, blockId: id });
  };

  _onPressBlockUnblockIcon = () => {
    const { onBlockUnblock } = this.props;
    const { blockId } = this.state;
    this.setState({ isblockOpen: false, blockId: null });
    onBlockUnblock(blockId);
  };

  render() {
    const {
      data, count, page, onPage,
    } = this.props;
    const { sortKey, sortOrder, isblockOpen } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>
                Recently View Products List
              </h4>
              <div className="card-header-action" />
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'product_type' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_type', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product Type
                      </th>
                      <th
                        className={`sort ${sortKey === 'product_title' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_title', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product Title
                      </th>
                      <th>
                        Customer Name
                      </th>
                      <th>
                        Is-Blocked
                      </th>
                      <th>
                        Created date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((user, key) => (
                        <tr key={key}>
                          <td>
                            {user.product_type}
                          </td>
                          <td>{(user.product_title)}</td>
                          <td>{user.customer_name}</td>
                          <td>
                            <div className="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`checkbox-${key}`}
                                checked={user.status === 2}
                                onChange={() => this._onBlockUnblockAlert(user._id)}
                                aria-labelledby={`checkbox-label-${key}`}
                              />
                              <label
                                htmlFor={`checkbox-${key}`}
                                id={`checkbox-label-${key}`}
                                className="custom-control-label"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>
                          <td>
                            {moment(user.created_date).format('DD/MM/YYYY')}
                          </td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <WarningBox
          isOpen={isblockOpen}
          title="Block/Unblock"
          text="Are you sure you want to continue ?"
          onConfirm={this._onPressBlockUnblockIcon}
          onCancel={() => {
            this.setState({ isblockOpen: false });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(RecentlyViewListSection));
