import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import ConfirmBox from '../Models/ConfirmBox';
import Pagination from '../Pagination';

class ProductListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      selectedId: null,
      sortKey: 'created_date',
      sortOrder: 'dasc',
    };
  }

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onPressDeleteIcon = () => {
    const { onDelete } = this.props;
    const { selectedId } = this.state;
    this.setState({ isDeleteConfirmOpen: false, selectedId: null });
    onDelete(selectedId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const { isDeleteConfirmOpen, sortKey, sortOrder } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Product List</h4>
              {/* <div className="card-header-action">
                <Link to="/products/create" className="btn btn-primary">Add Product</Link>
              </div> */}
            </div>
            <div className="card-body">
              <div className="table-responsive" style={{ height: '500px' }}>
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`text-center sort ${sortKey === 'isbn13' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'isbn13',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        ISBN Code
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'title' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'title',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Product Title
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'author' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'author',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Author
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'publisher' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'publisher',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Publisher
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'yearofpublication' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'yearofpublication',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Year of Publication
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'series' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'series',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Series
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'stock' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'stock',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Stock
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'price' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'price',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Price(€)
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'numOfNotify' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'numOfNotify',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Notify
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'numOfWishList' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'numOfWishList',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Wishlist
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'numOfOrder' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'numOfOrder',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        Orders
                      </th>
                      <th
                        className={`text-center sort ${sortKey === 'seo_detail' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(
                          this,
                          'seo_detail',
                          sortOrder === 'asc' ? 'desc' : 'asc',
                        )}
                      >
                        SEO
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0
                      && data.map((product, key) => (
                        <tr key={key}>
                          <td>
                            {' '}
                            {product.isbn13}
                          </td>
                          <td
                            className={` ${!product.title ? 'text-center' : ''} `}
                          >
                            {product.title ? `${product.title.slice(0, 50)}...` : '-'}
                          </td>
                          <td>
                            {product.author ? `${product.author.slice(0, 50)}...` : '-'}
                          </td>
                          <td>
                            {product.publisher ? product.publisher : '-' }
                          </td>
                          <td>
                            {product.yearofpublication ? product.yearofpublication : '-'}
                          </td>
                          <td>
                            {product.series ? product.series : '-'}
                          </td>
                          <td>{product.stock}</td>
                          <td>{product.price ? product.price.toFixed(2) : '-' }</td>
                          <td>
                            <Link
                              to={`/products/notifyMe/${product._id}`}
                              title="Notify"
                              className="cursor"
                            >
                              {product.numOfNotify}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/products/wishlist/${product._id}`}
                              title="wishlist"
                              className="cursor"
                            >
                              {product.numOfWishList}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/products/orders/${product._id}`}
                              title="Orders"
                              className="cursor"
                            >
                              {product.numOfOrder}
                            </Link>
                          </td>
                          <td>
                            {/* {(!product.seo_detail)
                              && (
                                <Link
                                  to={`/products/seo/${product._id}`} title="Add Seo Details"
                                  className="cursor"
                                >
                                  <i className="fas fa-times" />
                                </Link>
                              )}
                            {(product.seo_detail)
                              && ( */}
                            <Link
                              to={`/products/seo/${product._id}/${product.seo_detail ? product.seo_detail : '-'}`}
                              title="Edit Seo Details"
                              className="cursor"
                            >
                              {product.seo_detail ? (
                                <i className="fa fa-poll fa-lg" />
                              ) : (
                                <i className="fa fa-poll fa-lg text-danger" />
                              )}
                            </Link>
                            {/* )} */}
                          </td>
                          <td>
                            <Link
                              to={`/products/view/${product._id}`}
                              title="View"
                              className="cursor"
                              aria-label="View"
                            >
                              <i className="fas fa-eye" aria-hidden="true" />
                            </Link>
                            &nbsp;&nbsp;
                            <Link
                              to={`/products/edit/${product._id}`}
                              title="Edit"
                              className="cursor"
                              aria-label="Edit"
                            >
                              <i className="fas fa-pencil-alt" aria-hidden="true" />
                            </Link>
                            &nbsp;&nbsp;
                            <Link
                              title="Delete"
                              onClick={() => this._onDeleteAlert(product._id)}
                              className="cursor"
                              aria-label="Delete"
                            >
                              <i className="far fa-trash-alt" aria-hidden="true" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    {data.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <center>
                            <b>Records not available!</b>
                          </center>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Product"
          text="Are you sure want to delete this product?"
          onConfirm={() => this._onPressDeleteIcon()}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false, selectedId: null });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(ProductListSection));
