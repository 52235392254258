import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import CustomerDiscountListSection from '../../components/SiteSettings/CustomerDiscountListSection';

class CustomerDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: [],
      searchParams: {},
      page: 0,
      countCustomer: 0,
    };
  }

  _customerDiscount = (searchParams) => {
    const { setLoader, notify, params: { discountID } } = this.props;
    setLoader(true);
    API.discountCustomer({ discountID, ...searchParams }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ customer: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _countDiscount = (searchData) => {
    const { setLoader, notify, params: { discountID } } = this.props;
    setLoader(true);
    API.discountCustomer({ discountID, count: 1, ...searchData }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countCustomer: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._customerDiscount(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._customerDiscount(searchParams);
    this._countDiscount(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._customerWishlist({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._customerDiscount({});
      this._countDiscount({});
    }
  }

  render() {
    const {
      customer, countCustomer, page,
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <CustomerDiscountListSection
              data={customer}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countCustomer}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(CustomerDiscount)));
