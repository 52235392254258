import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router';
import ConfirmBox from '../../components/Models/ConfirmBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';
import AddEditDiscountCode from '../../components/Models/AddEditDiscountCode';

class DiscountCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discountCodes: [],
      selectedData: {},
      isOpenAddEditDiscountCode: false,
      isDeleteConfirmOpen: false,
      selectedId: null,
      searchParams: {},
      page: 0,
      countUsers: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: '',
      viewOnly: null
    };
  }

  _getDiscountCode = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getDiscountCode(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ discountCodes: data });
      }
    });
  };

  _countDiscountCode = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getDiscountCode({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countUsers: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getDiscountCode(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getDiscountCode(searchParams);
    this._countDiscountCode(searchParams);
  };

  handleKeypress = (e, searchData) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let { searchParams } = this.state;
      searchParams.page = 0;
      searchParams = { ...searchParams, ...searchData };
      this.setState({ searchParams, page: 0 });
      this._getDiscountCode(searchParams);
      this._countDiscountCode(searchParams);
    }
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getDiscountCode({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onOpenAddEditModal = (selectedData, index = null, view) => {
    this.setState({
      selectedData, isOpenAddEditDiscountCode: true, selectedId: index, viewOnly: view
    });
  };

  _addEditDiscountCode = (data) => {
    const { setLoader, notify } = this.props;
    const { selectedData, selectedId } = this.state;
    setLoader(true);
    if (Object.keys(selectedData).length > 0 && selectedId != null) {
      data.id = selectedId;
      API.updateDiscountCode(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getDiscountCode({});
          this._countDiscountCode({});
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.addDiscountCode(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getDiscountCode({});
          this._countDiscountCode({});
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onDelete = () => {
    const { selectedId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteDiscountCode({ id: selectedId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getDiscountCode({});
        this._countDiscountCode({});
        this.setState({ isDeleteConfirmOpen: false, selectedId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    this._getDiscountCode({});
    this._countDiscountCode({});
  }

  render() {
    const {
      discountCodes, selectedData, isOpenAddEditDiscountCode, isDeleteConfirmOpen,
      sortKey, sortOrder, search, countUsers, page, viewOnly
    }
      = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Discount Code</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                              onKeyDown={(e) => this.handleKeypress(e, { search })}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button
                                className="btn btn-primary mr-1"
                                type="button"
                                onClick={() => this._onSearch({ search })}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1"
                                type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Discount Code List</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalAddEditDiscountCode"
                        onClick={() => this._onOpenAddEditModal({})}
                      >
                        Add New Discount Code
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th
                              className={`sort ${sortKey === 'name' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(
                                this,
                                'name',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Name
                            </th>
                            <th
                              className={`sort ${sortKey === 'is_campaign' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(
                                this,
                                'is_campaign',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Customer Type
                            </th>
                            <th
                              className={`sort ${sortKey === 'code' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(
                                this,
                                'code',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Discount Code
                            </th>
                            <th
                              className={`sort ${sortKey === 'start_date' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(
                                this,
                                'start_date',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              Start Date
                            </th>
                            <th
                              className={`sort ${sortKey === 'end_date' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(
                                this,
                                'end_date',
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              )}
                            >
                              End Date
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(discountCodes)
                            && discountCodes.map((item, key) => (
                              <tr key={key}>
                                <td>{item.name}</td>
                                <td>
                                  {item.is_campaign ? 'Special' : 'Public'}
                                </td>
                                <td>{item.code}</td>
                                <td>{moment(item.start_date).format('DD/MM/YYYY')}</td>
                                <td>{moment(item.end_date).format('DD/MM/YYYY')}</td>
                                <td>
                                  <Link
                                    title="View"
                                    data-toggle="modal"
                                    data-target="#modalAddEditDiscountCode"
                                    onClick={() => this._onOpenAddEditModal(
                                      {
                                        name: item.name,
                                        code: item.code,
                                        is_campaign: item.is_campaign,
                                        discount_type: item.discount_type,
                                        amount: item.amount,
                                        max_discount: item.max_discount,
                                        has_min_order: item.has_min_order,
                                        min_order_amount:
                                          item.min_order_amount,
                                        start_date: item.start_date,
                                        end_date: item.end_date,
                                        selected_customer:
                                          item.selected_customer,
                                      },
                                      item._id,
                                      'view',
                                    )}
                                    aria-label="View"
                                  >
                                    <i className="fas fa-eye" aria-hidden="true" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                  <Link
                                    to={`/site-settings/discount-code/${item._id}`}
                                    title="VIew Customer"
                                    className="cursor"
                                    aria-label="View Customer"
                                  >
                                    <i className="fa fa-street-view" aria-hidden="true" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                  <Link
                                    title="Edit"
                                    data-toggle="modal"
                                    data-target="#modalAddEditDiscountCode"
                                    onClick={() => this._onOpenAddEditModal(
                                      {
                                        name: item.name,
                                        code: item.code,
                                        is_campaign: item.is_campaign,
                                        discount_type: item.discount_type,
                                        amount: item.amount,
                                        max_discount: item.max_discount,
                                        has_min_order: item.has_min_order,
                                        min_order_amount:
                                          item.min_order_amount,
                                        start_date: item.start_date,
                                        end_date: item.end_date,
                                        selected_customer:
                                          item.selected_customer,
                                      },
                                      item._id,
                                    )}
                                    aria-label="Edit"
                                  >
                                    <i className="fas fa-pencil-alt" aria-hidden="true" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                  <Link
                                    title="Delete"
                                    className="cursor"
                                    onClick={() => this._onDeleteAlert(item._id)}
                                    aria-label="Delete"
                                  >
                                    <i className="far fa-trash-alt" aria-hidden="true" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                </td>
                              </tr>
                            ))}
                          {discountCodes.length === 0 && (
                            <tr>
                              <td colSpan={12}>
                                <center>
                                  <b>Records not available!</b>
                                </center>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countUsers}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Discount Code"
          text="Are you sure want to delete this discount code?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
        <AddEditDiscountCode
          isOpen={isOpenAddEditDiscountCode}
          onCancel={() => {
            this.setState({ isOpenAddEditDiscountCode: false });
          }}
          addEditDiscountCode={this._addEditDiscountCode}
          data={selectedData}
          isView={viewOnly}
        />
      </div>
    );
  }
}
export default withLoader(withNotify(DiscountCode));
