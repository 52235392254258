import React, { Component } from 'react';

class viewVisitorLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_role: '',
      ip_address: '',
      rowData: {}
    };
  }

  _resetForm = () => {
    document.getElementById('modelViewVisitorLog').click();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0) {
      this.setState({ rowData: data.ip_object });
      this.setState({ country: data.ip_object.country });
      this.setState({ ip_address: data.ip_object.ip_address });
    }
  }

  render() {
    const { country, rowData, ip_address } = this.state;
    const jsonPretty = JSON.stringify(rowData, null, 4);
    return (
      <div
        className="modal fade" id="modelViewVisitorLog"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h4 className="modal-title pull-left">Visitor Log Details </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this._resetForm()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="row">
                <div className="col-md-12">
                  {ip_address
                    && (
                      <>
                        <div className="row">
                          <strong className="col-3"> IP Address: </strong>
                          <span className="col-9">
                            {ip_address}
                          </span>
                        </div>
                        <hr />
                      </>
                    )}
                </div>
                <div className="col-md-12">
                  {country
                    && (
                      <>
                        <div className="row">
                          <strong className="col-3"> Country: </strong>
                          <span className="col-9">
                            {country}
                          </span>
                        </div>
                        <hr />
                      </>
                    )}
                </div>
              </div>
              <div className="container">
                <pre>{jsonPretty}</pre>
              </div>
            </div>
            <div className="modal-footer br">
              <button
                type="button" className="btn btn-dark"
                onClick={() => this._resetForm()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default viewVisitorLog;
