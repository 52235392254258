import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import withNotify from '../../common/withNotify';
import {
  renderField,
  required,
  number,
  maxLength,
} from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';

class AddEditCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditCountry'));
    }
    document.getElementById('modalAddEditCountry').click();
  };

  _onAddCountry = async (formData) => {
    const { addEditCountry } = this.props;
    const data = {
      name: formData.name,
      country_code: formData.country_code,
      code: formData.code,
    };
    addEditCountry(data);
    this._resetForm();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0) {
      Object.keys(data).map((key) => {
        dispatch(change('addEditCountry', key, data[key]));
      });
    }
    // if (Object.keys(data).length === 0) {
    //   dispatch(change('addEditCountry', 'code', ''));
    //   dispatch(change('addEditCountry', 'name', ''));
    //   dispatch(change('addEditCountry', 'country_code', ''));
    //   // dispatch(reset('addEditCountry'));
    // }
  }

  render() {
    const { handleSubmit, data } = this.props;
    return (
      <div
        className="modal fade" id="modalAddEditCountry"
        tabIndex="-1" role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">{`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} Country`}</h5>
              <button type="button" className="close" onClick={() => this._resetForm()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form name="addEditCountry" onSubmit={handleSubmit(this._onAddCountry)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">Country Name</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="name"
                          component={renderField}
                          className="form-control"
                          placeholder="Country name"
                          validate={required}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="country_code" className="col-sm-3 col-form-label">ISO Code</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="country_code"
                          component={renderField}
                          className="form-control"
                          placeholder="ISO code"
                          validate={[required, maxLength(3)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="code" className="col-sm-3 col-form-label">Code</label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="code"
                          component={renderField}
                          className="form-control"
                          placeholder="Code"
                          validate={[required, number]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">{Object.keys(data).length > 0 ? 'Update' : 'Save'}</button>
                <button
                  type="button" className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditCountry'
});
export default withLoader(withForm(withNotify(AddEditCountry)));
