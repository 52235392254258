import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
// import Recaptcha from 'react-recaptcha';
import { Link } from 'react-router';
import { renderField, required } from '../helpers/form-validations';
import { storeUser } from '../common/globals';
import withLoader from '../common/withLoader';
import withUser from '../common/withUser';
import withStorage from '../common/withStorage';
import withNotify from '../common/withNotify';
import API from '../utils/utils';
// import { GOOGLE_CATPCHA_KEY } from '../common/config';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerified: false,
      btnDisabled: false,
    };
  }

  _recaptchaLoaded = () => { };

  _verifyCallback = (response) => {
    if (response) {
      this.setState({ isVerified: true });
    }
  };

  _onLogin = (formData) => {
    const { setLoader, notify, setUser } = this.props;
    // const { isVerified } = this.state;
    this.setState({ btnDisabled: true });
    setLoader(true);
    API.login(formData).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      // if (status && isVerified) {
      if (status) {
        notify({ type: 'success', message: 'Successfully signed in.' });
        await storeUser(data);
        this.setState({ btnDisabled: false });
        setTimeout(() => {
          setUser(data);
          window.location = '/';
        }, 600);
      } else {
        notify({ type: 'error', message });
      }
      // else if (!isVerified) {
      //   notify({
      //     type: 'error',
      //     message: 'Please verify that you are not a robot!',
      //   });
      //   this.setState({ btnDisabled: false });
      // }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { btnDisabled } = this.state;
    return (
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="card card-primary">
                <div className="card-header">
                  <img
                    alt="image"
                    src="/assets/images/login.png"
                    className="header-logo"
                  />
                </div>

                <div className="card-header">
                  <h4>Admin Login</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(this._onLogin)}>
                    <div className="form-group">
                      <label htmlFor="email">Username</label>
                      <Field
                        name="email"
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Username"
                        component={renderField}
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <div className="d-block">
                        <label htmlFor="password" className="control-label">
                          Password
                        </label>
                      </div>
                      <Field
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        component={renderField}
                        validate={required}
                      />
                      <div className="invalid-feedback">
                        please fill in your password
                      </div>
                    </div>
                    {/* <Recaptcha
                      sitekey={GOOGLE_CATPCHA_KEY}
                      render="explicit"
                      onloadCallback={this._recaptchaLoaded}
                      verifyCallback={this._verifyCallback}
                    /> */}
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                        disabled={btnDisabled}
                      >
                        Login
                      </button>
                    </div>
                    <div className="text-center">
                      <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const withForm = reduxForm({
  form: 'signInForm',
});
export default withForm(withNotify(withUser(withLoader(withStorage(SignIn)))));
