import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import WishlistSection from '../../components/Products/WishlistSection';
import WishlistSearchSection from '../../components/Products/WishlistSearchSection';

class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishlist: [],
      searchParams: {},
      page: 0,
      countWishlist: 0,
      customerName: null,
      productName: null,
    };
  }

  _customerWishlist = (searchParams) => {
    const { setLoader, notify, params: { productId, customerId } } = this.props;
    setLoader(true);
    if (!productId && !customerId) {
      API.customerWishlist(searchParams).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ wishlist: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (productId) {
      API.customerWishlist({ productId, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ wishlist: data });
          Object.keys(data).map((key) => {
            this.setState({ productName: data[key].product_title });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.customerWishlist({ customerId, ...searchParams }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ wishlist: data });
          Object.keys(data).map((key) => {
            this.setState({ customerName: data[key].customer_name });
          });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  }

  _countWishlist = (searchData) => {
    const { setLoader, notify, params: { productId, customerId } } = this.props;
    setLoader(true);
    if (!productId && !customerId) {
      API.customerWishlist({ count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countWishlist: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else if (productId) {
      API.customerWishlist({ productId, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countWishlist: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.customerWishlist({ customerId, count: 1, ...searchData }).then(async (responseJson) => {
        setLoader(false);
        const { status, message, data } = responseJson;
        if (status) {
          this.setState({ countWishlist: data });
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._customerWishlist(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._customerWishlist(searchParams);
    this._countWishlist(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._customerWishlist({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._customerWishlist({});
      this._countWishlist({});
    }
  }

  render() {
    const {
      wishlist, countWishlist, page,
      customerName, productName
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <WishlistSearchSection onSearch={this._onSearch} />
            <WishlistSection
              data={wishlist}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countWishlist}
              customerName={customerName}
              productName={productName}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(Wishlist)));
