import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router';
import Imgix from 'react-imgix';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import { IMAGE_BASE_URL } from '../../common/config';

class ProductViewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      videos: [],
      topTrending: {},
      bestSeller: {},
    };
  }

  _getProductById = () => {
    const {
      setLoader,
      notify,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getProductById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, message, product } = responseJson;
      if (status) {
        this.setState({
          isbn13: product.isbn13,
          isbn10: product.isbn10,
          price: product.price,
          stock: product.stock,
          ukavailability: product.ukavailability,
          bindingdes: product.bindingdes,
          author: product.author,
          bic: product.bic,
          countryoforigin: product.countryoforigin,
          illustrator: product.illustrator,
          edition: product.edition,
          editor: product.editor,
          illustrations: product.illustrations,
          copublisher: product.copublisher,
          language: product.language,
          mixedpackcomponents: product.mixedpackcomponents,
          nationalcurriculumKey: product.nationalcurriculumKey,
          publicationdate: product.publicationdate,
          pagination: product.pagination,
          numberofpages: product.numberofpages,
          dimensions: product.dimensions,
          publisher: product.publisher,
          yearofpublication: product.yearofpublication,
          bookdatareadershipcode: product.bookdatareadershipcode,
          bookdatareadershiptext: product.bookdatareadershiptext,
          series: product.series,
          serieseditor: product.serieseditor,
          title: product.title,
          subtitle: product.subtitle,
          parttitle: product.parttitle,
          translator: product.translator,
          partnumber: product.partnumber,
          shortdescription: product.shortdescription,
          ean: product.ean,
          returnsflag: product.returnsflag,
          returnsdate: product.returnsdate,
          newisbn: product.newisbn,
          weight_grams: product.weight_grams,
          starrating: product.starrating,
          gardnerspublicity: product.gardnerspublicity,
          gardnersclassificationcode: product.gardnersclassificationcode,
          titleprefix: product.titleprefix,
        });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _getProductImageById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getProductImgByProductId({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ images: data });
      }
    });
  };

  _getProductVideoById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getProductVideoByProductId({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ videos: data });
      }
    });
  };

  _getBestSellerById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getBestSellerById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ bestSeller: data });
      } else {
        this.setState({ bestSeller: {} });
      }
    });
  };

  _getTopTrendingById = () => {
    const {
      setLoader,
      params: { id },
    } = this.props;
    setLoader(true);
    API.getTopTrendingProductById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, data } = responseJson;
      if (status) {
        this.setState({ topTrending: data });
      } else {
        this.setState({ topTrending: {} });
      }
    });
  };

  _onPressCancel = () => {
    browserHistory.push('/products');
  };

  UNSAFE_componentWillMount() {
    this._getProductById();
    this._getProductImageById();
    this._getProductVideoById();
    this._getTopTrendingById();
    this._getBestSellerById();
  }

  render() {
    const {
      nationalcurriculumKey,
      publicationdate,
      isbn10,
      pagination,
      numberofpages,
      dimensions,
      yearofpublication,
      bookdatareadershipcode,
      mixedpackcomponents,
      isbn13,
      bic,
      copublisher,
      language,
      illustrator,
      title,
      stock,
      days,
      ukavailability,
      bindingdes,
      edition,
      author,
      publisher,
      countryoforigin,
      editor,
      illustrations,
      price,
      videos,
      images,
      bookdatareadershiptext,
      series,
      serieseditor,
      subtitle,
      parttitle,
      translator,
      partnumber,
      shortdescription,
      ean,
      returnsflag,
      newisbn,
      weight_grams,
      starrating,
      gardnerspublicity,
      gardnersclassificationcode,
      titleprefix,
      returnsdate,
      bestSeller,
      topTrending,
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product Image Gallary</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this._onPressCancel()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row m-3">
                      {images.map((item, key) => (
                        <div key={key}>
                          <Link to="/products">
                            <Imgix
                              sizes="(min-width: 960px) 33vw, (min-width: 640px) 50vw,(padding: 640px) 100vw"
                              src={IMAGE_BASE_URL + item.image}
                              imgixParams={{
                                fit: 'crop',
                                fm: 'jpg',
                              }}
                              width={180}
                              height={200}
                              border={3}
                            />
                          </Link>
                        </div>
                      ))}
                      {images.length === 0 && (
                        <div>
                          <div className="ml-3">
                            <Imgix
                              sizes="(min-width: 960px) 33vw, (min-width: 640px) 50vw, 100vw"
                              src="/assets/images/no-image-icon.png"
                              imgixParams={{
                                fit: 'crop',
                                fm: 'jpg',
                              }}
                              width={200}
                              height={200}
                              border={1}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product YouTube Link</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {videos.length > 0
                        && videos.map((item, key) => (
                          <div key={key} className="col-4 p-3">
                            <iframe
                              width="300"
                              title="Image"
                              height="250"
                              src={item.video_link}
                              frameBorder="0"
                              allow="autoplay; encrypted-media"
                              allowFullScreen
                            />
                          </div>
                        ))}
                      {videos.length === 0 && (
                        <div>
                          <div className="ml-3">
                            <center>
                              <b>Video not available!</b>
                            </center>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    {/* <h4>Product Details</h4> */}
                  </div>
                  <div className="card-body">
                    <div className="container mt-5">
                      <div className="row mt-5">
                        <div className="col-6">
                          <div className="border">
                            <h6 className="m-3 text-center">
                              Product&apos;s Information
                            </h6>
                            <div className="p-3 bg-light">
                              <strong className="">Title : </strong>
                              {' '}
                              {title || '-'}
                              <br />
                              <strong>Sub Title :</strong>
                              {' '}
                              {subtitle || '-'}
                              <br />
                              <strong>Part Title :</strong>
                              {' '}
                              {parttitle || '-'}
                              <br />
                              <strong>Title prefix :</strong>
                              {' '}
                              {titleprefix || '-'}
                              <br />
                              <strong>Author :</strong>
                              {' '}
                              {author || '-'}
                              <br />
                              <strong>Publisher :</strong>
                              {' '}
                              {publisher || '-'}
                              <br />
                              <strong>Price(€) : </strong>
                              {' '}
                              {price || '-'}
                              <br />
                              <strong>Stock :</strong>
                              {' '}
                              {stock || '-'}
                              <br />
                              <strong>Rating : </strong>
                              {' '}
                              {starrating || '-'}
                              <br />
                              <strong>Dimensions :</strong>
                              {' '}
                              {dimensions || '-'}
                              <br />
                              <strong>Co-publisher :</strong>
                              {' '}
                              {copublisher || '-'}
                              <br />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="border">
                            <h6 className="m-3 text-center">
                              Product&apos;s Number
                            </h6>
                            <div className="p-3 bg-light">
                              <strong>ISBN(13) Code : </strong>
                              {' '}
                              {isbn13 || '-'}
                              <br />
                              <strong>ISBN(10) Code :</strong>
                              {' '}
                              {isbn10 || '-'}
                              <br />
                              <strong>European Article Number :</strong>
                              {' '}
                              {ean || '-'}
                              <br />
                              <strong>New ISBN :</strong>
                              {' '}
                              {newisbn || '-'}
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="border">
                          <h6 className="m-3 text-center">
                            Product&apos;s other Information
                          </h6>
                          <div className="p-3 bg-light">
                            <div className="row">
                              <div className="col-6">
                                <strong>Language :</strong>
                                {' '}
                                {language || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Year of publication :</strong>
                                {' '}
                                {yearofpublication || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Number of pages : </strong>
                                {' '}
                                {numberofpages || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Publication date : </strong>
                                {' '}
                                {publicationdate || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Pagination :</strong>
                                {' '}
                                {pagination || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Days : </strong>
                                {' '}
                                {days || '-'}
                              </div>
                              <div className="col-6">
                                <strong>UK availability :</strong>
                                {' '}
                                {ukavailability || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Binding :</strong>
                                {' '}
                                {bindingdes || '-'}
                              </div>
                              <div className="col-6">
                                <strong>BIC :</strong>
                                {' '}
                                {bic || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Country of Origin : </strong>
                                {' '}
                                {countryoforigin || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Editor :</strong>
                                {' '}
                                {editor || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Illustrator :</strong>
                                {' '}
                                {illustrator || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Edition :</strong>
                                {' '}
                                {edition || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Illustrations :</strong>
                                {' '}
                                {illustrations || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Mixed pack components : </strong>
                                {' '}
                                {mixedpackcomponents || '-'}
                              </div>
                              <div className="col-6">
                                <strong>National curriculum Key :</strong>
                                {' '}
                                {nationalcurriculumKey || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Book data reader shipcode :</strong>
                                {' '}
                                {bookdatareadershipcode || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Series :</strong>
                                {' '}
                                {series || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Series editor : </strong>
                                {' '}
                                {serieseditor || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Translator :</strong>
                                {' '}
                                {translator || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Part number : </strong>
                                {' '}
                                {partnumber || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Return flag : </strong>
                                {' '}
                                {returnsflag || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Return date :</strong>
                                {' '}
                                {returnsdate || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Weight :</strong>
                                {' '}
                                {weight_grams || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Gardeners publicity :</strong>
                                {' '}
                                {gardnerspublicity || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Gardners classification code :</strong>
                                {' '}
                                {gardnersclassificationcode || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Short description :</strong>
                                {' '}
                                {shortdescription || '-'}
                              </div>
                              <div className="col-6">
                                <strong>Book data reader shiptext :</strong>
                                {' '}
                                {bookdatareadershiptext || '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Best Seller & Top Trending Product</h4>
                  </div>
                  <div className="card-body">
                    <div className="row text-center">
                      <div className="col-4 bg-light m-3 p-3">
                        <b>Best Seller:</b>
                        {bestSeller.length > 0 ? ' Yes' : ' No'}
                      </div>
                      <div className="col-4 bg-light m-3 p-3">
                        <b>Top Trending:</b>
                        {topTrending.length > 0 ? ' Yes' : ' No'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(ProductViewList)));
