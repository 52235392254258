import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import withLoader from '../../common/withLoader';
import withNotify from '../../common/withNotify';
import withUser from '../../common/withUser';
import API from '../../utils/utils';
import AddressListSection from '../../components/Customers/AddressListSection';
import AddressListSearchSection from '../../components/Customers/AddressListSearchSection';

class AddressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: [],
      searchParams: {},
      page: 0,
      countAddress: 0,
      name: null,
    };
  }

  _addressList = (searchParams) => {
    const { setLoader, notify, params: { id } } = this.props;
    searchParams.id = id;
    setLoader(true);
    API.addressList(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ address: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _getName = () => {
    const {
      setLoader, params: { id }
    } = this.props;
    setLoader(true);
    API.getCustomerById({ id }).then((responseJson) => {
      setLoader(false);
      const { status, customer } = responseJson;
      if (status) {
        this.setState({
          name: `${customer.first_name} ${customer.last_name}`
        });
      }
    });
  }

  _countAddress = (searchData) => {
    const { setLoader, notify, params: { id } } = this.props;
    setLoader(true);
    API.addressList({ id, count: 1, ...searchData }).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ countAddress: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _onDelete = (addressId) => {
    const { setLoader, notify, params: { id } } = this.props;
    setLoader(true);
    API.deleteAddress({ addressId, id }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        this._addressList({});
        this._countAddress({});
        notify({ type: 'success', message });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onSort = (sortParams) => {
    let { searchParams } = this.state;
    searchParams = { ...searchParams, ...sortParams };
    this.setState({ searchParams });
    this._addressList(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._addressList(searchParams);
    this._countAddress(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._addressList({ ...searchParams, page: pageParam - 1 });
    }
  }

  async UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!isEmpty(user)) {
      this._addressList({});
      this._countAddress({});
      this._getName();
    }
  }

  render() {
    const {
      address, countAddress, page, name
    } = this.state;
    const { params: { id } } = this.props;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <AddressListSearchSection onSearch={this._onSearch} />
            <AddressListSection
              data={address}
              onDelete={this._onDelete}
              onSort={this._onSort}
              page={page}
              onPage={this._onPage}
              count={countAddress}
              customerId={id}
              customerName={name}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withLoader(withNotify(withUser(AddressList)));
