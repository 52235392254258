import React, { PureComponent } from 'react';
import { browserHistory, Link } from 'react-router';
import withLoader from '../common/withLoader';
import withNotify from '../common/withNotify';
import API from '../utils/utils';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderCounts: [],
    };
  }

  _getData = () => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.getAdminDashboard().then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ orderCounts: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  }

  _onPressCancel = () => {
    browserHistory.push('/customers/address');
  }

  UNSAFE_componentWillMount() {
    this._getData();
  }

  render() {
    const { orderCounts } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4> Dashboard</h4>
                  </div>
                  <div className="card-body">
                    &nbsp;
                    <h5>Today&apos;s Orders</h5>
                    {orderCounts.length > 0
                      && (
                        <>
                          <div className="row">
                            <div className="col-lg-3 col-sm-6">
                              <Link to={`/orders/${1}`}>
                                <div className="card bg-warning">
                                  <div className="card-statistic-4">
                                    <div className="info-box7-block">
                                      <h6 className="m-b-20 text-right">
                                        {orderCounts[0].pendingOrder > 1
                                          ? 'Pending Orders' : 'Pending Order'}
                                      </h6>
                                      <h4 className="text-right">
                                        <i
                                          className="fas pull-left c-icon fas fa-cart-arrow-down"
                                        />
                                        <span>{orderCounts[0].pendingOrder}</span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                              <Link to={`/orders/${2}`}>
                                <div className="card bg-info">
                                  {/*  l-bg-purple */}
                                  <div className="card-statistic-4">
                                    <div className="info-box7-block">
                                      <h6 className="m-b-20 text-right">
                                        {orderCounts[1].processingOrder > 1 ? 'Processing Orders' : 'Processing Order'}
                                      </h6>
                                      <h4 className="text-right">
                                        <i
                                          className="fas pull-left c-icon fas fa-shipping-fast"
                                        />
                                        <span>{orderCounts[1].processingOrder}</span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                              <Link to={`/orders/${5}`}>
                                <div className="card bg-success">
                                  <div className="card-statistic-4">
                                    <div className="info-box7-block">
                                      <h6 className="m-b-20 text-right">
                                        {orderCounts[2].complatedOrder > 1 ? 'Completed Orders' : 'Completed Order'}
                                      </h6>
                                      <h4 className="text-right">
                                        <i
                                          className="fas pull-left c-icon fa fa-check-circle"
                                        />
                                        <span>{orderCounts[2].complatedOrder}</span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                              <Link to={`/orders/${6}`}>
                                <div className="card bg-danger">
                                  <div className="card-statistic-4">
                                    <div className="info-box7-block">
                                      <h6 className="m-b-20 text-right">
                                        {orderCounts[3].cancelledOrder > 1 ? 'Cancelled Orders' : 'Cancelled Order'}
                                      </h6>
                                      <h4 className="text-right">
                                        <i
                                          className="fas pull-left c-icon fa fa-times-circle"
                                        />
                                        <span>{orderCounts[3].cancelledOrder}</span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withNotify(withLoader(Dashboard));
