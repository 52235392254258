/* eslint-disable*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import WarningBox from '../Models/WarningBox';
import Pagination from '../Pagination';
import ConfirmBox from '../Models/ConfirmBox';

class ProductReviewListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: 'created_date',
      sortOrder: 'desc',
      activeId: null,
      isActiveOpen: false,
      isDeleteConfirmOpen: false,
    };
  }

  _onActiveDeactiveAlert = (id, status) => {
    if (status) {
      this.setState({
        isDeleteConfirmOpen: true,
        activeId: id
      });
    } else {
      this.setState({
        isActiveOpen: true,
        activeId: id
      });
    }
  };

  _onPressActiveDeactiveIcon = () => {
    const { onActiveDeactive } = this.props;
    const { activeId } = this.state;
    this.setState({ isActiveOpen: false, activeId: null });
    this.setState({ isDeleteConfirmOpen: false, activeId: null });
    onActiveDeactive(activeId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  _getRatting = (rating) => {
    const rt = (rating * 20).toString();
    return rt + '%';
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const {
      sortKey, sortOrder, isActiveOpen, isDeleteConfirmOpen
    } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Product Review Rating</h4>
            </div>
            <div className="card-body" >
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'product_isbn13' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_isbn13', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product ISBN
                      </th>
                      <th
                        className={`sort ${sortKey === 'product_title' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_title', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product Title
                      </th>
                      <th
                        className={`sort ${sortKey === 'product_author' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'product_author', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Product Author
                      </th>
                      <th>
                        Customer Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'review' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'review', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Review
                      </th>
                      <th
                        className={`sort ${sortKey === 'rating' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'rating', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Rating
                      </th>
                      <th>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((item, key) => (
                        <tr key={key}>
                          <td>{(item.isbn13) || '-'}</td>
                          <td>{item.title ? `${String(item.title).slice(0, 40)}...` : '-'}</td>
                          <td>
                            {item.author ? `${String(item.author).slice(0, 40)}...` : '-'}
                          </td>
                          <td>{item.customer_name}</td>
                          <td><td>
                            {(item.review)
                              ? (
                                <span>
                                  <input
                                    data-toggle="tooltip"
                                    title={item.review}
                                    type="radio"
                                    name="rGroup"
                                    height="200"
                                    width="200"
                                    className="fas fa-info-circle"
                                  />
                                  <label className="btn btn-default" htmlFor="rGroup">{' '}</label>
                                </span>
                              )
                              : '-'}
                          </td></td>
                          <td>
                            <div className="containerdiv" title={item.rating}>
                              <div>
                                <img src="https://image.ibb.co/jpMUXa/stars_blank.png" alt="img" />
                              </div>
                              <div className="cornerimage" style={{ width: this._getRatting(item.rating) }}>
                                <img src="https://image.ibb.co/caxgdF/stars_full.png" alt="" />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="custom-checkbox custom-control">
                              <input
                                type="checkbox"
                                className="custom-control-input" id={key}
                                checked={(item.status === 1)}
                                onChange={() => this._onActiveDeactiveAlert(item._id, item.status)}
                              />
                              <label htmlFor={key} className="custom-control-label">
                                &nbsp;
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <WarningBox
          isOpen={isActiveOpen}
          title="Approve Review"
          text="Are you sure you want to approve this review ?"
          onConfirm={this._onPressActiveDeactiveIcon}
          onCancel={() => {
            this.setState({ isActiveOpen: false });
          }}
        />
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Reject Review"
          text="Are you sure you want to reject this review?"
          onConfirm={this._onPressActiveDeactiveIcon}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(ProductReviewListSection));
